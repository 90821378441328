import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { PrimaryButton } from "components/Button/Button";
import request from "constants/requests";

import styles from "./DeclineMerchant.module.css";

export interface CreateWalletValuesTypes {
  reason: string;
}

const DeclineMerchant: React.FC<{
  merchant: string | string[];
  closeModal: () => void;
  fetchMerchant: () => void;
}> = ({ merchant, closeModal, fetchMerchant }) => {
  const [loading, setLoading] = useState(false);

  const handleDeclineMerchant = async (values, action) => {
    setLoading(true);
    try {
      const { data } = await request({
        method: "post",
        url: "/admin/merchant/decline",
        data: { merchantId: merchant, reason: values?.reason },
      });
      toast.success(data?.message);
      closeModal();
      fetchMerchant();
      action.resetForm();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-5">Decline Merchant</h2>
      <Formik
        initialValues={{
          reason: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={handleDeclineMerchant}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="input__container">
              <Field
                name="reason"
                placeholder="Reason"
                type="text"
                as="textarea"
                className={styles.input__style}
              />
              {errors.reason && touched.reason && (
                <div className={styles.error}>{errors.reason}</div>
              )}
            </div>

            {loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Decline"
                className={styles.decline__btn}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeclineMerchant;

const ValidationSchema = Yup.object().shape({
  reason: Yup.string().required("Required"),
});
