export const CATEGORY_LIST = [
  "NIP_DEBIT",
  "NIP_CREDIT",
  "BANK_TRANSFER",
  "CARD_ACTIVATION",
  "AIRTIME_PURCHASE",
  "BVN_VERIFICATION",
  "NIP_DEBIT_REVERSAL",
  "WALLET_RESERVATION",
  "DEBIT_CUSTOMER_WALLET",
  "CREDIT_CUSTOMER_WALLET",
  "WALLET_TO_WALLET_TRANSFER",
  "MERCHANT_WALLET_SETTLEMENT",
  "WALLET_DEBITED_BY_MERCHANT",
  "WALLET_CREDITED_BY_MERCHANT",
  "WALLET_FUNDED_THROUGH_BANK_TRANSFER",
  "CUSTOMER_BANK_TRANSFER",
  "BATCH_DEBIT_CUSTOMER_WALLET",
  "BATCH_CREDIT_CUSTOMER_WALLET",
  "BATCH_BANK_TRANSFER",
  "CUSTOMER_BATCH_CREDIT_CUSTOMER_WALLET",
  "BATCH_TRANSACTION_REVERSAL",
  "WALLET_CREDITED_BY_CUSTOMER",
  "STAMP_DUTY",
  "CARD_DEBIT",
  "CARD_REVERSAL",
  "CARD_PLACE_LIEN",
  "CARD_DEBIT_LIEN",
  "NIP_CREDIT_REVERSAL",
  "MASTER_CARD_QR_PAYMENT",
  "DEBIT_CUSTOMER_DIRECT",
  "CREDIT_CUSTOMER_DIRECT",
  "CREDIT_MERCHANT_WALLET",
  "MERCHANT_TO_MERCHANT_TRANSFER",
  "WALLET_FUNDED_VIA_SANDBOX",
];

export const CATEGORY_DESCRIPTION = {
  NIP_DEBIT: "Direct Debit",
  NIP_CREDIT: "Account Credited",
  BANK_TRANSFER: "Bank Transfer",
  CARD_ACTIVATION: "Card Activation",
  AIRTIME_PURCHASE: "Airtime Purchase",
  BVN_VERIFICATION: "BVN Verification",
  NIP_DEBIT_REVERSAL: "Transfer Reversal",
  WALLET_RESERVATION: "Wallet Reservation",
  DEBIT_CUSTOMER_WALLET: "Debit Customer Wallet",
  CREDIT_CUSTOMER_WALLET: "Credit Customer Wallet",
  WALLET_TO_WALLET_TRANSFER: "Wallet to Wallet Transfer",
  MERCHANT_WALLET_SETTLEMENT: "Merchant Wallet Settlement",
  WALLET_DEBITED_BY_MERCHANT: "Wallet Debited By Merchant",
  WALLET_CREDITED_BY_MERCHANT: "Wallet Credited By Merchant",
  WALLET_FUNDED_THROUGH_BANK_TRANSFER: "Wallet Funded Through Bank Transfer",
  CUSTOMER_BANK_TRANSFER: "Customer Bank Transfer",
  BATCH_DEBIT_CUSTOMER_WALLET: "Batch Debit Customer Wallet",
  BATCH_CREDIT_CUSTOMER_WALLET: "Batch Credit Customer Wallet",
  BATCH_BANK_TRANSFER: "Batch Bank Transfer",
  CUSTOMER_BATCH_CREDIT_CUSTOMER_WALLET:
    "Customer Batch Credit Customer Wallet",
  BATCH_TRANSACTION_REVERSAL: "Batch Transaction Reversal",
  WALLET_CREDITED_BY_CUSTOMER: "Wallet Credited By Customer",
  STAMP_DUTY: "Stamp Duty",
  CARD_DEBIT: "Card Debit",
  CARD_REVERSAL: "Card Reversal",
  CARD_PLACE_LIEN: "Card Place Lien",
  CARD_DEBIT_LIEN: "Card Debit Lien",
  NIP_CREDIT_REVERSAL: "NIP Credit Reversal",
  MASTER_CARD_QR_PAYMENT: "Master Card QR Payment",
  DEBIT_CUSTOMER_DIRECT: "Debit Customer Direct",
  CREDIT_CUSTOMER_DIRECT: "Credit Customer Direct",
  CREDIT_MERCHANT_WALLET: "Credit Merchant Wallet",
  MERCHANT_TO_MERCHANT_TRANSFER: "Merchant To Merchant Transfer",
  WALLET_FUNDED_VIA_SANDBOX: "Wallet Funded Via Sandbox",
};
