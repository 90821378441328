import React, { useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./CustomerDetail.module.css";

import Loading from "components/Loading/Loading";
import AmountModal from "../AmountModal/AmountModal";
import TransferModal from "../Transfer/Transfer";
import BankDetails from "components/BankDetails/BankDetails";
import EditWalletSection from "../EditWallet/EditWallet";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import {
  debitWalletFn,
  creditWalletFn,
  freezeWalletFn,
  unfreezeWalletFn,
  getSingleCustomerFn,
} from "slices/customersSlice";
import { numberWithCommas } from "utils/helpers";
import format from "date-fns/format";
import { OutlineButton } from "components/Button/Button";
import { TIER_DATA } from "constants/globals";

export interface CustomerDetailProps {
  customer: {
    loading: boolean;
    data: any;
    error: any;
  };
  closeModalFn?: () => void | undefined;
  permissions?: string[];
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  customer,
  closeModalFn,
  permissions,
}) => {
  const [modalContent, setModalContent] = useState("");
  const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const debitWallet = (values: { amount: string }) => {
    dispatch(
      debitWalletFn(
        {
          amount: values.amount,
          customerId: customer?.data?.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const creditWallet = (values: { amount: string }) => {
    dispatch(
      creditWalletFn(
        {
          amount: values.amount,
          reference: Date.now(),
          customerId: customer?.data?.customerId,
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const openModal = (content: string) => {
    setModalContent(content);
    setAmountModalIsOpen(true);
  };

  const closeModal = () => {
    setAmountModalIsOpen(false);
    setModalContent("");
  };

  const {
    email,
    status,
    bookedBalance,
    availableBalance,
    accountName,
    accountNumber,
    bankName,
    lastName,
    firstName,
    customerId,
    accountReference,
    tier,
    maxBalance,
    minBalance,
    dailyTransactionLimit,
    createdAt,
    phoneNumber,
  } = customer?.data;

  const renderModalContent = () => {
    if (modalContent === "creditWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={creditWallet}
          status="Credit"
        />
      );
    } else if (modalContent === "debitWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={debitWallet}
          status="Debit"
        />
      );
    } else if (modalContent === "transfer") {
      return <TransferModal customer={customerId} />;
    } else if (modalContent === "edit customer") {
      return (
        <EditWalletSection
          customerData={customer?.data}
          closeModal={closeModal}
        />
      );
    }
  };

  const renderWalletActivation = ({ customerId, status }: any) => {
    if (status === "ACTIVE") {
      return (
        <button
          type="button"
          className="btn btn-light btn-block"
          onClick={() => {
            dispatch(freezeWalletFn(customerId, closeModalFn));
          }}
          style={{ border: "1px solid red" }}
        >
          Freeze Account
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-light btn-block"
          onClick={() => {
            dispatch(unfreezeWalletFn(customerId, closeModalFn));
          }}
          style={{ border: "1px solid #384C55" }}
        >
          Unfreeze Account
        </button>
      );
    }
  };

  if (customer.loading) {
    return <Loading />;
  }

  return (
    <div>
      <ModalBackground
        modalIsOpen={amountModalIsOpen}
        closeModalFn={closeModal}
      />
      {modalContent === "transfer" ? (
        <SideContent modalIsOpen={amountModalIsOpen} closeModalFn={closeModal}>
          {renderModalContent()}
        </SideContent>
      ) : modalContent === "edit customer" ? (
        <SideContent modalIsOpen={amountModalIsOpen} closeModalFn={closeModal}>
          {renderModalContent()}
        </SideContent>
      ) : (
        <SideContent
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          upperModal
        >
          {renderModalContent()}
        </SideContent>
      )}
      <div>
        <p className={styles.customerDetail__fullName}>
          {lastName} {firstName}
        </p>
        <p className={styles.customerDetail__email}>{email}</p>
      </div>
      <BankDetails
        accountName={accountName}
        accountNumber={accountNumber}
        bankName={bankName}
      />

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Email</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {email}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td align="right">{status}</td>
          </tr>
          <tr>
            <td>Book Balance</td>
            <td align="right">&#8358;{numberWithCommas(bookedBalance)}</td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td align="right">&#8358;{numberWithCommas(availableBalance)}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td align="right">{phoneNumber}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {accountReference}
            </td>
          </tr>
          <tr>
            <td>Tier</td>
            <td align="right">{TIER_DATA[tier]}</td>
          </tr>
          <tr>
            <td>Daily Transaction Limit</td>
            <td align="right">
              &#8358;{numberWithCommas(dailyTransactionLimit)}
            </td>
          </tr>
          <tr>
            <td>Minimum Balance</td>
            <td align="right">&#8358;{numberWithCommas(minBalance)}</td>
          </tr>
          <tr>
            <td>Maximum Balance</td>
            {maxBalance === 0 ? (
              <td align="right">Unlimited</td>
            ) : (
              <td align="right">&#8358;{numberWithCommas(maxBalance)}</td>
            )}
          </tr>
          <tr>
            <td>Created At</td>
            <td align="right">{format(new Date(createdAt), "MMM dd, yyyy")}</td>
          </tr>
        </tbody>
      </table>
      {!!permissions?.includes("WALLET_CREDIT_DEBIT") && (
        <OutlineButton
          onClick={() => openModal("creditWallet")}
          block
          title="Credit Customer"
        />
      )}
      {!!permissions?.includes("WALLET_CREDIT_DEBIT") && (
        <OutlineButton
          onClick={() => openModal("debitWallet")}
          block
          title="Debit Customer"
        />
      )}
      {!!permissions?.includes("BROWSE_TRANSACTIONS") && (
        <OutlineButton
          link={`/customers/transactions/${customerId}`}
          block
          title="View Transactions"
        />
      )}
      {!!permissions?.includes("MANAGE_TRANSFER") && (
        <OutlineButton
          onClick={() => openModal("transfer")}
          block
          title="Transfer"
        />
      )}
      {!!permissions?.includes("UPDATE_CUSTOMERS") && (
        <OutlineButton
          onClick={() => openModal("edit customer")}
          block
          title="Edit Customer"
        />
      )}
      {!!permissions?.includes("ENABLE_DISABLE_WALLET") &&
        renderWalletActivation({ customerId, status })}
    </div>
  );
};

export default CustomerDetail;
