import React from "react";
import { Link } from "react-router-dom";

import LogoImg from "assets/images/new-logo.png";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicyPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to="/">
          <img className={styles.navbar__logo} src={LogoImg} alt="logo" />
        </Link>
        <h1 className={styles.head__title}>
          Xpress Wallet Mobile Banking Privacy Policy
        </h1>
      </div>
      <div className={styles.top__paragraph}>
        <p>
          At Xpress Wallet, our tailored financial services delivery include:
          Virtual Wallets Management, Payment Processing Software and
          Integration, and Virtual Marketing, Fast-tracked Service delivery and
          Self-service solutions, as such we treat your Personal Data as private
          and confidential. This Privacy Policy describes what Personal Data we
          collect, what we do with it and how we protect it.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Binding agreement</h2>
        <p className={styles.content__paragraph}>
          By utilizing our services, you agree to be bound by the terms of this
          policy agreement. You consent that we gather and process your Personal
          Data in accordance with this privacy notice (including Our Terms and
          Conditions) and in compliance with the relevant data protection
          regulation and laws.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Information That We Collect</h2>
        <p className={styles.content__paragraph}>
          We process your Personal Data to meet our legal, statutory, and
          contractual obligations and to provide you with our products and
          services. We will never collect any unnecessary personal data from you
          and do not process your information in any way, other than as
          specified in this notice.
        </p>
        <p className={styles.additional__paragraph}>
          The personal data that we collect include but is not limited to:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__item}>Name</li>
          <li className={styles.list__item}>Email Address</li>
          <li className={styles.list__item}>Mobile Telephone Number</li>
          <li className={styles.list__item}>Customer ID</li>
          <li className={styles.list__item}>Sensitive Data (i.e. BVN)</li>
          <li className={styles.list__item}>Phone Book</li>
          <li className={styles.list__item}>Camera information</li>
        </ul>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>How We Use Your Personal Data</h2>
        <p className={styles.content__paragraph}>
          We take your privacy very seriously and will never disclose, share or
          sell your data without your consent, unless required to do so by law.
          We only retain your data for as long as is necessary and for the
          purpose(s) specified in this notice. Where you have consented to us
          providing you with promotional offers and marketing, you are free to
          withdraw this consent at any time.
        </p>
        <p className={styles.additional__paragraph}>
          The purposes and reasons for processing your personal data are
          detailed below:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__item}>
            We collect your personal data in the performance of a contract or to
            provide a service and to ensure that orders are completed and can be
            sent out to your preferred address
          </li>
          <li className={styles.list__item}>
            We collect and store your personal data as part of our legal or
            compliance obligation
          </li>
          <li className={styles.list__item}>
            We have a legal obligation to share your personal data with
            regulatory bodies and credit bureau who are a credit reference
            agency and provide us with financial background checks as part of
            our KYC obligations prior to you opening an account with us
          </li>
          <li className={styles.list__item}>
            We will occasionally send you marketing information where we have
            assessed that it is beneficial to you as our customer and in our
            interests. Such information will be non- intrusive and is processed
            on the grounds of legitimate interests.
          </li>
          <li className={styles.list__item}>
            We or our third-party partners may also use cookies, web beacons or
            other technologies to collect and store other information about your
            visit to, or use of, our online services. In addition, we may later
            associate the usage and other information we collect online with
            personal information about you.
          </li>
        </ul>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Location Tracking</h2>
        <p className={styles.content__paragraph}>
          There may be certain information transmitted to us regarding your
          location when you request information through applications on your
          mobile device, such as your physical location when accessing the App.
          Location data we collect from your mobile device will assist us in
          mitigating against any potential breaches or unauthorized access to
          customer information.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Access to mobile camera</h2>
        <p className={styles.content__paragraph}>
          You agree that using our App we shall have access to your camera to
          collect images and other information from your device to provide you
          this service.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Your Rights</h2>
        <p className={styles.content__paragraph}>
          You have the right to access any Personal Data that we process about
          you and to request information about:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__item}>
            What personal data we hold about you
          </li>
          <li className={styles.list__item}>The purposes of the processing</li>
          <li className={styles.list__item}>
            The categories of personal data concerned
          </li>
          <li className={styles.list__item}>
            The recipients of your personal data or who it will be disclosed to
          </li>
          <li className={styles.list__item}>
            How long we intend to store your personal data for
          </li>
          <li className={styles.list__item}>
            If we did not collect the data directly from you, information about
            the source.
          </li>
        </ul>
        <p className={styles.additional__paragraph}>
          If you believe that we hold any incomplete or inaccurate data about
          you, you have the right to ask us to correct and/or complete the
          information and we will strive to correct it as quickly as possible;
          unless there is a valid reason for not doing so, at which point you
          will be notified. You also have the right to request erasure of your
          personal data or to restrict processing (where applicable) in
          accordance with the data protection laws; as well as to object to any
          direct marketing from us; to exercise your data portability rights,
          and to be informed about any automated decision-making we may use.
        </p>
        <p className={styles.additional__paragraph}>
          If we receive a request from you to exercise any of the above rights,
          we may ask you to verify your identity before acting on the request;
          this is to ensure that your data is protected and kept secure.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>
          Sharing and Disclosing Your Personal Data
        </h2>
        <p className={styles.content__paragraph}>
          We do not share or disclose any of your Personal Data without your
          consent, other than for the purposes specified in this notice or where
          there is a legal requirement. We use [a third- party/third-parties] to
          provide the below services and business functions; however, all
          processors acting on our behalf only process your data in accordance
          with instructions from us and comply fully with this privacy notice,
          the data protection laws and any other appropriate confidentiality and
          security measures.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Safeguarding Measures</h2>
        <p className={styles.content__paragraph}>
          We take your privacy seriously and take every reasonable measure and
          precaution to protect and secure your personal data. We work hard to
          protect you and your information from unauthorised access, alteration,
          disclosure or destruction and have several layers of security measures
          in place, including but not limited to network security, password,
          Policies, SSL, TLS, encryptions, pseudonymisation, restricted access,
          IT authentication, firewalls, anti- virus/malware etc
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Transfers Outside Nigeria</h2>
        <p className={styles.content__paragraph}>
          Personal data in Nigeria is protected by the Nigeria Data Protection
          Regulation (NDPR) but some other countries may not necessarily have
          the same high standard of protection for your personal data. Xpress
          Wallet does not transfer or store any personal data outside Nigeria.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Legitimate Interests</h2>
        <p className={styles.content__paragraph}>
          As noted in the 'How We Use Your Personal Data' section of this
          notice, we occasionally process your Personal Data under the
          legitimate interests' legal basis. Where this is the case, we have
          carried out a thorough Legitimate Interests' Assessment (LIA) to
          ensure that we have weighed your interests and any risk posed to you
          against our own interests, ensuring that they are proportionate and
          appropriate.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>How Long We Keep Your Data</h2>
        <p className={styles.content__paragraph}>
          Xpress Wallet only ever retains Personal Data for as long as is
          necessary by regulation and we have strict review and retention
          policies in place to meet these obligations. We are required under
          Nigerian laws/CBN Guidelines to keep your basic personal data (name,
          address, contact details) for not more than 7 years after termination
          of business relationship.
        </p>
        <p className={styles.additional__paragraph}>
          Where you have consented to us using your details for direct
          marketing, we will keep such data until you notify us otherwise and/or
          withdraw your consent.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Sensitive Data</h2>
        <p className={styles.content__paragraph}>
          Owing to our Legal/Regulatory obligations, Xpress Wallet sometimes
          needs to process sensitive Personal Data (known as sensitive data)
          about you, to [uniquely identify/distinguish you and sometimes for
          regulatory reasons]. Where we collect such information, we will only
          request and process the minimum necessary for the specified purpose
          and identify a compliant legal basis for doing so. Where we rely on
          your consent for processing sensitive data, we will obtain your
          explicit consent through [a signature/explicit mechanism]. You can
          modify or withdraw consent at any time, which we will act on
          immediately unless there is a legitimate or legal reason for not doing
          so.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>
          Disclosure of Your Personal Data
        </h2>
        <p className={styles.content__paragraph}>
          Law enforcement Under certain circumstances, we may be required to
          disclose Your Personal Data if required to do so by Law or in response
          to valid requests by public authorities (e.g. a court or a government
          agency).
        </p>
        <p className={styles.additional__paragraph}>
          Other legal requirements We may disclose Your Personal Data in the
          good faith belief that such action is necessary to:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__item}>Comply with a legal obligation</li>
          <li className={styles.list__item}>
            Protect and defend the rights or property of the Bank
          </li>
          <li className={styles.list__item}>
            Prevent or investigate possible wrongdoing in connection with the
            service or services we provide
          </li>
          <li className={styles.list__item}>
            Protect the personal safety of our staff or customers or the public
          </li>
          <li className={styles.list__item}>Protect against legal liability</li>
        </ul>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Cookie Notice</h2>
        <p className={styles.content__paragraph}>
          A 'cookie' is a small piece of data sent from a website and stored on
          the user's computer by the user's web browser, a cookie is downloaded
          onto your mobile device so that the next time you visit that site,
          your device will remember useful information.
        </p>
        <p className={styles.additional__paragraph}>
          When You access the Service by or through your mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>
          Keeping Your Personal Data Safe
        </h2>
        <p className={styles.content__paragraph}>
          Our cookies never store personal or sensitive information; they simply
          hold a unique random reference to you. The cookies we use cannot read
          or search your mobile device to obtain information about you or your
          family, or read any material kept on your device.
        </p>
        <p className={styles.additional__paragraph}>
          Your Personal Data is used in:
        </p>
        <ol className={styles.list__container}>
          <li className={styles.list__item}>
            Updating and enhancing our records.
          </li>
          <li className={styles.list__item}>Executing your instructions.</li>
          <li className={styles.list__item}>
            Establishing your identity and assessing applications.
          </li>
          <li className={styles.list__item}>
            Pricing and designing our products and services.
          </li>
          <li className={styles.list__item}>
            Administering our products and services.
          </li>
          <li className={styles.list__item}>
            Managing our relationship with you.
          </li>
          <li className={styles.list__item}>Managing our risks.</li>
          <li className={styles.list__item}>
            Identifying and investigating illegal activity such as fraud.
          </li>
          <li className={styles.list__item}>
            Contacting you, in instances where we suspect fraud on your account
          </li>
          <li className={styles.list__item}>
            Conducting and improving our businesses and improving your
            experience
          </li>
          <li className={styles.list__item}>
            Reviewing credit or loan eligibility.
          </li>
          <li className={styles.list__item}>
            Preventing money laundering or terrorism financing activities.
          </li>
          <li className={styles.list__item}>
            Complying with our legal obligations and assisting government and
            other law enforcement agencies or regulators.
          </li>
          <li className={styles.list__item}>
            Identifying and informing you about other products or services.
          </li>
          <li className={styles.list__item}>
            Processing your job application if you apply for a job with us.
            Information We Share
          </li>
        </ol>
        <p className={styles.additional__paragraph}>
          We may share the information about you and your dealings with us, to
          the extent permitted by law, with the following:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__item}>
            Xpress Wallet and/or its Subsidiaries
          </li>
          <li className={styles.list__item}>
            Regulators/Supervisors, Government Agencies/courts - It may be
            necessary by law, legal process, litigation, and/or requests from
            public and governmental authorities within or outside your country
            of residence for Xpress Wallet to disclose your Personal Data. We
            may also disclose information about you if we determine that for
            purposes of national security, law enforcement, or other issues of
            public importance, disclosure is necessary or appropriate.
          </li>
          <li className={styles.list__item}>External Auditors.</li>
          <li className={styles.list__item}>Xpress Wallet staff.</li>
          <li className={styles.list__item}>Credit Agencies.</li>
          <li className={styles.list__item}>Correspondent banks.</li>
        </ul>
        <p className={styles.additional__paragraph}>
          We may also collect, use and exchange your information in ways
          permitted by law.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>
          How We Protect Your Information
        </h2>
        <p className={styles.content__paragraph}>
          We take appropriate technical and organizational measures to prevent
          loss, unauthorized access, misuse, modification or disclosure of
          information under our control. This may include the use of encryption,
          access controls and other forms of security to ensure that your data
          is protected. We require all parties including our staff and
          third-parties processing data on our behalf to comply with relevant
          policies and guidelines to ensure confidentiality and that information
          is protected in use, when stored and during transmission. Our security
          controls and processes are also regularly updated to meet and exceed
          industry standards
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>
          Changes to this Privacy Policy
        </h2>
        <p className={styles.content__paragraph}>
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page. We will
          let You know via email and/or a prominent notice on our service, prior
          to the change becoming effective and update the "Last updated" date at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </p>
      </div>
      <div className={styles.content__container}>
        <h2 className={styles.content__title}>Contact Us</h2>
        <p className={styles.content__paragraph}>
          If you have any questions about this Privacy Policy, you can contact
          us:
        </p>
        <ul className={styles.list__container}>
          <li className={styles.list__contact}>
            <span className={styles.contact__opacity}>By email:</span>{" "}
            <Link to="#">businessconcierge@Xpress Wallet.com</Link>{" "}
            <span className={styles.contact__opacity}>or</span>{" "}
            <Link to="#">dpo@Xpress Wallet.com</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
