import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import routes from "./routes";

// route guards
import BankRoute from "./BankRoute";
import PrivateRoute from "./PrivateRoute";
import PartnerRoute from "./PartnerRoute";

// Authentication routes
import LoginScreen from "../screens/Auth/Login/Login";
import RegisterScreen from "../screens/Auth/Register/Register";
import ChangePasswordScreen from "screens/Auth/ChangePassword/ChangePassword";
import ForgotPasswordScreen from "screens/Auth/ForgotPassword/ForgotPassword";
import AcceptInvitationComponent from "screens/Merchant/Team/components/AcceptInvitation/AcceptInvitation";

import PageNotFound from "../screens/PageNotFound";
import PrivacyPolicyScreen from "screens/PrivacyPolicy/PrivacyPolicy";
import TermsOfUsePage from "screens/TermsOfUse/TermsOfUse";

// Merchant routes
import KYCScreen from "screens/Merchant/Kyc/Kyc";
import HomeScreen from "../screens/Merchant/Home/Home";
import CustomersScreen from "../screens/Merchant/Customers/Customers";
import CustomerTransactionsScreen from "../screens/Merchant/Customers/components/Transactions/Transactions";
import BankTransferComponent from "screens/Merchant/SendMoney/components/BankTransfer/BankTransfer";
import CustomerBankTransferComponent from "screens/Merchant/SendMoney/components/CustomerBankTransfer/CustomerBankTransfer";
import BatchBankTransferComponent from "screens/Merchant/SendMoney/components/BatchBankTransfer/BatchBankTransfer";
import BatchCustomersBankTransferComponent from "screens/Merchant/SendMoney/components/BatchCustomersBankTransfer/BatchCustomersBankTransfer";
import CreditCustomerWalletComponent from "screens/Merchant/SendMoney/components/CreditCustomerWallet/CreditCustomerWallet";
import DebitCustomerWalletComponent from "screens/Merchant/SendMoney/components/DebitCustomerWallet/DebitCustomerWallet";
import CustomerToCustomersTransferComponent from "screens/Merchant/SendMoney/components/CustomerToCustomersTransfer/CustomerToCustomersTransfer";
import BatchCreditCustomersComponent from "screens/Merchant/SendMoney/components/BatchCreditCustomers/BatchCreditCustomers";
import BatchDebitCustomersComponent from "screens/Merchant/SendMoney/components/BatchDebitCustomers/BatchDebitCustomers";
import BatchCustomerToCustomersTransferComponent from "screens/Merchant/SendMoney/components/BatchCustomerToCustomersTransfer/BatchCustomerToCustomersTransfer";
import VerificationScreen from "../screens/Merchant/Verification/Verification";
import ViaMobileMoney from "screens/Merchant/SendMoney/components/MobileMoney/MobileMoney";
import ProfileScreen from "screens/Merchant/Settings/Profile/Profile";
import SecurityScreen from "screens/Merchant/Settings/Security/Security";
import TransactionsScreen from "../screens/Merchant/Transactions/Transactions";
import PendingTransactionsScreen from "screens/Merchant/Transactions/components/PendingTransactions/PendingTransactions";
import BatchTransactionsScreen from "screens/Merchant/Transactions/components/BatchTransactions/BatchTransactions";
import TransactionHistory from "screens/Merchant/Transactions/components/TransactionHistory/TransactionHistory";
import CustomerTransactionHistory from "screens/Merchant/Transactions/components/CustomerTransactionHistory/CustomerTransactionHistory";
import SubsidiariesScreen from "../screens/Merchant/Subsidiaries/Subsidiaries";
import SubsidiaryDetailsComponent from "../screens/Merchant/Subsidiaries/components/SubsidiaryDetails/SubsidiaryDetails";
import UpdateSubsidiaryComponent from "../screens/Merchant/Subsidiaries/components/UpdateSubsidiary/UpdateSubsidiary";
import AccountReviewScreen from "screens/Merchant/AccountReview/AccountReview";
import NotificationsScreen from "screens/Merchant/Settings/Notifications/Notifications";
import DeveloperKeysScreen from "screens/Merchant/Settings/DeveloperKeys/DeveloperKeys";
import AccountVerificationScreen from "screens/Merchant/Settings/AccountVerification/AccountVerification";
import RolesScreen from "screens/Merchant/Roles/Roles";
import TeamScreen from "screens/Merchant/Team/Team";

// Partner routes
import PartnerHomeScreen from "screens/Partners/Home/Home";
import PartnerMerchantScreen from "screens/Partners/Merchant/Merchant";
import PartnerMerchantDetailsComponent from "screens/Partners/Merchant/components/MerchantDetails/MerchantDetails";
import PartnerUpdateMerchantComponent from "screens/Partners/Merchant/components/UpdateMerchant/UpdateMerchant";
import PartnerTransactionScreen from "screens/Partners/Transaction/Transaction";
import PartnerCardHolderScreen from "screens/PartnerCardHolder/PartnerCardHolder";
import PartnerSubMerchantScreen from "screens/Partners/SubMerchants/SubMerchants";
import PartnerSubMerchantDetailsComponent from "screens/Partners/SubMerchants/components/MerchantDetails/MerchantDetails";
import PartnerUpdateSubMerchantComponent from "screens/Partners/SubMerchants/components/UpdateMerchant/UpdateMerchant";
import PartnerActivatedCardScreen from "screens/PartnerActivatedCard/PartnerActivatedCard";

// Bank routes
import BankHomeScreen from "screens/Bank/Home/Home";
import BankReportScreen from "screens/Bank/Report/Report";
import BankPartnerScreen from "screens/Bank/Partner/Partner";
import BankMerchantScreen from "screens/Bank/Merchant/Merchant";
import BankTransactionScreen from "screens/Bank/Transaction/Transaction";
import BankMerchantDetailsComponent from "screens/Bank/Merchant/components/MerchantDetails/MerchantDetails";
import UpdateBankMerchantComponent from "screens/Bank/Merchant/components/UpdateMerchant/UpdateMerchant";
import BankPartnerDetailsScreen from "screens/Bank/Partner/components/PartnerDetail/PartnerDetail";
import BankCustomersScreen from "screens/Bank/Customers/Customers";
import BankCustomerTransactionsScreen from "screens/Bank/Customers/components/Transactions/Transactions";
import BankMerchantTransactionScreen from "screens/Bank/Merchant/components/Transactions/Transactions";
import NIPStatusCodeSettings from "screens/Bank/NipSettings/settings";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute
          exact
          path={routes.VIA_BANK}
          component={BankTransferComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.VIA_BANK}/cutomer`}
          component={CustomerBankTransferComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.VIA_BANK}/batch`}
          component={BatchBankTransferComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.VIA_BANK}/batch-cutomer`}
          component={BatchCustomersBankTransferComponent}
        />
        <PrivateRoute
          exact
          path={routes.VIA_WALLET}
          component={CreditCustomerWalletComponent}
        />
        <PrivateRoute
          exact
          path={routes.SEND_MONEY}
          component={CreditCustomerWalletComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SEND_MONEY}/debit`}
          component={DebitCustomerWalletComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SEND_MONEY}/customer-to-customer-transfer`}
          component={CustomerToCustomersTransferComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SEND_MONEY}/batch-credit`}
          component={BatchCreditCustomersComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SEND_MONEY}/batch-debit`}
          component={BatchDebitCustomersComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SEND_MONEY}/batch-customer-to-customer-transfer`}
          component={BatchCustomerToCustomersTransferComponent}
        />
        <PrivateRoute path={routes.PROFILE} component={ProfileScreen} />
        <PrivateRoute path={routes.SETTINGS} component={ProfileScreen} exact />
        <PrivateRoute path={routes.SECURITY} component={SecurityScreen} />
        <PrivateRoute
          exact
          path={routes.CUSTOMERS}
          component={CustomersScreen}
        />
        <PrivateRoute
          exact
          path={`${routes.CUSTOMERS}/transactions/:customer`}
          component={CustomerTransactionsScreen}
        />
        <PrivateRoute
          path={routes.VIA_MOBILE_MONEY}
          component={ViaMobileMoney}
        />
        <PrivateRoute
          exact
          path={routes.TRANSACTIONS}
          component={TransactionsScreen}
        />
        <PrivateRoute
          exact
          path={`${routes.TRANSACTIONS}/pending`}
          component={PendingTransactionsScreen}
        />
        <PrivateRoute
          exact
          path={`${routes.TRANSACTIONS}/batch`}
          component={BatchTransactionsScreen}
        />
        <PrivateRoute
          exact
          path={routes.SUBSIDIARIES}
          component={SubsidiariesScreen}
        />
        <PrivateRoute
          exact
          path={`${routes.SUBSIDIARIES}/:merchant`}
          component={SubsidiaryDetailsComponent}
        />
        <PrivateRoute
          exact
          path={`${routes.SUBSIDIARIES}/:merchant/update`}
          component={UpdateSubsidiaryComponent}
        />
        <PrivateRoute
          path={routes.NOTIFICATIONS}
          component={NotificationsScreen}
        />
        <PrivateRoute
          path={routes.DEVELOPER_KEYS}
          component={DeveloperKeysScreen}
        />
        <PrivateRoute
          path={routes.ACCOUNT_VERIFICATION}
          component={AccountVerificationScreen}
        />
        <PrivateRoute path={routes.HOME} component={HomeScreen} />
        <PrivateRoute path={`${routes.KYC}/:id`} component={KYCScreen} />
        <PrivateRoute
          path={routes.ACCOUNT_REVIEW}
          component={AccountReviewScreen}
        />
        <PrivateRoute path={routes.ROLES} component={RolesScreen} />
        <PrivateRoute path={routes.TEAM} component={TeamScreen} />
        <PrivateRoute
          exact
          path={routes.TRANSACTION_HISTORY}
          component={TransactionHistory}
        />
        <PrivateRoute
          exact
          path={`${routes.TRANSACTION_HISTORY}/:customer`}
          component={CustomerTransactionHistory}
        />

        <PartnerRoute
          path={routes.PARTNER_HOME}
          component={PartnerHomeScreen}
        />
        <PartnerRoute
          exact
          path={routes.PARTNERMERCHANT}
          component={PartnerMerchantScreen}
        />
        <PartnerRoute
          exact
          path={`${routes.PARTNERMERCHANT}/:merchant`}
          component={PartnerMerchantDetailsComponent}
        />
        <PartnerRoute
          exact
          path={`${routes.PARTNERMERCHANT}/:merchant/update`}
          component={PartnerUpdateMerchantComponent}
        />
        <PartnerRoute
          path={routes.PARTNERTRANSACTION}
          component={PartnerTransactionScreen}
        />
        <PartnerRoute
          exact
          path={routes.PARTNER_SUBSIDIARY_MERCHANT}
          component={PartnerSubMerchantScreen}
        />
        <PartnerRoute
          exact
          path={`${routes.PARTNER_SUBSIDIARY_MERCHANT}/:merchant`}
          component={PartnerSubMerchantDetailsComponent}
        />
        <PartnerRoute
          exact
          path={`${routes.PARTNER_SUBSIDIARY_MERCHANT}/:merchant/update`}
          component={PartnerUpdateSubMerchantComponent}
        />

        <PartnerRoute
          path={routes.CARD_HOLDERS}
          component={PartnerCardHolderScreen}
        />
        <PartnerRoute
          path={routes.ACTIVATED_CARDS}
          component={PartnerActivatedCardScreen}
        />

        <Route path={routes.REGISTER} component={RegisterScreen} />
        <Route exact path={routes.LOGIN} component={LoginScreen} />
        <Route path={routes.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
        <Route
          path={`${routes.VERIFICATION}/:email`}
          component={VerificationScreen}
        />
        <Route path={routes.VERIFICATION} component={VerificationScreen} />
        <Route path={routes.CHANGE_PASSWORD} component={ChangePasswordScreen} />
        <Route
          path={`${routes.ACCEPT_INVITATION}/:link`}
          component={AcceptInvitationComponent}
        />

        <Route
          exact
          path={`${routes.PRIVACY_POLICY}`}
          component={PrivacyPolicyScreen}
        />
        <Route
          exact
          path={`${routes.TERMS_OF_USE}`}
          component={TermsOfUsePage}
        />

        <BankRoute path={routes.BANK_HOME} component={BankHomeScreen} />
        <BankRoute path={routes.BANK_REPORT} component={BankReportScreen} />
        <BankRoute
          exact
          path={routes.BANK_PARTNER}
          component={BankPartnerScreen}
        />
        <BankRoute
          exact
          path={`${routes.BANK_PARTNER}/:partner`}
          component={BankPartnerDetailsScreen}
        />
        <BankRoute
          exact
          path={routes.BANK_MERCHANT}
          component={BankMerchantScreen}
        />
        <BankRoute
          path={routes.BANK_TRANSACTION}
          component={BankTransactionScreen}
        />
        <BankRoute
          exact
          path={`${routes.BANK_MERCHANT}/:merchant`}
          component={BankMerchantDetailsComponent}
        />
        <BankRoute
          exact
          path={`${routes.BANK_MERCHANT}/:merchant/update`}
          component={UpdateBankMerchantComponent}
        />
        <BankRoute
          exact
          path={routes.BANK_CUSTOMER}
          component={BankCustomersScreen}
        />
        <BankRoute
          exact
          path={`${routes.BANK_CUSTOMER}/:customer/transactions`}
          component={BankCustomerTransactionsScreen}
        />
        <BankRoute
          exact
          path={`${routes.BANK_MERCHANT}/:merchant/transactions`}
          component={BankMerchantTransactionScreen}
        />
        <BankRoute
          exact
          path={`${routes.BANK_NIP_SETTINGS}`}
          component={NIPStatusCodeSettings}
        />

        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
