import * as Yup from "yup";
import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";

import routes from "routing/routes";
import styles from "./Register.module.css";
import AuthLayout from "layouts/Auth/Auth";
import TextInput from "components/TextInput/TextInput";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import {
  registerUserFn,
  selectAuthState,
  RegisterValuesTypes,
} from "slices/authSlice";
import { handleInputValidation } from "utils/helpers";

const { BUSINESS_CATEGORY } = require("constants/business");

const RegisterScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const registerState = useSelector(selectAuthState);

  const submitForm = (values: RegisterValuesTypes) => {
    dispatch(registerUserFn(values));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout title="Registration">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phoneNumber: "",
          businessName: "",
          businessType: "",
          agreement: false,
          accountNumber: "",
        }}
        onSubmit={submitForm}
        validationSchema={RegisterSchema}
      >
        {({ errors, touched, values }) => {
          const businessTypeClassname = `form-control form-control-lg ${
            errors.businessType && touched.businessType ? "is-invalid" : ""
          }`;

          const isAgreementSelected = values.agreement;

          return (
            <Form>
              <div className="row">
                <div className="col-6">
                  <Field
                    name="firstName"
                    placeholder="First Name*"
                    type="text"
                    component={TextInput}
                  />
                </div>
                <div className="col-6">
                  <Field
                    name="lastName"
                    placeholder="Last Name*"
                    type="text"
                    component={TextInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Field
                    name="email"
                    placeholder="Email Address*"
                    type="email"
                    component={TextInput}
                  />
                </div>
                <div className="col-6">
                  <Field
                    name="phoneNumber"
                    placeholder="Phone Number*"
                    type="tel"
                    autoComplete="tel"
                    component={TextInput}
                    maxLength="11"
                  />
                </div>
              </div>
              <Field
                name="password"
                placeholder="Password*"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                show={showPassword}
                toggleShow={toggleShowPassword}
                component={PasswordInput}
              />
              <div style={{ marginTop: "15px" }}>
                <Field
                  name="businessName"
                  placeholder="Business Name*"
                  type="text"
                  component={TextInput}
                />
              </div>

              <div className="form-group">
                <Field
                  name="businessType"
                  component="select"
                  placeholder="Favorite Color"
                  className={businessTypeClassname}
                >
                  <option value="" disabled hidden>
                    Select Business*
                  </option>
                  {BUSINESS_CATEGORY.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </Field>
                {errors.businessType && touched.businessType && (
                  <div className="invalid-feedback">{errors.businessType}</div>
                )}
              </div>
              <Field
                type="text"
                name="accountNumber"
                component={TextInput}
                placeholder="Providus Account Number*"
                maxLength="10"
              />

              <Field name="agreement">
                {({ field, meta }: any) => (
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="agreement"
                      {...field}
                    />
                    <label className="form-check-label" htmlFor="agreement">
                      I agree to the{" "}
                      <a
                        href={routes.TERMS_OF_USE}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        terms of use
                      </a>{" "}
                      and{" "}
                      <a
                        href={routes.PRIVACY_POLICY}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        privacy policy
                      </a>
                      .
                    </label>
                    {meta.touched && meta.error && (
                      <div className={styles.error}>{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>

              {registerState.register.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <PrimaryButton
                    type="submit"
                    block
                    size="lg"
                    title="Get Started"
                    disabled={!isAgreementSelected}
                  />
                  <OutlineButton
                    block
                    size="lg"
                    link={routes.LOGIN}
                    title="Login"
                  />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default RegisterScreen;

const RegisterSchema = Yup.object().shape({
  businessType: Yup.string().required("Required"),
  accountNumber: Yup.string()
    .required("Account number is required")
    .length(10, "Account number must be 10 characters long")
    .test(
      "onlyNumber",
      "Account number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  agreement: Yup.boolean().oneOf([true], "You must accept agreement"),
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(11, "Too Short!")
    .max(11, "Too Long!")
    .required("Required")
    .test(
      "onlyNumber",
      "Phone number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  businessName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required")
    .test(
      "noExtraSpace",
      "Extra spaces not accepted",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
});
