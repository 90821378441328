import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { string, mixed, object } from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";

import Settings from "../Settings";
import routes from "routing/routes";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { getProfileFn, selectSettingsState } from "slices/settingsSlice";
import { selectProfile } from "slices/merchantSlice";
import request from "constants/requests";
import { handleInputValidation } from "utils/helpers";
import { today } from "utils/helpers";

const AccountVerificationScreen: React.FC = () => {
  const history = useHistory();

  const fetchProfile = () => {
    dispatch(getProfileFn());
  };

  useEffect(fetchProfile, []);

  const dispatch = useDispatch();
  const profileState = useSelector(selectSettingsState);
  const { data: user, loading: userLoading } = profileState.getProfile;
  const { permissions: profilePermissions } = useSelector(selectProfile);
  const [loading, setLoading] = useState(false);

  const submitKYC = async (values) => {
    if (!profilePermissions?.includes("UPDATE_MERCHANT_DETAIL")) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.set("bvn", values?.bvn);
      formData.set(
        "dateOfBirth",
        format(
          parse(values?.dateOfBirth, "yyyy-MM-dd", new Date()),
          "dd-MM-yyyy"
        )
      );
      values?.cacPack?.forEach((each) =>
        formData.append("cac_pack", each, each?.name)
      );

      await request({
        method: "put",
        data: formData,
        url: "/merchant/complete-merchant-registration",
        headers: { "Content-Type": "multipart/form-data" },
      });
      history.push(routes.ACCOUNT_REVIEW);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (userLoading && !user.id) {
      return <Loading />;
    } else if (user.review === "ENABLED") {
      return (
        <div>
          <p className="mb-0">Identity Verification</p>
          <p className="settings__lightText">
            Your KYC information has been verified.
          </p>
        </div>
      );
    } else if (user.review === "IN-REVIEW") {
      return (
        <div>
          <p className="mb-0">Identity Verification</p>
          <p className="settings__lightText">
            Your KYC information is in review.
          </p>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-6">
            <h2 className="mb-2">Director's Details</h2>
            <p className="settings__lightText mb-3">
              Submit the KYC(Know Your Customer) information to verifiy you
              account and complete registration
            </p>
            <Formik
              initialValues={{ bvn: "", dateOfBirth: "", cacPack: null }}
              validationSchema={object().shape({
                bvn: string()
                  .required("BVN is required")
                  .length(11, "BVN must be 11 characters long")
                  .test(
                    "onlyNumber",
                    "BVN must contain numbers only",
                    (val) => handleInputValidation(val)?.onlyNumber
                  ),
                dateOfBirth: string().required("Date of birth is required"),
                cacPack: mixed().required("CAC pack is required"),
              })}
              onSubmit={submitKYC}
            >
              {({ setFieldValue, errors, touched, values }) => (
                <Form>
                  <Field
                    name="bvn"
                    placeholder="BVN"
                    type="text"
                    component={TextInput}
                  />

                  <Field
                    name="dateOfBirth"
                    placeholder="YYYY-MM-DD"
                    type="date"
                    component={TextInput}
                    max={today}
                  />

                  <div className="form-group">
                    <label htmlFor="cac">Upload CAC Pack</label>

                    <div className="custom-file">
                      <input
                        id="cac"
                        type="file"
                        name="cacPack"
                        className="custom-file-input"
                        onChange={(e) => {
                          setFieldValue("cacPack", Array.from(e.target.files));
                        }}
                        accept="image/png, image/jpeg,image/jpg,.pdf"
                        multiple
                      />
                      <label className="custom-file-label" htmlFor="cac">
                        {values?.cacPack ? "File Selected" : "Choose file"}
                      </label>
                    </div>
                    {errors.cacPack && touched.cacPack && (
                      <div className="invalid-feedback">{errors.cacPack}</div>
                    )}
                  </div>

                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    !!profilePermissions?.includes(
                      "UPDATE_MERCHANT_DETAIL"
                    ) && <PrimaryButton block type="submit" title="Submit" />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }
  };
  return <Settings>{renderContent()}</Settings>;
};

export default AccountVerificationScreen;
