import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./BatchTransactions.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  batchTransactionsFn,
  selectBatchTransactionsState,
  selectSingleBatchTransactionState,
  getSingleBatchTransactionFn,
} from "slices/transactionsSlice";
// import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import TransactionDetail from "../TransactionDetail";
import { CATEGORY_LIST, CATEGORY_DESCRIPTION } from "constants/transaction";
import routes from "routing/routes";
import { OutlineButton } from "components/Button/Button";

import DownArrow from "assets/images/down-arrow.svg";

const BatchTransactionsScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const transactionsState = useSelector(selectBatchTransactionsState);
  const singleBatchTransactionState = useSelector(
    selectSingleBatchTransactionState
  );
  const {
    data: transactionsData,
    meta: transactionsMeta,
    loading,
  } = transactionsState;

  const [currentPage, setCurrentPage] = useState(transactionsMeta?.page);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("ALL");
  const [toggleFilter, setToggleFilter] = useState(false);

  // const user = JSON.parse(localStorage.getItem('user'));

  const fetchTransactions = () => {
    dispatch(batchTransactionsFn({ page: currentPage, category, type }));
  };

  useEffect(fetchTransactions, [currentPage]);

  const handlefilter = () => {
    dispatch(batchTransactionsFn({ page: currentPage, category, type }));
  };

  const renderRows = () => {
    return transactionsData?.map((each: any, index: number) => {
      return transactionsData?.length ? (
        <tr
          onClick={() => getTransaction(each?.reference)}
          style={{ cursor: "pointer" }}
          key={index}
        >
          {/* <td style={{ display: "flex", alignItems: "center" }}>
                        <span
                            style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                                display: "inline-block",
                                backgroundColor: each.status === "success" ? "#FBB900" : "red",
                            }}
                        />
                        {each?.reference}
                    </td> */}
          <td>{each?.type}</td>
          <td className={styles.descriptionRow}>
            {CATEGORY_DESCRIPTION[each?.category]}
          </td>
          <td>{format(parseISO(each?.createdAt), "dd-MM-yyyy p")}</td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            No Transaction Available
          </td>
        </tr>
      );
    });
  };

  const getTransaction = (reference: string) => {
    dispatch(
      getSingleBatchTransactionFn(reference, () => {
        openModal("transactionDetail");
      })
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;

    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "transactionDetail") {
      return (
        <TransactionDetail
          transaction={singleBatchTransactionState}
          status="batch"
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {loading && !toggleFilter && !transactionsData?.length ? (
          <Loading />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Transactions</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Batch Transactions
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {transactionsMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-light mr-3`}
                    onClick={() => history.push(routes.TRANSACTIONS)}
                  >
                    Approved
                  </button>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-light mr-3`}
                    onClick={() =>
                      history.push(`${routes.TRANSACTIONS}/pending`)
                    }
                  >
                    Pending
                  </button>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-warning`}
                    onClick={() => history.push(`${routes.TRANSACTIONS}/batch`)}
                  >
                    Batch
                  </button>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!searchValue) {
                    toast.error("Search value is required");
                    return;
                  }
                  getTransaction(searchValue);
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchTransaction">
                    Search transaction
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <div className="input-group" style={{ flex: 1 }}>
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              borderRightColor: "transparent",
                              backgroundColor: "transparent",
                            }}
                          >
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                        <input
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                          style={{ borderLeftColor: "transparent" }}
                          type="text"
                          className="form-control"
                          id="searchTransaction"
                          placeholder="Search with transaction Reference"
                        />
                      </div>
                      <OutlineButton
                        type="submit"
                        title="Search"
                        size="sm"
                        style={{ padding: "6px 20px" }}
                      />
                    </div>
                    <div className={styles.filter__container}>
                      <div
                        className={styles.filter__placeholder}
                        onClick={() => setToggleFilter(!toggleFilter)}
                      >
                        All{" "}
                        <img
                          src={DownArrow}
                          alt=""
                          className={styles.filter__icon}
                        />
                      </div>
                      <div
                        className={styles.filter__content}
                        style={{ display: toggleFilter ? "block" : "none" }}
                      >
                        <div className={styles.filter__content__top}>
                          <p className={styles.filter__top__text}>FILTER</p>
                          <button
                            className={styles.filter__button}
                            onClick={handlefilter}
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                        <div className={styles.filter__content__bottom}>
                          <select
                            onChange={(e) => setType(e.target.value)}
                            className={styles.filter__select}
                          >
                            <option value="ALL">-- Select Type --</option>
                            <option value="CREDIT">Credit</option>
                            <option value="DEBIT">Debit</option>
                          </select>
                          <select
                            onChange={(e) => setCategory(e.target.value)}
                            className={styles.filter__select}
                          >
                            <option value="">-- Select Category --</option>
                            {CATEGORY_LIST.map((each) => (
                              <option value={each} key={each}>
                                {CATEGORY_DESCRIPTION[each]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <Table
                headers={["type", "description", "date"]}
                noData={
                  transactionsData?.length === 0 && !loading ? true : false
                }
              >
                {loading ? (
                  <tr className="bg-white">
                    <td colSpan={5} className="text-center">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {transactionsMeta?.totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={transactionsMeta.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
      <div
        className={styles.filter__overlay}
        style={{ display: toggleFilter ? "block" : "none" }}
        onClick={() => setToggleFilter(false)}
      />
    </>
  );
};

export default BatchTransactionsScreen;
