import React from "react";

import styles from "./CustomerDetail.module.css";

import Loading from "components/Loading/Loading";
import BankDetails from "components/BankDetails/BankDetails";
import { numberWithCommas } from "utils/helpers";
import format from "date-fns/format";
import { TIER_DATA } from "constants/globals";

export interface CustomerDetailProps {
  customer: {
    loading: boolean;
    data: any;
  };
  closeModalFn?: () => void | undefined;
  permissions?: string[];
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({ customer }) => {
  const { email, wallet, lastName, firstName, tier, createdAt, phoneNumber } =
    customer?.data;

  if (customer.loading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <p className={styles.customerDetail__fullName}>
          {lastName} {firstName}
        </p>
        <p className={styles.customerDetail__email}>{email}</p>
      </div>
      <BankDetails
        accountName={wallet?.accountName}
        accountNumber={wallet?.accountNumber}
        bankName={wallet?.bankName}
      />

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Email</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {email}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td align="right" style={{ textTransform: "capitalize" }}>
              {wallet?.status?.toLowerCase()}
            </td>
          </tr>
          <tr>
            <td>Book Balance</td>
            <td align="right">
              &#8358;{numberWithCommas(wallet?.bookedBalance)}
            </td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td align="right">
              &#8358;{numberWithCommas(wallet?.availableBalance)}
            </td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td align="right">{phoneNumber}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {wallet?.accountReference}
            </td>
          </tr>
          <tr>
            <td>Tier</td>
            <td align="right">{TIER_DATA[tier]}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td align="right">{format(new Date(createdAt), "MMM dd, yyyy")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CustomerDetail;
