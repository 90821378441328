import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import styles from "./Customers.module.css";
import AddWalletImg from "assets/images/addWallet.svg";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import { selectWalletState, createWalletFn } from "slices/walletSlice";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
  getCustomersFn,
  selectCustomerState,
  getSingleCustomerFn,
} from "slices/customersSlice";
import CreateWalletSection, {
  CreateWalletValuesTypes,
} from "screens/Merchant/Home/components/CreateWallet/CreateWallet";
import { selectProfile } from "slices/merchantSlice";
import { OutlineButton } from "components/Button/Button";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const customersState = useSelector(selectCustomerState);
  // All customers
  const {
    data: customersData,
    meta: customersMeta,
    loading: customerIsLoading,
  } = customersState.customers;
  // Single customer
  const { customerData: customer } = customersState;
  const walletState = useSelector(selectWalletState);
  const createWalletLoading = walletState.createWallet.loading;
  const { permissions: profilePermissions } = useSelector(selectProfile);

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(customersMeta?.page || 1);

  const fetchCustomers = () => {
    !!profilePermissions?.includes("BROWSE_CUSTOMERS") &&
      dispatch(getCustomersFn({ page: currentPage }));
  };

  useEffect(fetchCustomers, [currentPage, profilePermissions]);

  const renderRows = () => {
    const rows =
      customerIsLoading && searchValue ? (
        <tr style={{ cursor: "pointer" }}>
          <td colSpan={5}>
            <Loading />
          </td>
        </tr>
      ) : (
        customersData.map((each: any) => {
          return (
            <tr
              style={{ cursor: "pointer" }}
              key={each?.id}
              onClick={() => getCustomer(each?.id)}
            >
              <td>
                {each?.lastName} {each?.firstName}
              </td>
              <td>{each?.dateOfBirth}</td>
              <td>{each?.phoneNumber}</td>
              <td>{each?.email}</td>
              <td>{format(parseISO(each?.createdAt), "dd MMMM, yyyy")}</td>
            </tr>
          );
        })
      );
    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const createWallet = (values: CreateWalletValuesTypes) => {
    dispatch(
      createWalletFn(values, () => {
        fetchCustomers();
        closeModal();
      })
    );
  };

  const renderSideContent = () => {
    if (sideContent === "customerDetail") {
      return (
        <CustomerDetail
          closeModalFn={closeModal}
          customer={customer}
          permissions={profilePermissions}
        />
      );
    }
    if (sideContent === "createWallet") {
      return (
        <CreateWalletSection
          loading={createWalletLoading}
          error={walletState.createWallet.error}
          createWalletFn={createWallet}
        />
      );
    } else {
      return null;
    }
  };

  const getCustomer = (id: string) => {
    dispatch(getSingleCustomerFn(id));
    openModal("customerDetail");
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <DashboardLayout>
        {customerIsLoading && !searchValue ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>
                Customers
                {!!profilePermissions?.includes("CREATE_CUSTOMER_WALLET") && (
                  <>
                    <img
                      alt="Customer"
                      className="ml-2"
                      src={AddWalletImg}
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal("createWallet")}
                      data-tip="Create Customer"
                      data-for="createCustomer"
                    />
                    <ReactTooltip id="createCustomer" place="right" />
                  </>
                )}
              </Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of customers
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {customersMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(getCustomersFn({ phoneNumber: searchValue }));
                }}
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <div className="col-auto my-3" style={{ flex: 1 }}>
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
                <OutlineButton
                  type="submit"
                  title="Search"
                  size="sm"
                  style={{ padding: "6px 20px" }}
                />
              </form>
              <Table
                noData={customersData.length === 0 ? true : false}
                headers={[
                  "name",
                  "date of birth",
                  "phone number",
                  "email",
                  "date",
                ]}
              >
                {!!profilePermissions?.includes("BROWSE_CUSTOMERS") &&
                  renderRows()}
              </Table>

              {customersMeta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={customersMeta?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default CustomersScreen;
