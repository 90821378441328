import * as React from "react";
import styles from "./AmountModal.module.css";
import { PrimaryButton } from "components/Button/Button";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CloseModalImg from "assets/images/closeModal.svg";
import TextInput from "components/TextInput/TextInput";
import { handleInputValidation } from "utils/helpers";

export interface AmountModalProps {
  modalIsOpen: boolean;
  closeModalFn: () => void;
  submitForm: (values: { amount: string }) => void;
  status?: string;
}

const AmountModal: React.SFC<AmountModalProps> = ({
  submitForm,
  closeModalFn,
  status,
}) => {
  return (
    <div className={styles.amountModal}>
      <img
        onClick={closeModalFn}
        className={styles.amountModal__closeBtn}
        src={CloseModalImg}
        alt="close"
      />
      <Formik
        initialValues={{ amount: "" }}
        onSubmit={(values, actions) => {
          submitForm(values);
        }}
        validationSchema={AmountSchema}
      >
        {() => (
          <Form>
            <Field
              id="amount"
              label="Amount"
              name="amount"
              placeholder=""
              type="number"
              component={TextInput}
            />
            <PrimaryButton type="submit" title={status} block />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AmountModal;

const AmountSchema = Yup.object().shape({
  amount: Yup.string()
    .required("Required")
    .test(
      "onlyNumber",
      "Amount must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    )
    .test(
      "noExtraSpace",
      "No extra space needed",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
});
