import React, { useEffect, useState } from "react";
import styles from "./BatchCustomerToCustomersTransfer.module.css";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  selectWalletState,
  batchCustomerToCustomersTransferFn,
} from "slices/walletSlice";
import {
  selectAllCustomersState,
  getAllCustomerFn,
} from "slices/customersSlice";
import TextInput from "components/TextInput/TextInput";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";
import { handleInputValidation } from "utils/helpers";

const BatchCustomerToCustomersTransfer: React.FC = () => {
  const dispatch = useDispatch();
  const { batchCustomerToCustomersTransfer } = useSelector(selectWalletState);
  const customerState = useSelector(selectAllCustomersState);
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    dispatch(getAllCustomerFn());
  }, [dispatch]);

  const customerMap = customerState?.data?.reduce((accum, record) => {
    accum[record?.id] = record;

    return accum;
  }, {});

  return (
    <SendMoney>
      {customerState.loading ? (
        <div style={{ margin: "0 auto" }}>
          <Loading />
        </div>
      ) : (
        <Formik
          initialValues={{
            customer: "",
            recipient: "",
            amount: "",
          }}
          validationSchema={Yup.object().shape(
            collection?.length
              ? {
                  customer: Yup.string(),
                  recipient: Yup.string(),
                  amount: Yup.string(),
                }
              : {
                  customer: Yup.string().required("Required"),
                  recipient: Yup.string().required("Required"),
                  amount: Yup.string()
                    .required("Required")
                    .test(
                      "onlyNumber",
                      "Amount must contain numbers only",
                      (val) => handleInputValidation(val)?.onlyNumber
                    )
                    .test(
                      "noExtraSpace",
                      "No extra space needed",
                      (val) => handleInputValidation(val)?.noExtraSpace
                    ),
                }
          )}
          onSubmit={(values, actions) => {
            dispatch(
              batchCustomerToCustomersTransferFn(
                {
                  customerId: values.customer,
                  recipients: [
                    ...collection.map((data) => {
                      return {
                        customerId: data?.recipient,
                        amount: data?.amount,
                      };
                    }),
                    { customerId: values.recipient, amount: values.amount },
                  ],
                },
                () => {
                  actions.resetForm();
                  setCollection([]);
                }
              )
            );
          }}
        >
          {({
            errors,
            touched,
            setValues,
            values,
            setFieldError,
            setFieldTouched,
          }) => (
            <div className={styles.viaWallet}>
              <h4 className={styles.viaWallet__title}>
                Batch Customer To Customers Transfer
              </h4>
              <Form>
                {!!collection.length && (
                  <div className={styles.collection__container}>
                    <div className={styles.collection__header}>
                      <p className={styles.header__name}>Name</p>
                      <p className={styles.header__amount}>Amount</p>
                      <p className={styles.header__action}>Action</p>
                    </div>
                    {collection?.map((data, index) => (
                      <div className={styles.collection__content} key={index}>
                        <p className={styles.content__name}>
                          {customerMap[data?.recipient]?.firstName +
                            " " +
                            customerMap[data?.recipient]?.lastName}
                        </p>
                        <p className={styles.content__amount}>{data?.amount}</p>
                        <p className={styles.content__action}>
                          <button
                            type="button"
                            className={styles.edit}
                            onClick={() => {
                              setCollection(
                                collection?.filter(
                                  (flitered) =>
                                    flitered?.recipient !== data?.recipient
                                )
                              );
                              setValues({
                                customer: data?.customer,
                                recipient: data?.recipient,
                                amount: data?.amount,
                              });
                            }}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className={styles.delete}
                            onClick={() => {
                              setCollection(
                                collection?.filter(
                                  (filtered) =>
                                    filtered?.recipient !== data?.recipient
                                )
                              );
                            }}
                          >
                            Delete
                          </button>
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                {/* <div>Baba oo</div> */}
                <div className="form-group" style={{ marginBottom: "2rem" }}>
                  <Field
                    // onBlur={() => {
                    //   fetchUser(props.values.phoneNumber);
                    // }}
                    name="customer"
                    type="string"
                    component="select"
                    className={`form-control form-control-lg ${
                      errors.customer && touched.customer ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">-- Select Customer --</option>
                    {customerState?.data?.map((customer) => (
                      <option value={customer?.id} key={customer?.id}>
                        {customer?.firstName + " " + customer?.lastName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="customer"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <Field
                    // onBlur={() => {
                    //   fetchUser(props.values.phoneNumber);
                    // }}
                    name="recipient"
                    type="string"
                    component="select"
                    className={`form-control form-control-lg ${
                      errors.recipient && touched.recipient ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">-- Select Recipient --</option>
                    {customerState?.data
                      ?.filter((data) => data?.id !== values.customer)
                      ?.map((customer) => (
                        <option value={customer?.id} key={customer?.id}>
                          {customer?.firstName + " " + customer?.lastName}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="recipient"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <Field
                  name="amount"
                  placeholder="Amount"
                  type="text"
                  component={TextInput}
                />
                {batchCustomerToCustomersTransfer.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <OutlineButton
                      title="Add More"
                      type="button"
                      size="sm"
                      onClick={() => {
                        if (!values.customer) {
                          setFieldError("customer", "Customer is required");
                          setFieldTouched("customer");
                          return;
                        } else if (!values?.recipient) {
                          setFieldError("recipient", "Recipient is required");
                          setFieldTouched("recipient");
                          return;
                        } else if (!values.amount) {
                          setFieldError("amount", "Amount is required");
                          setFieldTouched("amount");
                          return;
                        }

                        const selectedRecipient = collection?.find(
                          (each) => each?.recipient === values?.recipient
                        );

                        if (selectedRecipient) {
                          toast.error("Recipient already selected");
                          return;
                        }

                        setCollection([...collection, values]);
                        setValues({
                          customer: values.customer,
                          recipient: "",
                          amount: "",
                        });
                        setFieldTouched("recipient", false);
                        setFieldTouched("amount", false);
                      }}
                    />
                    <PrimaryButton
                      type="submit"
                      title="Send Money"
                      size="lg"
                      block
                      style={{ marginTop: "2rem" }}
                    />
                  </>
                )}
              </Form>
            </div>
          )}
        </Formik>
      )}
    </SendMoney>
  );
};

export default BatchCustomerToCustomersTransfer;
