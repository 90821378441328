import * as React from "react";
import {useLocation} from 'react-router-dom';
import { useSelector } from "react-redux";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./SendMoney.module.css";
import Header from "components/Header/Header";
import {NavSection} from "components/CardNav/CardNav";
import SectionSelector from "./components/SectionSelector/SectionSelector";
import routes from "routing/routes";
import {selectProfile} from 'slices/merchantSlice';

const SendMoney: React.FC = ({ children }) => {
  const bank = useLocation().pathname.includes('via-bank');
  const {permissions: profilePermissions, loading} = useSelector(selectProfile);

  return (
    <DashboardLayout>
      <Header>Send Money</Header>
      <div className={`mt-4 ${styles.content__container}`}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {!!(!loading && profilePermissions?.includes('WALLET_CREDIT_DEBIT')) && <NavSection link={{
            label: "Via Wallet Account",
            route: routes.SEND_MONEY,
            }} status={bank ? false : true} />}
          {!!(!loading && profilePermissions?.includes('MANAGE_TRANSFER')) && <NavSection link={{
            label: "Via Bank Account",
            route: routes.VIA_BANK,
            }} status={bank ? true : false} />}
        </div>
        <div className={styles.content}>
          <SectionSelector />
          {children}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SendMoney;
