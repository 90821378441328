import { createSlice, Dispatch } from "@reduxjs/toolkit";
// import config from "constants/api";
import { toast } from "react-toastify";
import request from "constants/requests";

interface createWalletValues {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  dateOfBirth?: string;
  bvn?: string;
  address?: string;
  tier?: string;
}

interface editWalletValues {
  firstName: string;
  lastName: string;
  email?: string;
  address?: string;
  tier?: string;
  customerId: string;
}

// interface initialStateTypes {
//   wallets: { loading: boolean; data: Array<object>; error: object };
//   createWallet: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
//   balance: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
//   fundWallet: {
//     loading: boolean;
//     data: object;
//     error: object;
//   };
// }

const initialState = {
  wallets: { loading: false, data: [], error: {}, meta: {} },
  createWallet: {
    loading: false,
    data: {},
    error: {},
  },
  editWallet: {
    loading: false,
    data: {},
    error: {},
  },
  balance: {
    loading: false,
    data: {},
    error: {},
  },
  fundWallet: {
    loading: false,
    data: {},
    error: {},
  },
  creditWallet: {
    loading: false,
    data: null,
    error: null,
  },
  debitWallet: {
    loading: false,
    data: null,
    error: null,
  },
  batchCreditWallet: {
    loading: false,
    data: null,
    error: null,
  },
  batchDebitWallet: {
    loading: false,
    data: null,
    error: null,
  },
  customerToCustomerTransfer: {
    loading: false,
    data: null,
    error: null,
  },
  batchCustomerToCustomersTransfer: {
    loading: false,
    data: null,
    error: null,
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {
    getWallets: (state) => {
      let { wallets } = state;
      wallets.loading = true;
      wallets.error = initialState.wallets.error;
      // wallets.data = initialState.wallets.data;
    },
    getWalletsSuccess: (state, { payload }) => {
      let { wallets } = state;
      wallets.loading = false;
      wallets.data = payload.data;
      wallets.meta = payload.meta;
      wallets.error = initialState.wallets.error;
    },
    getWalletsFailed: (state, { payload }) => {
      let { wallets } = state;
      wallets.loading = false;
      wallets.data = initialState.wallets.data;
      wallets.error = payload;
      wallets.meta = {};
    },
    createWallet: (state) => {
      let { createWallet } = state;
      createWallet.loading = true;
      createWallet.error = initialState.createWallet.error;
      createWallet.data = initialState.createWallet.data;
    },
    createWalletSuccess: (state, { payload }) => {
      let { createWallet } = state;
      createWallet.loading = initialState.createWallet.loading;
      createWallet.data = payload;
      createWallet.error = initialState.createWallet.error;
    },
    createWalletFailed: (state, { payload }) => {
      let { createWallet } = state;
      createWallet.loading = initialState.createWallet.loading;
      createWallet.data = initialState.createWallet.data;
      createWallet.error = payload;
    },
    editWallet: (state) => {
      let { editWallet } = state;
      editWallet.loading = true;
      editWallet.error = initialState.editWallet.error;
      editWallet.data = initialState.editWallet.data;
    },
    editWalletSuccess: (state, { payload }) => {
      let { editWallet } = state;
      editWallet.loading = initialState.editWallet.loading;
      editWallet.data = payload;
      editWallet.error = initialState.editWallet.error;
    },
    editWalletFailed: (state, { payload }) => {
      let { editWallet } = state;
      editWallet.loading = initialState.editWallet.loading;
      editWallet.data = initialState.editWallet.data;
      editWallet.error = payload;
    },
    getBalance: (state) => {
      let { balance } = state;
      balance.loading = true;
      balance.error = initialState.balance.error;
      // balance.data = initialState.balance.data;
    },
    getBalanceSuccess: (state, { payload }) => {
      let { balance } = state;
      balance.loading = false;
      balance.data = payload;
      balance.error = initialState.balance.error;
    },
    getBalanceFailed: (state, { payload }) => {
      let { balance } = state;
      balance.loading = false;
      balance.data = initialState.balance.data;
      balance.error = payload;
    },
    fundWallet: (state) => {
      let { fundWallet } = state;
      fundWallet.loading = true;
      fundWallet.error = initialState.fundWallet.error;
      fundWallet.data = initialState.fundWallet.data;
    },
    fundWalletSuccess: (state, { payload }) => {
      let { fundWallet } = state;
      fundWallet.loading = initialState.fundWallet.loading;
      fundWallet.data = payload;
      fundWallet.error = initialState.fundWallet.error;
    },
    fundWalletFailed: (state, { payload }) => {
      let { fundWallet } = state;
      fundWallet.loading = initialState.fundWallet.loading;
      fundWallet.data = initialState.fundWallet.data;
      fundWallet.error = payload;
    },
    resetCreditWallet: (state) => {
      const { creditWallet } = state;

      creditWallet.loading = true;
      creditWallet.data = null;
      creditWallet.error = null;
    },
    creditWalletSuccess: (state, { payload }) => {
      const { creditWallet } = state;

      creditWallet.loading = false;
      creditWallet.data = payload;
      creditWallet.error = null;
    },
    creditWalletFailure: (state, { payload }) => {
      const { creditWallet } = state;

      creditWallet.loading = false;
      creditWallet.data = null;
      creditWallet.error = payload;
    },
    resetDebitWallet: (state) => {
      const { debitWallet } = state;

      debitWallet.loading = true;
      debitWallet.data = null;
      debitWallet.error = null;
    },
    debitWalletSuccess: (state, { payload }) => {
      const { debitWallet } = state;

      debitWallet.loading = false;
      debitWallet.data = payload;
      debitWallet.error = null;
    },
    debitWalletFailure: (state, { payload }) => {
      const { debitWallet } = state;

      debitWallet.loading = false;
      debitWallet.data = null;
      debitWallet.error = payload;
    },
    resetBatchCreditWallet: (state) => {
      const { creditWallet } = state;

      creditWallet.loading = true;
      creditWallet.data = null;
      creditWallet.error = null;
    },
    batchCreditWalletSuccess: (state, { payload }) => {
      const { creditWallet } = state;

      creditWallet.loading = false;
      creditWallet.data = payload;
      creditWallet.error = null;
    },
    batchCreditWalletFailure: (state, { payload }) => {
      const { creditWallet } = state;

      creditWallet.loading = false;
      creditWallet.data = null;
      creditWallet.error = payload;
    },
    resetBatchDebitWallet: (state) => {
      const { debitWallet } = state;

      debitWallet.loading = true;
      debitWallet.data = null;
      debitWallet.error = null;
    },
    batchDebitWalletSuccess: (state, { payload }) => {
      const { debitWallet } = state;

      debitWallet.loading = false;
      debitWallet.data = payload;
      debitWallet.error = null;
    },
    batchDebitWalletFailure: (state, { payload }) => {
      const { debitWallet } = state;

      debitWallet.loading = false;
      debitWallet.data = null;
      debitWallet.error = payload;
    },
    resetCustomerToCustomerTransfer: (state) => {
      const { customerToCustomerTransfer } = state;

      customerToCustomerTransfer.loading = true;
      customerToCustomerTransfer.data = null;
      customerToCustomerTransfer.error = null;
    },
    customerToCustomerTransferSuccess: (state, { payload }) => {
      const { customerToCustomerTransfer } = state;

      customerToCustomerTransfer.loading = false;
      customerToCustomerTransfer.data = payload;
      customerToCustomerTransfer.error = null;
    },
    customerToCustomerTransferFailure: (state, { payload }) => {
      const { customerToCustomerTransfer } = state;

      customerToCustomerTransfer.loading = false;
      customerToCustomerTransfer.data = null;
      customerToCustomerTransfer.error = payload;
    },
    resetBatchCustomerToCustomersTransfer: (state) => {
      const { batchCustomerToCustomersTransfer } = state;

      batchCustomerToCustomersTransfer.loading = true;
      batchCustomerToCustomersTransfer.data = null;
      batchCustomerToCustomersTransfer.error = null;
    },
    batchCustomerToCustomersTransferSuccess: (state, { payload }) => {
      const { batchCustomerToCustomersTransfer } = state;

      batchCustomerToCustomersTransfer.loading = false;
      batchCustomerToCustomersTransfer.data = payload;
      batchCustomerToCustomersTransfer.error = null;
    },
    batchCustomerToCustomersTransferFailure: (state, { payload }) => {
      const { batchCustomerToCustomersTransfer } = state;

      batchCustomerToCustomersTransfer.loading = false;
      batchCustomerToCustomersTransfer.data = null;
      batchCustomerToCustomersTransfer.error = payload;
    },
  },
});

export const {
  getWallets,
  getWalletsSuccess,
  getWalletsFailed,
  createWallet,
  createWalletSuccess,
  createWalletFailed,
  editWallet,
  editWalletSuccess,
  editWalletFailed,
  getBalance,
  getBalanceSuccess,
  getBalanceFailed,
  fundWallet,
  fundWalletSuccess,
  fundWalletFailed,
  resetCreditWallet,
  creditWalletSuccess,
  creditWalletFailure,
  resetDebitWallet,
  debitWalletSuccess,
  debitWalletFailure,
  resetBatchCreditWallet,
  batchCreditWalletSuccess,
  batchCreditWalletFailure,
  resetBatchDebitWallet,
  batchDebitWalletSuccess,
  batchDebitWalletFailure,
  resetCustomerToCustomerTransfer,
  customerToCustomerTransferSuccess,
  customerToCustomerTransferFailure,
  resetBatchCustomerToCustomersTransfer,
  batchCustomerToCustomersTransferSuccess,
  batchCustomerToCustomersTransferFailure,
} = walletSlice.actions;

export const getWalletsFn =
  ({ page }: { page?: number }) =>
  async (dispatch: Dispatch) => {
    try {
      if (page === 1) {
        dispatch(getWallets());
      }

      const response = await request({
        method: "get",
        url: "/wallet",
        params: { page },
      });
      dispatch(
        getWalletsSuccess({
          data: response?.data?.wallets,
          meta: response?.data?.metadata,
        })
      );
    } catch (error) {
      dispatch(getWalletsFailed(error?.response?.data || error?.response));
    }
  };

export const getWalletBalanceFn = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getBalance());
    const response = await request({ method: "get", url: "/merchant/wallet" });
    dispatch(getBalanceSuccess(response?.data?.data));
  } catch (error) {
    dispatch(getBalanceFailed(error?.response?.data || error?.response));
  }
};

export const createWalletFn =
  (values: createWalletValues, cb?: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(createWallet());

      const { data } = await request({
        method: "post",
        url: "wallet",
        data: values,
      });

      dispatch(createWalletSuccess(data));

      toast.success(data?.message || "Successfully created wallet");

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(createWalletFailed(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const editWalletFn =
  (values: editWalletValues, cb?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(editWallet());

      const { data } = await request({
        method: "put",
        url: `customer/${values?.customerId}`,
        data: values,
      });

      dispatch(editWalletSuccess(data));

      toast.success(data?.message || "Successfully edited customer");

      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(editWalletFailed(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const fundWalletFn = (cb: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(fundWallet());
    const response = await request({
      url: "/merchant/fund-wallet",
      method: "post",
      data: { amount: 10000 },
    });
    dispatch(fundWalletSuccess(response?.data));
    toast.success("Wallet funded with ₦10,000");
    console.log(response?.data);
    cb();
  } catch (error) {
    dispatch(fundWalletFailed(error?.response?.data || error?.response));
    toast.error(error?.response?.data || error?.response?.message);
  }
};

export const creditWalletFn =
  (customerId: string, amount: string, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetCreditWallet());
      const { data } = await request({
        url: "/wallet/credit",
        method: "post",
        data: { customerId, amount },
      });
      dispatch(
        creditWalletSuccess({
          message: data?.message,
          reference: data?.reference,
          amount: data?.amount,
        })
      );
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(creditWalletFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const debitWalletFn =
  (customerId: string, amount: string, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetDebitWallet());
      const { data } = await request({
        url: "/wallet/debit",
        method: "post",
        data: { customerId, amount },
      });
      dispatch(debitWalletSuccess(data?.data));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(debitWalletFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const batchCreditWalletFn =
  (transactions, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetBatchCreditWallet());
      const { data } = await request({
        url: "/wallet/batch-credit-customer-wallet",
        method: "post",
        data: transactions,
      });
      dispatch(batchCreditWalletSuccess(data?.data));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(
        batchCreditWalletFailure(error?.response?.data || error?.response)
      );
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const batchDebitWalletFn =
  (transactions, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetBatchDebitWallet());
      const { data } = await request({
        url: "/wallet/batch-debit-customer-wallet",
        method: "post",
        data: transactions,
      });
      dispatch(batchDebitWalletSuccess(data?.data));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(
        batchDebitWalletFailure(error?.response?.data || error?.response)
      );
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const customerToCustomerTransferFn =
  (payload, cb: () => void) => async (dispatch) => {
    try {
      dispatch(resetCustomerToCustomerTransfer());
      const { data } = await request({
        url: "/transfer/wallet",
        method: "post",
        data: payload,
      });
      dispatch(customerToCustomerTransferSuccess(data?.data));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(
        customerToCustomerTransferFailure(
          error?.response?.data || error?.response
        )
      );
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const batchCustomerToCustomersTransferFn =
  (payload, cb: () => void) => async (dispatch) => {
    try {
      dispatch(resetBatchCustomerToCustomersTransfer());
      const { data } = await request({
        url: "/wallet/customer-batch-credit-customer-wallet",
        method: "post",
        data: payload,
      });
      dispatch(batchCustomerToCustomersTransferSuccess(data?.data));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(
        batchCustomerToCustomersTransferFailure(
          error?.response?.data || error?.response
        )
      );
      toast.error(error?.response?.data || error?.response?.message);
    }
  };

export const selectWalletState = (state: any) => state.wallet;

export default walletSlice.reducer;
