import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useParams, useHistory} from 'react-router-dom';

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import ProvidusAvatar from "assets/images/providus-avatar.png";
import {selectPartnerDetailsState, selectDeletePartnerState, selectUnassignMerchantState, getPartnerDetailsFn, deletePartnerFn, unassignMerchantFn} from "slices/bankSlice";
import SideContent from "layouts/Partner/components/SideContent/SideContent";
import ModalBackground from "layouts/Partner/components/ModalBackground/ModalBackground";
import AssignMerchantComponent from '../AssignMerchant/AssignMerchant';

import styles from "./PartnerDetail.module.css";

const PartnerDetails = () => {
    const {partner} = useParams() as Record<string, string | undefined>;
    const history = useHistory();
    const {loading, data} = useSelector(selectPartnerDetailsState);
	const deletePartnerState = useSelector(selectDeletePartnerState);
	const unassignMerchantState = useSelector(selectUnassignMerchantState);
    const [sideContent, setSideContent] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedMerchant, setSelectedMerchant] = useState('');

    const dispatch = useDispatch();

	useEffect(() => {
		// eslint-disable-next-line
        dispatch(getPartnerDetailsFn(partner));
    }, [partner, dispatch]);

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const renderSideContent = () => {
        switch (sideContent) {
            case 'assignMerchant':
                return <AssignMerchantComponent closeModal={closeModal} partner={partner} />;
            default:
                return null;
        }
    }

	const handleDeletePartner = () => {
		const confirmDelete = window.confirm('Are you sure you want to delete this partner? This action is irreversible.');
		if (confirmDelete) {
			dispatch(deletePartnerFn(partner, () => {
				history.push('/bank/partners');
			}))
		}
	}

	const handleUnassignMerchant = (merchant) => {
		setSelectedMerchant(merchant);
		const confirmUnassign = window.confirm('Are you sure you want to unassign this merchant? This action is irreversible.');
		if (confirmUnassign) {
			dispatch(unassignMerchantFn(partner, merchant, () => dispatch(getPartnerDetailsFn(partner))));
		}
	}

	return (
        <>
			<ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
			<SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
				{renderSideContent()}
			</SideContent>
			<BankLayout>
				{loading ? <Loading /> : <div>
					<div className="d-flex flex-row align-items-center justify-content-between mb-3">
						<div className='d-flex flex-row align-items-center'>
							<img src={ProvidusAvatar} alt='Providus Avatar' className="rounded-circle" />
							<div className="ml-2">
								<p className={styles.customerDetail__fullName}>{data?.firstName + ' ' + data?.lastName}</p>
								<p className={styles.customerDetail__email}>{data?.email}</p>
							</div>
						</div>
						<div>
							<PrimaryButton title='Assign Merchant' onClick={() => openModal('assignMerchant')} />
							<OutlineButton title={deletePartnerState.loading ? 'Deleting...' : 'Delete Partner'} className={styles.deleteBtn} style={{marginLeft: '1rem'}} onClick={handleDeletePartner} />
						</div>
						
					</div>

					<div className='row mt-4'>
						<div className='col-6'>
							<div className={styles.table__container}>
								<h3>Basic Information</h3>
								<table className="table table-striped table-borderless mt-4">
									<tbody>
										<tr>
											<td>First Name</td>
											<td align="right">{data?.firstName}</td>
										</tr>
										<tr>
											<td>Last Name</td>
											<td align="right">{data?.lastName}</td>
										</tr>
										<tr>
											<td>Email</td>
											<td align="right">{data?.email}</td>
										</tr>
									</tbody>
								</table>
								
							</div>
						</div>
						<div className='col-6'>
							<div className={styles.table__container}>
								<h3>Merchants</h3>
								<table className="table table-striped table-borderless mt-4">
									<tbody>
										{data?.merchants?.length ? data?.merchants?.map((merchant) => (
											<tr key={merchant?.id}>
												<td>{merchant?.businessName}</td>
												<td align="right"><OutlineButton title='View' className={styles.viewMerchant} onClick={() => history.push(`/bank/merchants/${merchant?.id}`)} /> <OutlineButton title={unassignMerchantState.loading && merchant?.id === selectedMerchant ? 'Deleting...' : 'Delete'} className={styles.deleteMerchant} onClick={() => handleUnassignMerchant(merchant?.id)} /></td>
											</tr>
										)) : (
											<tr>
												<td>No Merchant Available</td>
											</tr>
										)}
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
				</div>}
				</BankLayout>
            </>
		);
};

export default PartnerDetails;
