import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import routes from "routing/routes";
import FileImg from "assets/images/files.png";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import BankDetails from "components/BankDetails/BankDetails";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import ProvidusAvatar from "assets/images/providus-avatar.png";
import {
  selectSingleMerchantState,
  selectBankAccessModeState,
  getSingleMerchantFn,
  activateMerchantFn,
  deactivateMerchantFn,
} from "slices/bankSlice";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import DeclineMerchant from "../DeclineMerchant/DeclineMerchant";

import styles from "./MerchantDetails.module.css";

import { numberWithCommas } from "utils/helpers";

const MerchantDetails = () => {
  const { merchant } = useParams() as Record<string, string | undefined>;
  const { loading, data } = useSelector(selectSingleMerchantState);
  const modeState = useSelector(selectBankAccessModeState);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const fetchMerchant = () => {
    dispatch(getSingleMerchantFn({ merchantId: merchant, mode: modeState }));
  };

  useEffect(fetchMerchant, [modeState]);

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderActionableButton = (status) => {
    switch (status) {
      case "PENDING":
        return null;
      case "IN-REVIEW":
        return (
          <>
            <PrimaryButton
              title="Activate"
              onClick={() =>
                dispatch(
                  activateMerchantFn(
                    {
                      merchantId: merchant,
                    },
                    () =>
                      dispatch(
                        getSingleMerchantFn({
                          merchantId: merchant,
                          mode: modeState,
                        })
                      )
                  )
                )
              }
            />
            <PrimaryButton
              title="Decline"
              onClick={openModal}
              style={{
                backgroundColor: "red",
                marginLeft: "1rem",
                display: "inline-block",
                border: "1px solid red",
              }}
            />
          </>
        );
      case "DISABLED":
        return (
          <PrimaryButton
            title="Reactivate"
            onClick={() =>
              dispatch(
                activateMerchantFn(
                  {
                    merchantId: merchant,
                  },
                  () =>
                    dispatch(
                      getSingleMerchantFn({
                        merchantId: merchant,
                        mode: modeState,
                      })
                    )
                )
              )
            }
          />
        );
      case "ENABLED":
        return (
          <PrimaryButton
            title="Deactivate"
            onClick={() =>
              dispatch(
                deactivateMerchantFn({ merchantId: merchant }, () =>
                  dispatch(
                    getSingleMerchantFn({
                      merchantId: merchant,
                      mode: modeState,
                    })
                  )
                )
              )
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        <DeclineMerchant
          merchant={merchant}
          closeModal={closeModal}
          fetchMerchant={fetchMerchant}
        />
      </SideContent>
      <BankLayout>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between mb-3">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={ProvidusAvatar}
                  alt="Providus Avatar"
                  className="rounded-circle"
                />
                <div className="ml-2">
                  <p className={styles.customerDetail__fullName}>
                    {data?.businessName}
                  </p>
                  <p className={styles.customerDetail__email}>{data?.email}</p>
                </div>
              </div>
              <div>
                {renderActionableButton(data?.review)}
                <OutlineButton
                  title="Update Details"
                  link={`${routes.BANK_MERCHANT}/${merchant}/update`}
                  style={{ marginLeft: "1rem" }}
                />
              </div>
            </div>
            <div className="col-6 ml-0 pl-0">
              <BankDetails
                accountName={data?.businessName}
                accountNumber={data?.accountNumber}
                bankName="Providus"
              />
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <div className={styles.table__container}>
                  <h3>Basic Information</h3>
                  <table className="table table-striped table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td>Business Name</td>
                        <td align="right">{data?.businessName}</td>
                      </tr>
                      {!!data?.businessType && (
                        <tr>
                          <td>Business Type</td>
                          <td align="right">{data?.businessType}</td>
                        </tr>
                      )}
                      {!!data?.providusAccountNumber && (
                        <tr>
                          <td>Providus Account Number</td>
                          <td align="right">{data?.providusAccountNumber}</td>
                        </tr>
                      )}
                      {!!data?.bvn && (
                        <tr>
                          <td>BVN</td>
                          <td align="right">{data?.bvn || ""}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Email</td>
                        <td align="right" style={{ wordBreak: "break-word" }}>
                          {data?.email}
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td align="right">{statusFn(data?.review)}</td>
                      </tr>
                      <tr>
                        <td>Phone Number</td>
                        <td align="right">{data?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>Book Balance</td>
                        <td align="right">
                          &#8358;{numberWithCommas(data?.bookedBalance)}
                        </td>
                      </tr>
                      <tr>
                        <td>Available Balance</td>
                        <td align="right">
                          &#8358;{numberWithCommas(data?.availableBalance)}
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Funding Rate</td>
                        <td align="right">{data?.fundingRate}</td>
                      </tr> */}
                      {/* <tr>
                        <td>Funding Rate (Max)</td>
                        <td align="right">{data?.fundingRateMax}</td>
                      </tr> */}
                      {!!data?.parentMerchant && (
                        <tr>
                          <td>Parent Merchant</td>
                          <td align="right">
                            {data?.parentMerchant?.businessName}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>CAC Pack</td>
                        <td align="right">
                          {data?.cacPack
                            ? data?.cacPack.map((data) => {
                                const key = Date.now();
                                console.log(data);
                                return (
                                  <a
                                    key={key}
                                    href={data}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.link__container}
                                  >
                                    <img
                                      src={FileImg}
                                      alt=""
                                      className={styles.image__link}
                                    />
                                  </a>
                                );
                              })
                            : "No Document Submitted"}
                        </td>
                      </tr>
                      <tr>
                        <td>Callback URL</td>
                        <td align="right">
                          {data?.callbackURL ? data?.callbackURL : "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>Sandbox Callback URL</td>
                        <td align="right">
                          {data?.sandboxCallbackURL
                            ? data?.sandboxCallbackURL
                            : "Not Set"}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* <h5 className="mt-4">Card Setup</h5>
                  <table className="table table-striped table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td>App ID</td>
                        <td align="right">
                          {data?.card_setup?.appId || "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>App Key</td>
                        <td align="right">
                          {data?.card_setup?.appKey || "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>Prepaid Card Prefix</td>
                        <td align="right">
                          {data?.card_setup?.prepaidCardPrefix || "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>Loading Account Name</td>
                        <td align="right">
                          {data?.card_setup?.loadingAccountName || "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>Loading Account Number</td>
                        <td align="right">
                          {data?.card_setup?.loadingAccountNumber || "Not Set"}
                        </td>
                      </tr>
                      <tr>
                        <td>Loading Account Sort Code</td>
                        <td align="right">
                          {data?.card_setup?.loadingAccountSortcode ||
                            "Not Set"}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
                <div className={styles.table__container}>
                  <h3>Capabilities</h3>
                  <table className="table table-striped table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td>Can Login</td>
                        <td align="right">{data?.canLogin ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Can Send Email</td>
                        <td align="right">{data?.sendEmail ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <td>Can Debit Customer</td>
                        <td align="right">
                          {data?.canDebitCustomer ? "Yes" : "No"}
                        </td>
                      </tr>
                      <tr>
                        <td>Can Manage Customer Tier</td>
                        <td align="right">
                          {data?.canChooseTier ? "Yes" : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-6">
                <div className={styles.table__container}>
                  <h3>Limits</h3>
                  <table className="table table-striped table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td>Lien</td>
                        <td align="right">
                          &#8358;{numberWithCommas(data?.lien || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Daily Transaction Limit</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.dailyTransactionLimit || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 1 Daily Limit</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_1_daily_limit || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 2 Daily Limit</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_2_daily_limit || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 3 Daily Limit</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_3_daily_limit || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 1 Min Balance</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_1_min_balance || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 1 Max Balance</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_1_max_balance || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 2 Min Balance</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_2_min_balance || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 2 Max Balance</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_2_max_balance || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 3 Min Balance</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.tier_3_min_balance || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Tier 3 Max Balance</td>
                        {data?.tier_3_max_balance === 0 ? (
                          <td align="right">Unlimited</td>
                        ) : (
                          <td align="right">
                            &#8358;
                            {numberWithCommas(data?.tier_3_max_balance)}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={styles.table__container}>
                  <h3>Charges</h3>
                  <table className="table table-striped table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td>Wallet Reservation Charge</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.walletReservationCharge || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Wallet To Wallet Transfer</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(data?.walletToWalletTransfer || 0)}
                        </td>
                      </tr>
                      <tr>
                        <td>Transfer Charge (max5000)</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(
                            data?.transferCharges?.max5000 || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Transfer Charge (max50000)</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(
                            data?.transferCharges?.max50000 || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Transfer Charge (min50000)</td>
                        <td align="right">
                          &#8358;
                          {numberWithCommas(
                            data?.transferCharges?.min50000 || 0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default MerchantDetails;
