import React, { useState } from "react";
import style from "./PasswordInput.module.css";
import classNames from "classnames";

export interface PasswordInputProps {
  [x: string]: any;
  field: any;
  form: any;
  show: boolean;
  toggleShow: () => void;
  divClass?: any;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  show,
  toggleShow,
  divClass,
  ...props
}: {
  [x: string]: any;
  field: any;
  form: any;
  show: boolean;
  toggleShow: () => void;
  divClass?: any;
}) => {
  const [focus, setFocus] = useState("");

  return (
    <div style={divClass}>
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}

      <div
        className={classNames(
          style.content,
          focus ? style.focus : "",
          errors[field.name] ? style.error : ""
        )}
      >
        <input
          id={props.id}
          className={style.input}
          {...field}
          {...props}
          onFocus={() => setFocus(field?.name)}
          onBlur={() => setFocus("")}
        />
        <span onClick={toggleShow} className={style.show}>
          {show ? "Hide" : "Show"}
        </span>
      </div>

      {props.subtext ? (
        <small className="form-text text-muted">{props.subtext}</small>
      ) : null}
      {errors[field.name] && (
        <div className={style.text_error}>{errors[field.name]}</div>
      )}
    </div>
  );
};

export default PasswordInput;
