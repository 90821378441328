import * as React from "react";
import styles from "./Button.module.css";
import { Link } from "react-router-dom";

interface PrimaryButtonProps {
    title: string;
    link?: string;
    size?: "sm" | "lg";
    block?: boolean;
    className?: string;
    type?: "submit" | "button";
    disabled?: boolean;
    style?: any;
    onClick?: () => void;
    target?: string;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
    title,
    size,
    link,
    block,
    type,
    style,
    className,
    target,
    onClick,
    disabled = false,
}) => {
    let classes = `${styles.primaryBtn} btn ${className ? className : ""}`;

    if (size) {
        classes = `btn-${size} ${classes}`;
    }
    if (block) {
        classes = `btn-block ${classes}`;
    }

    if (link) {
        return (
            <Link className={classes} to={link} target={target ? target : "_self"} style={style}>
                {title}
            </Link>
        );
    }

    return (
        <button onClick={onClick} className={`${classes}`} style={style} type={type || "button"} disabled={disabled}>
            {title}
        </button>
    );
};

interface OutlineButtonProps {
    title: string;
    link?: string;
    size?: "sm" | "lg";
    block?: boolean;
    type?: "submit" | "button";
    style?: any;
    className?: string;
    onClick?: () => void;
    target?: string;
}

export const OutlineButton: React.FC<OutlineButtonProps> = ({ title, link, size, block, style, className, onClick, target, type }) => {
    let classes = `${styles.outlineBtn} btn btn-outline ${className ? className : ""}`;
    if (size) {
        classes = `btn-${size} ` + classes;
    }
    if (block) {
        classes = `btn-block ${classes}`;
    }
    if (link) {
        return (
            <Link className={classes} to={link} target={target ? target : "_self"} style={style}>
                {title}
            </Link>
        );
    }
    return (
        <button onClick={onClick} type={type || "button"} className={classes} style={style}>
            {title}
        </button>
    );
};
