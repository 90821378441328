export default {
  LOGIN: "/",
  KYC: "/kyc",
  HOME: "/home",
  REGISTER: "/register",
  SETTINGS: "/settings",
  CUSTOMERS: "/customers",
  SEND_MONEY: "/send-money",
  VERIFICATION: "/verification",
  TRANSACTIONS: "/transactions",
  PROFILE: "/settings/profile",
  SECURITY: "/settings/security",
  ACCOUNT_VERIFICATION: "/settings/account-verification",
  NOTIFICATIONS: "/settings/notifications",
  DEVELOPER_KEYS: "/settings/developer-keys",
  VIA_WALLET: "/send-money/via-wallet",
  VIA_BANK: "/send-money/via-bank",
  ACCOUNT_REVIEW: "/account-review",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  VIA_MOBILE_MONEY: "/send-money/via-mobile-money",
  ROLES: "/roles",
  TEAM: "/team",
  ACCEPT_INVITATION: "/accept-invitation",
  TRANSACTION_HISTORY: "/transactions/history",

  PARTNER_HOME: "/partner/home",
  PARTNERMERCHANT: "/partner/merchants",
  PARTNERTRANSACTION: "/partner/transactions",
  PARTNER_SUBSIDIARY_MERCHANT: "/partner/merchant/subsidiary",

  CARD_HOLDERS: "/card-holders",
  ACTIVATED_CARDS: "/activated-cards",

  BANK_HOME: "/bank/home",
  BANK_REPORT: "/bank/report",
  BANK_MERCHANT: "/bank/merchants",
  BANK_TRANSACTION: "/bank/transactions",
  BANK_PARTNER: "/bank/partners",
  BANK_CUSTOMER: "/bank/customers",
  BANK_NIP_SETTINGS: "/bank/nip-settings",

  SUBSIDIARIES: "/subsidiaries",

  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
};
