import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import PartnerList from "./components/PartnerList/PartnerList";
import PartnerHeaderSection from "./components/PartnerHeader/PartnerHeader";
import SideContent from "layouts/Partner/components/SideContent/SideContent";
import ModalBackground from "layouts/Partner/components/ModalBackground/ModalBackground";
import CreatePartner from './components/CreatePartner/CreatePartner';

import { selectBankPartnerState, fetchPartnerFn } from "slices/bankSlice";

const HomeScreen: React.FC = () => {
    const dispatch = useDispatch();
    const partnerState = useSelector(selectBankPartnerState);

    const [sideContent, setSideContent] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchPartnerFn());
    }, [dispatch]);

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const renderSideContent = () => {
        switch (sideContent) {
            case 'createPartner':
                return <CreatePartner closeModal={closeModal} />;
            default:
                return null;
        }
    }

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <BankLayout>
                {partnerState.loading ? (
                    <Loading />
                ) : (
                    <>
                        <PartnerHeaderSection totalRecords={partnerState.data.length} openModal={openModal} />
                        <PartnerList data={partnerState.data} />
                    </>
                )}
            </BankLayout>
        </>
    );
};

export default HomeScreen;
