import { toast } from "react-toastify";

export const numberWithCommas = (text: string, decimalPlaces = 2) => {
  const dotted = `${text}`?.includes(".") ? true : false;

  if (dotted) {
    return Intl.NumberFormat("NGN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(text));
  }

  return Intl.NumberFormat("NGN").format(Number(text));
};

// export const numberWithCommas = (n: string, decimalPlaces = 2) => {
//     return parseFloat(n || "0")
//         .toFixed(decimalPlaces)
//         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
// };

export const handlingErrors = (error, dispatch, callback) => {
  if (error.response) {
    dispatch(callback(error?.response?.data?.message || error.message));
  } else {
    toast.error(error.message);
    dispatch(callback(error.message));
  }
};

export const handleInputValidation = (val: string) => {
  return {
    onlyNumber:
      /^[0-9]*$/.test(val) || /^-?\d+(\.\d{1,2})?$/.test(val) ? true : false,
    onlyAlphabet: /^[a-zA-Z]*$/.test(val) ? true : false,
    alphabetWithSpace: /^[a-zA-Z][a-zA-Z ]*$/.test(val) ? true : false,
    noExtraSpace: /^\s+|\s+$/g.test(val) ? false : true,
  };
};

let today: Date | string = new Date();
let dd: number | string = today.getDate();
let mm: number | string = today.getMonth() + 1; //January is 0!
const yyyy = today.getFullYear();

if (dd < 10) {
  dd = "0" + dd;
}

if (mm < 10) {
  mm = "0" + mm;
}

today = yyyy + "-" + mm + "-" + dd;

export { today };
