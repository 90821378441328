declare global {
    interface Window {
        __RUNTIME_CONFIG__: {
            API_URL: string;
            NODE_ENV: string;
        };
    }
}

export default {
    API_URL: window.__RUNTIME_CONFIG__.API_URL,
    USER: "user",
    MERCHANT: "merchant",
    ACCOUNT_MODE: "accountMode",
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
};
