import React from "react";
import Header from "components/Header/Header";
// import DottedImg from "assets/images/dotted.svg";

import styles from "./Customer.module.css";

const Customer = ({ data }) => {
  return (
    <div className={styles.wallet}>
      <div className="mb-2">
        <Header>
          Merchant Customers{" "}
          {/*<img alt="Option" className={styles.wallet__headerIcon} src={DottedImg} />*/}
        </Header>
      </div>
      <div className={styles.wallet__content}>
        <div className={styles.transaction__balanceSheet}>
          <p className={styles.transaction__balanceSheetTitle}>
            Total Number of Customers
          </p>
          <h3 className={styles.transaction__balanceSheetAmount}>
            {data?.totalCustomers || 0}
          </h3>
        </div>
        <div className={styles.wallet__vl} />
        <div className={styles.transaction__section}>
          <p className={styles.wallet__amountTitle}>
            Customers of Active Merchants
          </p>
          <h3 className={styles.wallet__amount}>
            {data?.activeMerchantCustomers || 0}
          </h3>
        </div>
        <div className={styles.wallet__vl} />
        <div className={styles.transaction__section}>
          <p className={styles.wallet__amountTitle}>
            Customers of Frozen Merchants
          </p>
          <h3 className={styles.wallet__amount}>
            {data?.frozenMerchantCustomers || 0}
          </h3>
        </div>
        <div className={styles.wallet__vl} />
        <div className={styles.transaction__section}>
          <p className={styles.wallet__amountTitle}>
            Customers of Pending Merchants
          </p>
          <h3 className={styles.wallet__amount}>
            {data?.pendingMerchantCustomers || 0}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Customer;
