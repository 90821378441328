import { createSlice } from "@reduxjs/toolkit";
import config from "constants/api";
import { toast } from "react-toastify";

import request from "constants/requests";
import { format, subDays } from "date-fns";

const initialState = {
  accessMode:
    localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION"
      ? "PRODUCTION"
      : "SANDBOX",
  home: {
    error: null,
    loading: true,
    merchant: null,
    customer: null,
    transaction: null,
    merchantList: [],
  },
  merchant: {
    loading: false,
    info: null,
    data: [],
    meta: {},
    error: null,
  },
  singleMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  parentMerchants: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  transaction: {
    loading: true,
    info: null,
    data: [],
    meta: {},
    error: null,
  },
  reports: {
    loading: true,
    info: null,
    data: [],
    meta: {},
    error: null,
  },
  partner: {
    loading: true,
    data: [],
    error: null,
  },
  activateMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  deactivateMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantInfo: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantCharges: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantLimit: {
    loading: false,
    data: null,
    error: null,
  },
  createPartner: {
    loading: false,
    data: null,
    error: null,
  },
  partnerDetails: {
    loading: true,
    data: null,
    error: null,
  },
  assignMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  deletePartner: {
    loading: false,
    data: null,
    error: null,
  },
  unassignMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  customers: {
    loading: true,
    data: null,
    meta: null,
    error: null,
  },
  singleCustomer: {
    loading: true,
    data: null,
    error: null,
  },
  nipStatusCode: {
    loading: true,
    data: null,
    meta: null,
    error: null,
  },
  updateNipStatusCode: {
    loading: false,
    data: null,
    error: null,
  },
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setAccessMode: (state, { payload }) => {
      state.accessMode = payload;
    },
    resetHome: (state) => {
      state.home.loading = true;
      state.home.error = null;
      state.home.merchant = null;
      state.home.customer = null;
      state.home.merchantList = [];
      state.home.transaction = null;
    },
    setHomeSuccess: (state, { payload }) => {
      state.home.loading = false;
      state.home.merchant = payload.merchant;
      state.home.customer = payload.customer;
      state.home.merchantList = payload.merchantList;
      state.home.transaction = payload.transaction;
    },
    setHomeFailure: (state, { payload }) => {
      state.home.loading = false;
      state.home.error = payload;
      state.home.merchant = null;
      state.home.customer = null;
      state.home.merchantList = [];
      state.home.transaction = null;
    },
    resetMerchant: (state) => {
      state.merchant.loading = true;
      state.merchant.info = null;
      state.merchant.data = [];
      state.merchant.meta = {};
      state.merchant.error = null;
    },
    setMerchantSuccess: (state, { payload }) => {
      state.merchant.loading = false;
      state.merchant.info = payload.info;
      state.merchant.data = payload.data;
      state.merchant.meta = payload.meta;
      state.merchant.error = null;
    },
    setMerchantFailure: (state, { payload }) => {
      state.merchant.loading = false;
      state.merchant.info = null;
      state.merchant.data = [];
      state.merchant.meta = {};
      state.merchant.error = payload;
    },
    resetSingleMerchant: (state) => {
      const { singleMerchant } = state;

      singleMerchant.loading = true;
      singleMerchant.data = null;
      singleMerchant.error = null;
    },
    singleMerchantSuccess: (state, { payload }) => {
      const { singleMerchant } = state;

      singleMerchant.loading = false;
      singleMerchant.data = payload.data;
      singleMerchant.error = null;
    },
    singleMerchantFailed: (state, { payload }) => {
      const { singleMerchant } = state;

      singleMerchant.loading = false;
      singleMerchant.data = null;
      singleMerchant.error = payload;
    },
    resetParentMerchants: (state) => {
      state.parentMerchants.loading = true;
      state.parentMerchants.data = [];
      state.parentMerchants.meta = {};
      state.parentMerchants.error = null;
    },
    parentMerchantsSuccess: (state, { payload }) => {
      state.parentMerchants.loading = false;
      state.parentMerchants.data = payload.data;
      state.parentMerchants.meta = payload.meta;
      state.parentMerchants.error = null;
    },
    parentMerchantsFailure: (state, { payload }) => {
      state.parentMerchants.loading = false;
      state.parentMerchants.data = [];
      state.parentMerchants.meta = {};
      state.parentMerchants.error = payload;
    },
    resetTransaction: (state) => {
      state.transaction.loading = true;
      state.transaction.info = null;
      state.transaction.data = [];
      state.transaction.meta = {};
      state.transaction.error = null;
    },
    setTransactionSuccess: (state, { payload }) => {
      state.transaction.loading = false;
      state.transaction.info = payload.info;
      state.transaction.data = payload.data;
      state.transaction.meta = payload.meta;
      state.transaction.error = null;
    },
    setTransactionFailure: (state, { payload }) => {
      state.transaction.loading = false;
      state.transaction.info = null;
      state.transaction.data = [];
      state.transaction.meta = {};
      state.transaction.error = payload;
    },
    resetReport: (state) => {
      state.reports.loading = true;
      state.reports.info = null;
      state.reports.data = [];
      state.reports.meta = {};
      state.reports.error = null;
    },
    setReportSuccess: (state, { payload }) => {
      state.reports.loading = false;
      state.reports.info = payload.info;
      state.reports.data = payload.data;
      state.reports.meta = payload.meta;
      state.reports.error = null;
    },
    setReportFailure: (state, { payload }) => {
      state.reports.loading = false;
      state.reports.info = null;
      state.reports.data = [];
      state.reports.meta = {};
      state.reports.error = payload;
    },
    fetchPartnerStarted: (state) => {
      const { partner } = state;

      partner.loading = true;
      partner.data = [];
      partner.error = null;
    },
    fetchPartnerSuccess: (state, { payload }) => {
      const { partner } = state;

      partner.loading = false;
      partner.data = payload;
      partner.error = null;
    },
    fetchPartnerFailed: (state, { payload }) => {
      const { partner } = state;

      partner.loading = false;
      partner.data = null;
      partner.error = payload;
    },
    resetActivateMerchant: (state) => {
      const { activateMerchant } = state;

      activateMerchant.loading = true;
      activateMerchant.data = null;
      activateMerchant.error = null;
    },
    activateMerchantSuccess: (state, { payload }) => {
      const { activateMerchant } = state;

      activateMerchant.loading = false;
      activateMerchant.data = payload.data;
      activateMerchant.error = null;
    },
    activateMerchantFailed: (state, { payload }) => {
      const { activateMerchant } = state;

      activateMerchant.loading = false;
      activateMerchant.data = null;
      activateMerchant.error = payload;
    },
    resetDeactivateMerchant: (state) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = true;
      deactivateMerchant.data = null;
      deactivateMerchant.error = null;
    },
    deactivateMerchantSuccess: (state, { payload }) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = false;
      deactivateMerchant.data = payload.data;
      deactivateMerchant.error = null;
    },
    deactivateMerchantFailed: (state, { payload }) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = false;
      deactivateMerchant.data = null;
      deactivateMerchant.error = payload;
    },
    resetUpdateMerchantInfo: (state) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = true;
      updateMerchantInfo.data = null;
      updateMerchantInfo.error = null;
    },
    updateMerchantInfoSuccess: (state, { payload }) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = false;
      updateMerchantInfo.data = payload.data;
      updateMerchantInfo.error = null;
    },
    updateMerchantInfoFailed: (state, { payload }) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = false;
      updateMerchantInfo.data = null;
      updateMerchantInfo.error = payload;
    },
    resetUpdateMerchantCharges: (state) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = true;
      updateMerchantCharges.data = null;
      updateMerchantCharges.error = null;
    },
    updateMerchantChargesSuccess: (state, { payload }) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = false;
      updateMerchantCharges.data = payload.data;
      updateMerchantCharges.error = null;
    },
    updateMerchantChargesFailed: (state, { payload }) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = false;
      updateMerchantCharges.data = null;
      updateMerchantCharges.error = payload;
    },
    resetUpdateMerchantLimit: (state) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = true;
      updateMerchantLimit.data = null;
      updateMerchantLimit.error = null;
    },
    updateMerchantLimitSuccess: (state, { payload }) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = false;
      updateMerchantLimit.data = payload.data;
      updateMerchantLimit.error = null;
    },
    updateMerchantLimitFailed: (state, { payload }) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = false;
      updateMerchantLimit.data = null;
      updateMerchantLimit.error = payload;
    },
    resetCreatePartner: (state) => {
      const { createPartner } = state;

      createPartner.loading = true;
      createPartner.data = null;
      createPartner.error = null;
    },
    createPartnerSuccess: (state, { payload }) => {
      const { createPartner } = state;

      createPartner.loading = false;
      createPartner.data = payload;
      createPartner.error = null;
    },
    createPartnerFailure: (state, { payload }) => {
      const { createPartner } = state;

      createPartner.loading = false;
      createPartner.data = null;
      createPartner.error = payload;
    },
    resetPartnerDetails: (state) => {
      const { partnerDetails } = state;

      partnerDetails.loading = true;
      partnerDetails.data = null;
      partnerDetails.error = null;
    },
    partnerDetailsSuccess: (state, { payload }) => {
      const { partnerDetails } = state;

      partnerDetails.loading = false;
      partnerDetails.data = payload;
      partnerDetails.error = null;
    },
    partnerDetailsFailure: (state, { payload }) => {
      const { partnerDetails } = state;

      partnerDetails.loading = false;
      partnerDetails.data = null;
      partnerDetails.error = payload;
    },
    resetAssignMerchant: (state) => {
      const { assignMerchant } = state;

      assignMerchant.loading = true;
      assignMerchant.data = null;
      assignMerchant.error = null;
    },
    assignMerchantSuccess: (state, { payload }) => {
      const { assignMerchant } = state;

      assignMerchant.loading = false;
      assignMerchant.data = payload;
      assignMerchant.error = null;
    },
    assignMerchantFailure: (state, { payload }) => {
      const { assignMerchant } = state;

      assignMerchant.loading = false;
      assignMerchant.data = null;
      assignMerchant.error = payload;
    },
    resetDeletePartner: (state) => {
      const { deletePartner } = state;

      deletePartner.loading = true;
      deletePartner.data = null;
      deletePartner.error = null;
    },
    deletePartnerSuccess: (state, { payload }) => {
      const { deletePartner } = state;

      deletePartner.loading = false;
      deletePartner.data = payload;
      deletePartner.error = null;
    },
    deletePartnerFailed: (state, { payload }) => {
      const { deletePartner } = state;

      deletePartner.loading = false;
      deletePartner.data = null;
      deletePartner.error = payload;
    },
    resetUnassignMerchant: (state) => {
      const { unassignMerchant } = state;

      unassignMerchant.loading = true;
      unassignMerchant.data = null;
      unassignMerchant.error = null;
    },
    unassignMerchantSuccess: (state, { payload }) => {
      const { unassignMerchant } = state;

      unassignMerchant.loading = false;
      unassignMerchant.data = payload;
      unassignMerchant.error = null;
    },
    unassignMerchantFailed: (state, { payload }) => {
      const { unassignMerchant } = state;

      unassignMerchant.loading = false;
      unassignMerchant.data = null;
      unassignMerchant.error = payload;
    },
    resetCustomers: (state) => {
      const { customers } = state;

      customers.loading = true;
      customers.data = null;
      customers.meta = null;
      customers.error = null;
    },
    getCustomersSuccess: (state, { payload }) => {
      const { customers } = state;

      customers.loading = false;
      customers.data = payload.data;
      customers.meta = payload.meta;
      customers.error = null;
    },
    getCustomersFailure: (state, { payload }) => {
      const { customers } = state;

      customers.loading = false;
      customers.data = null;
      customers.meta = null;
      customers.error = payload;
    },
    resetSingleCustomer: (state) => {
      const { singleCustomer } = state;

      singleCustomer.loading = true;
      singleCustomer.data = null;
      singleCustomer.error = null;
    },
    getSingleCustomerSuccess: (state, { payload }) => {
      const { singleCustomer } = state;

      singleCustomer.loading = false;
      singleCustomer.data = payload;
      singleCustomer.error = null;
    },
    getSingleCustomerFailure: (state, { payload }) => {
      const { singleCustomer } = state;

      singleCustomer.loading = false;
      singleCustomer.data = null;
      singleCustomer.error = payload;
    },
    resetNipStatusCode: (state) => {
      const { nipStatusCode } = state;

      nipStatusCode.loading = true;
      nipStatusCode.data = null;
      nipStatusCode.meta = null;
      nipStatusCode.error = null;
    },
    nipStatusCodeSuccess: (state, { payload }) => {
      const { nipStatusCode } = state;

      nipStatusCode.loading = false;
      nipStatusCode.data = payload?.data;
      nipStatusCode.meta = payload?.meta;
      nipStatusCode.error = null;
    },
    nipStatusCodeFailure: (state, { payload }) => {
      const { nipStatusCode } = state;

      nipStatusCode.loading = false;
      nipStatusCode.data = null;
      nipStatusCode.meta = null;
      nipStatusCode.error = payload;
    },
    resetUpdateNipStatusCode: (state) => {
      const { updateNipStatusCode } = state;

      updateNipStatusCode.loading = true;
      updateNipStatusCode.data = null;
      updateNipStatusCode.error = null;
    },
    updateNipStatusCodeSuccess: (state, { payload }) => {
      const { updateNipStatusCode } = state;

      updateNipStatusCode.loading = false;
      updateNipStatusCode.data = payload;
      updateNipStatusCode.error = null;
    },
    updateNipStatusCodeFailed: (state, { payload }) => {
      const { updateNipStatusCode } = state;

      updateNipStatusCode.loading = false;
      updateNipStatusCode.data = null;
      updateNipStatusCode.error = payload;
    },
  },
});

export const {
  setAccessMode,
  resetHome,
  setHomeSuccess,
  setHomeFailure,
  resetMerchant,
  setMerchantSuccess,
  setMerchantFailure,
  resetParentMerchants,
  parentMerchantsSuccess,
  parentMerchantsFailure,
  resetTransaction,
  setTransactionSuccess,
  setTransactionFailure,
  resetReport,
  setReportSuccess,
  setReportFailure,
  fetchPartnerStarted,
  fetchPartnerSuccess,
  fetchPartnerFailed,
  resetSingleMerchant,
  singleMerchantSuccess,
  singleMerchantFailed,
  resetActivateMerchant,
  activateMerchantSuccess,
  activateMerchantFailed,
  resetDeactivateMerchant,
  deactivateMerchantSuccess,
  deactivateMerchantFailed,
  resetUpdateMerchantInfo,
  updateMerchantInfoSuccess,
  updateMerchantInfoFailed,
  resetUpdateMerchantCharges,
  updateMerchantChargesSuccess,
  updateMerchantChargesFailed,
  resetUpdateMerchantLimit,
  updateMerchantLimitSuccess,
  updateMerchantLimitFailed,
  resetCreatePartner,
  createPartnerSuccess,
  createPartnerFailure,
  resetPartnerDetails,
  partnerDetailsSuccess,
  partnerDetailsFailure,
  resetAssignMerchant,
  assignMerchantSuccess,
  assignMerchantFailure,
  resetDeletePartner,
  deletePartnerSuccess,
  deletePartnerFailed,
  resetUnassignMerchant,
  unassignMerchantSuccess,
  unassignMerchantFailed,
  resetCustomers,
  getCustomersSuccess,
  getCustomersFailure,
  resetSingleCustomer,
  getSingleCustomerSuccess,
  getSingleCustomerFailure,
  resetNipStatusCode,
  nipStatusCodeSuccess,
  nipStatusCodeFailure,
  resetUpdateNipStatusCode,
  updateNipStatusCodeSuccess,
  updateNipStatusCodeFailed,
} = bankSlice.actions;

export const getHomeRecordFn = (mode) => async (dispatch) => {
  dispatch(resetHome());

  try {
    const [
      transactionResponse,
      customerResponse,
      merchantResponse,
      merchantListResponse,
    ] = await Promise.all([
      request({
        method: "get",
        url: "/admin/analytics/transaction",
        params: { mode },
      }),
      request({
        method: "get",
        url: "/admin/analytics/customer",
        params: { mode },
      }),
      request({
        method: "get",
        url: "/admin/analytics/merchant",
        params: { mode },
      }),
      request({ method: "get", url: "/merchant", params: { mode } }),
    ]);

    dispatch(
      setHomeSuccess({
        merchant: merchantResponse?.data?.data,
        customer: customerResponse?.data?.data,
        transaction: transactionResponse?.data?.data,
        merchantList: merchantListResponse?.data?.data,
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(setHomeFailure(error?.response?.data || error?.response));
    } else {
      dispatch(setHomeFailure(error?.message));
    }
  }
};

export const getMerchantFn =
  ({ page = 1, search = null }: { page: any; search?: any }) =>
  async (dispatch, getState) => {
    const { accessMode } = getState().bank;

    const params: any = { page, mode: accessMode };

    if (search) {
      params.name = search;
    }

    if (page === 1) {
      dispatch(resetMerchant());
    }

    try {
      const [merchantResponse, merchantListResponse] = await Promise.all([
        request({
          method: "get",
          url: "/admin/analytics/merchant",
          params: { mode: accessMode },
        }),
        request({
          method: "get",
          url: "/merchant",
          params,
        }),
      ]);

      dispatch(
        setMerchantSuccess({
          info: merchantResponse?.data?.data,
          data: merchantListResponse?.data?.data,
          meta: merchantListResponse?.data?.metadata,
        })
      );
    } catch (error) {
      if (error?.response) {
        dispatch(setMerchantFailure(error?.response?.data || error?.response));
      } else {
        dispatch(setMerchantFailure(error?.message));
      }
    }
  };

export const getSingleMerchantFn =
  ({ merchantId, mode }) =>
  async (dispatch) => {
    dispatch(resetSingleMerchant());

    try {
      const { data } = await request({
        method: "get",
        url: "/admin/merchants/" + merchantId,
        params: { mode },
      });

      dispatch(singleMerchantSuccess({ data: data?.data }));
    } catch (error) {
      if (error?.response) {
        dispatch(
          singleMerchantFailed(error?.response?.data || error?.response)
        );
      } else {
        dispatch(singleMerchantFailed(error?.message));
      }
    }
  };

export const getTransactionFn =
  ({
    page = 1,
    type,
    status,
    category,
    reference,
    accessMode,
    merchantId,
  }: {
    page?: number;
    type?: string;
    status?: string;
    category?: string;
    reference?: string;
    accessMode: string;
    merchantId?: string;
  }) =>
  async (dispatch) => {
    dispatch(resetTransaction());

    const params: {
      page?: number;
      type?: string;
      status?: string;
      category?: string;
      reference?: string;
      mode?: string;
      merchantId?: string;
    } = {};

    if (page) {
      params.page = page;
    }
    if (type) {
      params.type = type;
    }
    if (status) {
      params.status = status;
    }
    if (category) {
      params.category = category;
    }
    if (reference) {
      params.reference = reference;
    }
    if (accessMode) {
      params.mode = accessMode;
    }
    if (merchantId) {
      params.merchantId = merchantId;
    }

    try {
      const [transactionResponse, transactionListResponse] = await Promise.all([
        request({
          method: "get",
          url: "/admin/analytics/transaction",
          params: { mode: accessMode },
        }),
        request({
          method: "get",
          url: "/admin/transactions",
          params,
        }),
      ]);

      dispatch(
        setTransactionSuccess({
          info: transactionResponse?.data?.data,
          data: transactionListResponse?.data?.data,
          meta: transactionListResponse?.data?.metadata,
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          setTransactionFailure(error?.response?.data || error?.response)
        );
      } else {
        dispatch(setTransactionFailure(error?.message));
      }
    }
  };

export const getReportFn =
  ({
    page = 1,
    type = null,
    status = null,
    category = null,
    reference = null,
    date = format(subDays(new Date(), 1), "yyyy-MM-dd"),
  }) =>
  async (dispatch, getState) => {
    const { accessMode } = getState().bank;

    if (page === 1) {
      dispatch(resetReport());
    }

    try {
      const [transactionResponse, endOfDayTransactionResponse] =
        await Promise.all([
          request({
            method: "get",
            url: "/admin/analytics/transaction",
            params: { mode: accessMode, date },
          }),
          request({
            method: "get",
            url: "/admin/transaction/end-of-day",
            params: {
              mode: accessMode,
              date,
              type,
              category,
              status,
              reference,
              page,
            },
          }),
        ]);

      dispatch(
        setReportSuccess({
          info: transactionResponse?.data?.data,
          data: endOfDayTransactionResponse?.data?.data,
          meta: endOfDayTransactionResponse?.data?.metadata,
        })
      );
    } catch (error) {
      if (error?.response) {
        dispatch(setReportFailure(error?.response?.data || error?.response));
      } else {
        dispatch(setReportFailure(error?.message));
      }
    }
  };

export const fetchPartnerFn = () => async (dispatch) => {
  dispatch(fetchPartnerStarted());

  try {
    const { data } = await request({ method: "get", url: "/partners" });

    if (data && data?.status) {
      dispatch(fetchPartnerSuccess(data?.data));
    } else {
      throw new Error();
    }
  } catch (error) {
    if (error?.response) {
      dispatch(fetchPartnerFailed(error?.response?.data || error?.response));
    } else {
      dispatch(fetchPartnerFailed(error?.message));
    }
  }
};

export const activateMerchantFn =
  ({ merchantId }, cb) =>
  async (dispatch) => {
    dispatch(resetActivateMerchant());

    try {
      const { data } = await request({
        method: "patch",
        url: "/merchant/activate",
        data: { merchantId },
      });

      dispatch(activateMerchantSuccess({ data: data?.message }));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(
        activateMerchantFailed(error?.response?.data?.message || error?.message)
      );
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

export const deactivateMerchantFn =
  ({ merchantId }, cb) =>
  async (dispatch) => {
    dispatch(resetDeactivateMerchant());

    try {
      const { data } = await request({
        method: "patch",
        url: "/merchant/deactivate",
        data: { merchantId },
      });

      dispatch(deactivateMerchantSuccess({ data: data?.message }));
      toast.success(data?.message);
      cb();
    } catch (error) {
      dispatch(deactivateMerchantFailed(error?.message));
      toast.error(error?.message);
    }
  };

export const updateMerchantInfoFn =
  (merchantId: string, basicInformations: any, capabilities: any) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantInfo());

    try {
      await request({
        method: "put",
        url: "/admin/merchants/" + merchantId,
        data: { ...basicInformations, ...capabilities },
      });

      dispatch(
        updateMerchantInfoSuccess({
          data: "Merchant informations was updated successfully",
        })
      );
      toast.success("Merchant informations was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantInfoFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantInfoFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMerchantChargesFn =
  ({ merchantId, chargesInformations }) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantCharges());

    try {
      await request({
        method: "patch",
        url: "/merchant/charges",
        data: { merchantId, ...chargesInformations },
      });

      dispatch(
        updateMerchantChargesSuccess({
          data: "Merchant charges was updated successfully",
        })
      );
      toast.success("Merchant charges was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantChargesFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantChargesFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMerchantLimitFn =
  ({ merchantId, limitInformations }) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantLimit());

    try {
      await request({
        method: "patch",
        url: "/merchant/limits",
        data: { merchantId, ...limitInformations },
      });

      dispatch(
        updateMerchantLimitSuccess({
          data: "Merchant limit was updated successfully",
        })
      );
      toast.success("Merchant limit was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantLimitFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantLimitFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getParentMerchantsFn = () => async (dispatch) => {
  dispatch(resetParentMerchants());

  try {
    const { data } = await request({
      method: "get",
      url: "/merchant/main",
    });

    dispatch(
      parentMerchantsSuccess({
        data: data?.data?.map((each) => {
          return {
            id: each?.id,
            businessName: each?.businessName,
          };
        }),
        meta: data?.metadata,
      })
    );
  } catch (error) {
    if (error?.response) {
      dispatch(
        parentMerchantsFailure(error?.response?.data || error?.response)
      );
    } else {
      dispatch(parentMerchantsFailure(error?.message));
    }
  }
};

export const createPartnerFn = (payload, cb) => async (dispatch) => {
  const { firstName, lastName, email, password } = payload;
  dispatch(resetCreatePartner());

  try {
    const { data } = await request({
      method: "post",
      url: "/partners",
      data: { firstName, lastName, email, password },
    });
    dispatch(createPartnerSuccess(data?.message));
    toast.success(data?.message);
    dispatch(fetchPartnerFn());
    cb();
  } catch (error) {
    if (error?.response) {
      dispatch(createPartnerFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(createPartnerFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const getPartnerDetailsFn = (id) => async (dispatch) => {
  dispatch(resetPartnerDetails());

  try {
    const { data } = await request({
      method: "get",
      url: `/partners/${id}`,
    });

    dispatch(partnerDetailsSuccess(data?.data));
  } catch (error) {
    if (error?.response) {
      dispatch(partnerDetailsFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(partnerDetailsFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const assignMerchantFn =
  (partnerId, merchantId, cb) => async (dispatch) => {
    dispatch(resetAssignMerchant());

    try {
      const { data } = await request({
        method: "post",
        url: "/partners/assign",
        data: { partnerId, merchantId },
      });

      dispatch(assignMerchantSuccess(data?.message));
      toast.success(data?.message);
      dispatch(getPartnerDetailsFn(partnerId));
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(
          assignMerchantFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(assignMerchantFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const deletePartnerFn = (partnerId, cb) => async (dispatch) => {
  dispatch(resetDeletePartner());

  try {
    await request({
      method: "delete",
      url: `/partners/${partnerId}`,
    });

    dispatch(deletePartnerSuccess("Partner deleted successfully"));
    toast.success("Partner deleted successfully");
    cb();
  } catch (error) {
    if (error?.response) {
      dispatch(deletePartnerFailed(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(deletePartnerFailed(error?.message));
      toast.error(error?.message);
    }
  }
};

export const unassignMerchantFn =
  (partnerId, merchantId, cb) => async (dispatch) => {
    dispatch(resetUnassignMerchant());

    try {
      const { data } = await request({
        method: "post",
        url: "/partners/unassign",
        data: { partnerId, merchantId },
      });

      dispatch(unassignMerchantSuccess(data?.message));
      toast.success(data?.message);
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(
          unassignMerchantFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(unassignMerchantFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getCustomersFn =
  ({
    mode,
    page,
    perPage,
    merchantId,
    phoneNumber,
  }: {
    mode: string;
    page?: number;
    perPage?: number;
    merchantId?: string;
    phoneNumber?: string;
  }) =>
  async (dispatch) => {
    if (page === 1) {
      dispatch(resetCustomers());
    }

    const params: {
      mode: string;
      page?: number;
      perPage?: number;
      merchantId?: string;
      phoneNumber?: string;
    } = {
      mode,
    };

    if (page) {
      params.page = page;
    }
    if (perPage) {
      params.perPage = perPage;
    }
    if (merchantId) {
      params.merchantId = merchantId;
    }
    if (phoneNumber) {
      params.phoneNumber = phoneNumber;
    }

    try {
      const { data } = await request({
        method: "get",
        url: "/admin/customers",
        params,
      });

      dispatch(getCustomersSuccess({ data: data?.data, meta: data?.metadata }));
    } catch (error) {
      if (error?.response) {
        dispatch(getCustomersFailure(error?.response?.data || error?.response));
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(getCustomersFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getSingleCustomerFn = (id: string) => async (dispatch) => {
  dispatch(resetSingleCustomer());

  try {
    const { data } = await request({
      method: "get",
      url: `/admin/customers/${id}`,
    });

    dispatch(getSingleCustomerSuccess(data?.data));
  } catch (error) {
    if (error?.response) {
      dispatch(
        getSingleCustomerFailure(error?.response?.data || error?.response)
      );
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(getSingleCustomerFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const getNipStatusCodeFn = (recall?: boolean) => async (dispatch) => {
  if (!recall) {
    dispatch(resetNipStatusCode());
  }

  try {
    const { data } = await request({
      method: "get",
      url: "/nibss/nip-response-codes",
    });

    dispatch(nipStatusCodeSuccess({ data: data?.data, meta: null }));
  } catch (error) {
    if (error?.response) {
      dispatch(nipStatusCodeFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(nipStatusCodeFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const updateNipStatusCodeFn =
  ({
    responseCode,
    applyAutoReversal,
    callback,
  }: {
    responseCode: string;
    applyAutoReversal: number;
    callback: () => void;
  }) =>
  async (dispatch) => {
    dispatch(resetUpdateNipStatusCode());
    toast.info("Switching Status...");

    try {
      const { data } = await request({
        method: "patch",
        url: "/nibss/nip-response-code",
        data: { responseCode, applyAutoReversal },
      });

      dispatch(updateNipStatusCodeSuccess(data?.message));
      callback();
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateNipStatusCodeFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateNipStatusCodeFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const selectBankState = (state) => state.bank;
export const selectBankHomeState = (state) => state.bank.home;
export const selectBankPartnerState = (state) => state.bank.partner;
export const selectBankAccessModeState = (state) =>
  state.bank.accessMode || "PRODUCTION";
export const selectSingleMerchantState = (state) => state.bank.singleMerchant;
export const selectParentMerchantsState = (state) => state.bank.parentMerchants;
export const selectCreatePartner = (state) => state.bank.createPartner;
export const selectPartnerDetailsState = (state) => state.bank.partnerDetails;
export const selectAssignMerchantState = (state) => state.bank.assignMerchant;
export const selectDeletePartnerState = (state) => state.bank.deletePartner;
export const selectUnassignMerchantState = (state) =>
  state.bank.unassignMerchant;
export const selectCustomers = (state) => state.bank.customers;
export const selectSingleCustomer = (state) => state.bank.singleCustomer;
export const selectNipStatusCodes = (state) => state.bank.nipStatusCode;
export const selectUpdateNipStatusCode = (state) =>
  state.bank.updateNipStatusCode;

export default bankSlice.reducer;
