import React, { useEffect } from "react";
import styles from "./CustomerToCustomersTransfer.module.css";
import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  selectWalletState,
  customerToCustomerTransferFn,
} from "slices/walletSlice";
import {
  selectAllCustomersState,
  getAllCustomerFn,
} from "slices/customersSlice";
import TextInput from "components/TextInput/TextInput";
import Loading from "components/Loading/Loading";
import { handleInputValidation } from "utils/helpers";

const CustomerToCustomersTransfer: React.FC = () => {
  const dispatch = useDispatch();
  const { customerToCustomerTransfer } = useSelector(selectWalletState);
  const customerState = useSelector(selectAllCustomersState);

  useEffect(() => {
    dispatch(getAllCustomerFn());
  }, [dispatch]);

  return (
    <SendMoney>
      {customerState.loading ? (
        <div style={{ margin: "0 auto" }}>
          <Loading />
        </div>
      ) : (
        <Formik
          initialValues={{
            customer: "",
            recipient: "",
            amount: "",
          }}
          validationSchema={WalletSchema}
          onSubmit={(values, actions) => {
            dispatch(
              customerToCustomerTransferFn(
                {
                  amount: values.amount,
                  fromCustomerId: values.customer,
                  toCustomerId: values.recipient,
                },
                () => actions.resetForm()
              )
            );
          }}
        >
          {({ errors, touched, values }) => (
            <div className={styles.viaWallet}>
              <h4 className={styles.viaWallet__title}>
                Customer To Customer Transfer
              </h4>
              <Form>
                {/* <div>Baba oo</div> */}
                <div className="form-group">
                  <Field
                    // onBlur={() => {
                    //   fetchUser(props.values.phoneNumber);
                    // }}
                    name="customer"
                    type="string"
                    component="select"
                    className={`form-control form-control-lg ${
                      errors.customer && touched.customer ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">-- Select Customer --</option>
                    {customerState?.data?.map((customer) => (
                      <option value={customer?.id} key={customer?.id}>
                        {customer?.firstName + " " + customer?.lastName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="customer"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <Field
                    // onBlur={() => {
                    //   fetchUser(props.values.phoneNumber);
                    // }}
                    name="recipient"
                    type="string"
                    component="select"
                    className={`form-control form-control-lg ${
                      errors.recipient && touched.recipient ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">-- Select Recipient --</option>
                    {customerState?.data
                      ?.filter((data) => data?.id !== values.customer)
                      ?.map((customer) => (
                        <option value={customer?.id} key={customer?.id}>
                          {customer?.firstName + " " + customer?.lastName}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="recipient"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <Field
                  name="amount"
                  placeholder="Amount"
                  type="text"
                  component={TextInput}
                />
                {customerToCustomerTransfer.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <PrimaryButton
                    type="submit"
                    title="Send Money"
                    size="lg"
                    block
                  />
                )}
              </Form>
            </div>
          )}
        </Formik>
      )}
    </SendMoney>
  );
};

export default CustomerToCustomersTransfer;

const WalletSchema = Yup.object().shape({
  customer: Yup.string().required("Required"),
  recipient: Yup.string().required("Required"),
  amount: Yup.string()
    .required("Required")
    .test(
      "onlyNumber",
      "Amount must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    )
    .test(
      "noExtraSpace",
      "No extra space needed",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
});
