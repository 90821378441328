import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { PrimaryButton } from "components/Button/Button";
import { handleInputValidation, today } from "utils/helpers";

import styles from "./CreateWallet.module.css";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
  bvn?: string;
  dateOfBirth?: string;
  address?: string;
  tier?: string;
}

export interface CreateWalletSectionProps {
  createWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const CreateWalletSection: React.SFC<CreateWalletSectionProps> = ({
  createWalletFn,
  loading,
  error,
}) => {
  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <h2 className="mb-5">Create Customer</h2>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          dateOfBirth: "",
          bvn: "",
          address: "",
          tier: "TIER_1",
        }}
        validationSchema={CreateWalletSchema}
        onSubmit={(values, actions) => {
          createWalletFn(values);
          if (!error) {
            actions.resetForm();
          }

          // console.log(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col">
                <Field
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  component={TextInput}
                />
              </div>
              <div className="col">
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  component={TextInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="email"
                  placeholder="Email"
                  type="email"
                  component={TextInput}
                />
              </div>
              <div className="col">
                <Field
                  name="phoneNumber"
                  placeholder="Phone"
                  type="tel"
                  component={TextInput}
                />
              </div>
            </div>
            <Field
              name="bvn"
              maxLength="11"
              placeholder="BVN"
              type="text"
              component={TextInput}
            />
            <div className={styles?.bvn_date_container}>
              <Field
                name="dateOfBirth"
                placeholder="YYYY-MM-DD"
                type="date"
                component={TextInput}
                max={today}
              />
              <p className={styles?.date_subtext}>Enter your date of birth</p>
            </div>

            <Field
              name="address"
              placeholder="Address"
              type="text"
              component={TextInput}
            />
            <Field
              name="tier"
              type="text"
              component="select"
              className={`form-control form-control-lg ${
                errors.tier && touched.tier ? "is-invalid" : ""
              }`}
            >
              <option value="TIER_1">Tier 1</option>
              <option value="TIER_2">Tier 2</option>
              <option value="TIER_3">Tier 3</option>
            </Field>
            {errors.tier && touched.tier && (
              <div className="invalid-feedback">{errors.tier}</div>
            )}

            {loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Create Customer"
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateWalletSection;

const CreateWalletSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .length(11, "Phone number should be 11 characters long")
    .required("Required")
    .test(
      "onlyNumber",
      "Phone number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  bvn: Yup.string()
    .test(
      "is-valid-bvn",
      "You have provided an invalid bvn",
      (value) => !value || value?.length === 11
    )
    .test(
      "onlyNumber",
      "BVN must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  dateOfBirth: Yup.date()
    .max(new Date(), "DOB must not be more than today")
    .when("bvn", (bvn, schema) => {
      return bvn ? schema.required("Date of birth is required") : schema;
    }),
  email: Yup.string().email("Invalid email"),
  address: Yup.string(),
  tier: Yup.string().default("TIER_1").oneOf(["TIER_1", "TIER_2", "TIER_3"]),
});
