import React, { useEffect } from "react";
import Settings from "../Settings";
import styles from "./Profile.module.css";
import { useSelector, useDispatch } from "react-redux";
import { getProfileFn, selectSettingsState } from "slices/settingsSlice";
import Loading from "components/Loading/Loading";

const ProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const profileState = useSelector(selectSettingsState);

  const fetchProfile = () => {
    dispatch(getProfileFn());
  };

  useEffect(fetchProfile, []);

  const { id, firstName, lastName, businessName, email, businessType, review } =
    profileState?.getProfile?.data;
  // console.log(profileState.getProfile.data);

  return (
    <Settings>
      {!id && profileState?.getProfile?.loading ? (
        <Loading />
      ) : (
        <div className={styles.profile}>
          <div className={styles.profile__initials}>
            {lastName ? lastName.slice(0, 1) : ""}
            {firstName ? firstName.slice(0, 1) : ""}
          </div>
          <div className="mr-5">
            <div>
              <p className="settings__lightText">Full Name</p>
              <p>
                {lastName} {firstName}
              </p>
            </div>
            <div>
              <p className="settings__lightText">Business Name</p>
              <p>{businessName}</p>
            </div>
          </div>
          <div className="ml-5">
            <div>
              <p className="settings__lightText">Email</p>
              <p>{email}</p>
            </div>
            <div>
              <p className="settings__lightText">Category</p>
              <p>{businessType}</p>
            </div>
            <div>
              <p className="settings__lightText">Status</p>
              <p>{review}</p>
            </div>
          </div>
        </div>
      )}
    </Settings>
  );
};

export default ProfileScreen;
