import React from "react";
import Routes from "routing/routes";
import Table from "components/Table/Table";
import { useDispatch } from "react-redux";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import styles from "./MerchantList.module.css";
import { switchUserFn } from "slices/partnerSlice";
import CustomersImg from "assets/images/person.svg";
import TransactionsImg from "assets/images/clipboard.svg";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";

const MerchantList = ({ data, loading, searchValue }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const renderTable = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={6}>
            <Loading />
          </td>
        </tr>
      );
    }

    return data.map((record) => (
      <tr key={record?.id} style={{ cursor: "pointer" }}>
        <td
          onClick={() =>
            history.push(`${Routes.PARTNERMERCHANT}/${record?.id}`)
          }
        >
          {record?.businessName}
        </td>
        <td
          onClick={() =>
            history.push(`${Routes.PARTNERMERCHANT}/${record?.id}`)
          }
        >
          {record?.phoneNumber}
        </td>
        <td
          onClick={() =>
            history.push(`${Routes.PARTNERMERCHANT}/${record?.id}`)
          }
        >
          &#8358;{numberWithCommas(record?.availableBalance)}
        </td>
        <td
          onClick={() =>
            history.push(`${Routes.PARTNERMERCHANT}/${record?.id}`)
          }
        >
          {statusFn(record?.review)}
        </td>
        <td
          onClick={() =>
            history.push(`${Routes.PARTNERMERCHANT}/${record?.id}`)
          }
        >
          {format(parseISO(record?.createdAt), "MMM dd, yyyy")}
        </td>
        <td>
          <button
            title="View Sub-Merchants"
            className={styles.actionBtn}
            onClick={() => {
              dispatch(switchUserFn(record));

              history.push(Routes.PARTNER_SUBSIDIARY_MERCHANT);
            }}
          >
            <img alt="Sub-Merchant" src={CustomersImg} />
          </button>
          <button
            title="View Transactions"
            className={styles.actionBtn}
            onClick={() => {
              dispatch(switchUserFn(record));

              history.push(Routes.PARTNERTRANSACTION);
            }}
          >
            <img alt="Transaction" src={TransactionsImg} />
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <Table
      noData={data?.length === 0 && !loading}
      headers={[
        "MERCHANT NAME",
        "PHONE NO",
        "BALANCE",
        "STATUS",
        "DATE",
        "ACTION",
      ]}
    >
      {renderTable()}
    </Table>
  );
};

export default MerchantList;
