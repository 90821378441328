import React, { useState } from "react";
import Table from "components/Table/Table";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import styles from "./MerchantList.module.css";
import { numberWithCommas } from "utils/helpers";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import routes from "routing/routes";
import Loading from "components/Loading/Loading";

const MerchantList = ({ data, openModal, searchState, loading }) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const renderTable = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={7}>
            <Loading />
          </td>
        </tr>
      );
    }

    return data.map((record) => (
      <tr key={record?.id} style={{ cursor: "pointer" }}>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.businessName}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.accountNumber}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.phoneNumber}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          &#8358;{numberWithCommas(record?.availableBalance)}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {statusFn(record?.review)}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {format(parseISO(record?.createdAt), "MMM dd, yyyy")}
        </td>
        <td>
          <PrimaryButton
            title="View Transactions"
            size="sm"
            link={`${routes.BANK_MERCHANT}/${record?.id}/transactions`}
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className={styles.tableContainer}>
      <form
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
        onClick={(e) => {
          e.preventDefault();
          searchState[1](searchValue);
        }}
      >
        <div className="col-auto my-3" style={{ flex: 1 }}>
          <label className="sr-only" htmlFor="searchMerchant">
            Search merchant
          </label>
          <div
            className="input-group"
            style={{ backgroundColor: "#FBB900", border: 0 }}
          >
            <div className="input-group-prepend">
              <div
                style={{
                  borderRightColor: "transparent",
                  backgroundColor: "transparent",
                  border: 0,
                }}
                className="input-group-text"
              >
                <i className="fas fa-search"></i>
              </div>
            </div>
            <input
              type="text"
              value={searchValue}
              id="searchMerchant"
              className="form-control"
              onChange={(e) => setSearchValue(e.target.value)}
              style={{
                borderLeftColor: "transparent",
                backgroundColor: "#FBB900",
                color: "#000",
                border: 0,
              }}
              placeholder="Search by merchant name"
            />
          </div>
        </div>
        <OutlineButton
          type="submit"
          title="Search"
          size="sm"
          style={{ padding: "6px 20px" }}
        />
      </form>
      <Table
        noData={data.length === 0 && !loading}
        headers={[
          "MERCHANT NAME",
          "ACCOUNT NO",
          "PHONE NO",
          "BALANCE",
          "STATUS",
          "DATE",
          "ACTION",
        ]}
      >
        {renderTable()}
      </Table>
    </div>
  );
};

export default MerchantList;
