import React, { useEffect, Fragment, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import styles from "./CustomerBankTransfer.module.css";

import {
  getBankListFn,
  selectSendMoneyState,
  getBankAccountDetailsFn,
  clearBankAccountDetailsFn,
  customerBankTransferFn,
} from "slices/sendMoneySlice";
import {
  selectAllCustomersState,
  getAllCustomerFn,
} from "slices/customersSlice";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { handleInputValidation } from "utils/helpers";

const CustomerBankTransfer: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectSendMoneyState);
  const customerState = useSelector(selectAllCustomersState);
  const { loading: bankListLoading } = bankState.bankList;
  const { data: bankAccountDetails, loading: bankAccountDetailsLoading } =
    bankState.bankAccountDetails;
  const customerBankTransferState = bankState.customerBankTransfer;

  const [resolvedName, setResolvedName] = useState(false);

  const fetchData = () => {
    dispatch(getBankListFn());
    dispatch(getAllCustomerFn());
  };

  useEffect(fetchData, []);

  const fetchBankAcount = (values: any) => {
    const { accountNumber, sortCode } = values;
    if (
      accountNumber &&
      Number(accountNumber) &&
      accountNumber?.length === 10 &&
      sortCode
    ) {
      dispatch(getBankAccountDetailsFn(sortCode, accountNumber));
    } else if (accountNumber?.length !== 10) {
      dispatch(clearBankAccountDetailsFn());
    }
  };

  const renderVerifiedBankAccount = () => {
    if (bankAccountDetailsLoading) {
      setResolvedName(false);
      return "verifying...";
    } else if (bankAccountDetails?.accountName) {
      setResolvedName(true);
      return `${bankAccountDetails?.accountName}`;
    } else {
      return null;
    }
  };

  const renderBankList = () => {
    const { data: allBanks } = bankState.bankList;

    const banks = allBanks.map((bank: { code: string; name: string }) => {
      return (
        <option value={bank?.code} key={bank?.code}>
          {bank?.name}
        </option>
      );
    });
    return banks;
  };

  return (
    <SendMoney>
      <div className={styles.viaBank}>
        {bankListLoading && customerState.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <h4 className={styles.viaBank__title}>Customer Bank Transfer</h4>
            <Formik
              // enableReinitialize
              initialValues={{
                amount: "",
                accountNumber: "",
                narration: "",
                sortCode: "",
                customer: "",
              }}
              validationSchema={BankTransferSchema}
              onSubmit={(values, actions) => {
                // console.log(values);
                if (!resolvedName) {
                  actions.setFieldError(
                    "accountNumber",
                    "Account not resolved"
                  );
                  return;
                }

                dispatch(
                  customerBankTransferFn(
                    {
                      amount: values.amount,
                      accountName: bankAccountDetails?.accountName,
                      narration: values.narration,
                      sortCode: values.sortCode,
                      customerId: values.customer,
                      accountNumber: values.accountNumber,
                    },
                    () => {
                      actions.resetForm();
                      dispatch(clearBankAccountDetailsFn());
                    }
                  )
                );

                // submitForm(values);
              }}
            >
              {(props) => {
                const sortCodeClassName = `form-control form-control-lg ${
                  props.errors.sortCode && props.touched.sortCode
                    ? "is-invalid"
                    : ""
                }`;

                return (
                  <Form>
                    <div
                      className="form-group"
                      style={{ marginBottom: "2rem" }}
                    >
                      <Field
                        name="customer"
                        component="select"
                        className={`form-control form-control-lg ${
                          props.errors.customer && props.touched.customer
                            ? "is-invalid"
                            : ""
                        }`}
                        // onBlur={() => fetchBankAcount(props.values)}
                      >
                        <option value="" disabled hidden>
                          Select Customer
                        </option>
                        {customerState?.data?.map((customer) => (
                          <option key={customer?.id} value={customer?.id}>
                            {customer?.firstName + " " + customer?.lastName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="customer">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="form-group">
                      <Field
                        name="sortCode"
                        component="select"
                        className={sortCodeClassName}
                        onBlur={() => fetchBankAcount(props.values)}
                      >
                        <option value="" disabled hidden>
                          Select Bank
                        </option>
                        {renderBankList()}
                      </Field>
                      <ErrorMessage name="sortCode">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>

                    <Field
                      onBlur={(e: { currentTarget: { value: any } }) => {
                        props.handleBlur(e);
                        fetchBankAcount(props.values);
                      }}
                      type="text"
                      name="accountNumber"
                      placeholder="Account Number"
                      component={TextInput}
                      subtext={renderVerifiedBankAccount()}
                      maxLength="10"
                    />
                    <Field
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      component={TextInput}
                    />
                    <Field
                      name="narration"
                      placeholder="Narration (optional)"
                      type="text"
                      component={TextInput}
                    />

                    {customerBankTransferState.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <PrimaryButton
                        type="submit"
                        block
                        size="lg"
                        title="Transfer"
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        )}
      </div>
    </SendMoney>
  );
};

export default CustomerBankTransfer;

const BankTransferSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required("Required")
    .length(10, "Account number must be 10 characters")
    .test(
      "onlyNumber",
      "Account number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  amount: Yup.string()
    .required("Required")
    .test(
      "onlyNumber",
      "Amount must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    )
    .test(
      "noExtraSpace",
      "No extra space needed",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
  sortCode: Yup.string().required("Required"),
  customer: Yup.string().required("Required"),
});
