import React, { useEffect, Fragment, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { PrimaryButton, OutlineButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import styles from "./BatchBankTransfer.module.css";

import {
  getBankListFn,
  selectSendMoneyState,
  batchBankTransferFn,
  getBankAccountDetailsFn,
  clearBankAccountDetailsFn,
} from "slices/sendMoneySlice";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { handleInputValidation } from "utils/helpers";

const BatchBankTransfer: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectSendMoneyState);
  const batchBankTransferState = bankState.batchBankTransfer;
  const { loading: bankListLoading } = bankState.bankList;
  const { data: bankAccountDetails, loading: bankAccountDetailsLoading } =
    bankState.bankAccountDetails;
  const [collection, setCollection] = useState([]);
  const [resolvedName, setResolvedName] = useState(false);

  const fetchData = () => {
    dispatch(getBankListFn());
  };

  useEffect(fetchData, []);

  const fetchBankAcount = (values: any) => {
    const { accountNumber, sortCode } = values;
    if (
      accountNumber &&
      Number(accountNumber) &&
      accountNumber?.length === 10 &&
      sortCode
    ) {
      dispatch(getBankAccountDetailsFn(sortCode, accountNumber));
    } else if (accountNumber?.length !== 10) {
      dispatch(clearBankAccountDetailsFn());
    }
  };

  const renderVerifiedBankAccount = () => {
    if (bankAccountDetailsLoading) {
      setResolvedName(false);
      return "verifying...";
    } else if (bankAccountDetails?.accountName) {
      setResolvedName(true);
      return `${bankAccountDetails?.accountName}`;
    } else {
      return null;
    }
  };

  const renderBankList = () => {
    const { data: allBanks } = bankState.bankList;

    const banks = allBanks.map((bank: { code: string; name: string }) => {
      return (
        <option value={bank?.code} key={bank?.code}>
          {bank?.name}
        </option>
      );
    });
    return banks;
  };

  const bankListMap = bankState?.bankList?.data?.reduce((accum, record) => {
    accum[record?.code] = record;

    return accum;
  }, {});

  return (
    <SendMoney>
      <div className={styles.viaBank}>
        {bankListLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <h4 className={styles.viaBank__title}>Batch Bank Transfer</h4>

            <Formik
              // enableReinitialize
              initialValues={{
                amount: "",
                accountNumber: "",
                narration: "",
                sortCode: "",
              }}
              validationSchema={BankTransferSchema}
              onSubmit={(values, actions) => {
                if (!resolvedName) {
                  actions.setFieldError(
                    "accountNumber",
                    "Account not resolved"
                  );
                  return;
                }

                dispatch(
                  batchBankTransferFn(
                    [
                      ...collection?.map((data) => {
                        return {
                          amount: data?.amount,
                          sortCode: data?.sortCode,
                          narration: data?.narration,
                          accountNumber: data?.accountNumber,
                          accountName: data?.accountName,
                        };
                      }),
                      {
                        amount: values.amount,
                        sortCode: values.sortCode,
                        narration: values.narration,
                        accountNumber: values.accountNumber,
                        accountName: bankListMap[values.sortCode]?.name,
                      },
                    ],
                    () => {
                      actions.resetForm();
                      dispatch(clearBankAccountDetailsFn());
                      setCollection([]);
                    }
                  )
                );

                // submitForm(values);
              }}
            >
              {(props) => {
                const sortCodeClassName = `form-control form-control-lg ${
                  props.errors.sortCode && props.touched.sortCode
                    ? "is-invalid"
                    : ""
                }`;
                return (
                  <Form>
                    {!!collection.length && (
                      <div className={styles.collection__container}>
                        <div className={styles.collection__header}>
                          <p className={styles.header__name}>Bank Name</p>
                          <p className={styles.header__amount}>Amount</p>
                          <p className={styles.header__action}>Action</p>
                        </div>
                        {collection?.map((data, index) => (
                          <div
                            className={styles.collection__content}
                            key={index}
                          >
                            <p className={styles.content__name}>
                              {bankListMap[data?.sortCode]?.name} (
                              {data?.accountNumber})
                            </p>
                            <p className={styles.content__amount}>
                              {data?.amount}
                            </p>
                            <p className={styles.content__action}>
                              <button
                                type="button"
                                className={styles.edit}
                                onClick={() => {
                                  setCollection(
                                    collection?.filter(
                                      (flitered) =>
                                        flitered.sortCode !== data?.sortCode
                                    )
                                  );
                                  props.setValues({
                                    accountNumber: data?.accountNumber,
                                    amount: data?.amount,
                                    sortCode: data?.sortCode,
                                    narration: data?.narration,
                                  });
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className={styles.delete}
                                onClick={() => {
                                  setCollection(
                                    collection?.filter(
                                      (flitered) =>
                                        flitered.sortCode !== data?.sortCode
                                    )
                                  );
                                }}
                              >
                                Delete
                              </button>
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="form-group">
                      <Field
                        name="sortCode"
                        component="select"
                        className={sortCodeClassName}
                        onBlur={() => fetchBankAcount(props.values)}
                        value={props.values.sortCode}
                      >
                        <option value="" disabled hidden>
                          Select Bank
                        </option>
                        {renderBankList()}
                      </Field>
                      <ErrorMessage name="sortCode">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <Field
                      onBlur={(e: { currentTarget: { value: any } }) => {
                        props.handleBlur(e);
                        fetchBankAcount(props.values);
                      }}
                      type="text"
                      name="accountNumber"
                      placeholder="Account Number"
                      component={TextInput}
                      subtext={renderVerifiedBankAccount()}
                      maxLength="10"
                    />
                    <Field
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      component={TextInput}
                    />
                    <Field
                      name="narration"
                      placeholder="Narration (optional)"
                      type="text"
                      component={TextInput}
                    />

                    {/* {batchBankTransferState.loading ? (
                                            <div className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {console.log(bankState)}
                                            </div>
                                        ) : ( */}
                    <>
                      <OutlineButton
                        title="Add More"
                        type="button"
                        size="sm"
                        onClick={() => {
                          const values = props.values;
                          if (!values.sortCode) {
                            props.setFieldError(
                              "sortCode",
                              "Account sort code is required"
                            );
                            props.setFieldTouched("sortCode");
                            return;
                          } else if (!values.accountNumber) {
                            props.setFieldError(
                              "accountNumber",
                              "Account number is required"
                            );
                            props.setFieldTouched("accountNumber");
                            return;
                          } else if (values.accountNumber.length !== 10) {
                            props.setFieldError(
                              "accountNumber",
                              "Account number must be 10 characters"
                            );
                            props.setFieldTouched("accountNumber");
                            return;
                          } else if (!resolvedName) {
                            props.setFieldError(
                              "accountNumber",
                              "Account is not resolved"
                            );
                            props.setFieldTouched("accountNumber");
                            return;
                          } else if (!values.amount) {
                            props.setFieldError("amount", "Amount is required");
                            props.setFieldTouched("amount");
                            return;
                          }
                          setCollection([
                            ...collection,
                            {
                              ...values,
                              accountName: bankListMap[values.sortCode]?.name,
                            },
                          ]);
                          props.resetForm();
                        }}
                      />
                      <PrimaryButton
                        type="submit"
                        block
                        size="lg"
                        title="Transfer"
                        style={{ marginTop: "2rem" }}
                      />
                    </>
                    {/* )
                                        } */}
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        )}
      </div>
    </SendMoney>
  );
};

export default BatchBankTransfer;

const BankTransferSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required("Required")
    .length(10, "Account number must be 10 characters")
    .test(
      "onlyNumber",
      "Account number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  amount: Yup.string()
    .required("Required")
    .test(
      "onlyNumber",
      "Amount must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    )
    .test(
      "noExtraSpace",
      "No extra space needed",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
  sortCode: Yup.string().required("Required"),
});
