import * as Yup from "yup";
import routes from "routing/routes";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import config from "constants/api";
import styles from "./Login.module.css";

import AuthLayout from "layouts/Auth/Auth";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import {
  loginUserFn,
  selectAuthState,
  LoginValuesTypes,
} from "slices/authSlice";
import { handleInputValidation } from "utils/helpers";

const LoginScreen: React.FC = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const token = localStorage.getItem(config.ACCESS_TOKEN) !== null;
  const authUser = JSON.parse(localStorage.getItem("user"));

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (token && authUser) {
      switch (authUser.role) {
        case "USER":
          window.location.assign(routes.HOME);
          break;
        case "PARTNER":
          window.location.assign(routes.PARTNER_HOME);
          break;
        case "ADMINISTRATOR":
          window.location.assign(routes.BANK_HOME);
          break;
      }
    }
  }, [token, authUser]);

  const dispatch = useDispatch();

  const authState = useSelector(selectAuthState);
  const { loading: loginLoading } = authState.login;

  const submitForm = (values: LoginValuesTypes) => {
    dispatch(
      loginUserFn(values, (role) => {
        if (role === "USER") {
          history.push(routes.HOME);
        } else if (role === "PARTNER") {
          history.push(routes.PARTNER_HOME);
        } else if (role === "ADMINISTRATOR") {
          history.push(routes.BANK_HOME);
        }
      })
    );
  };

  return (
    <AuthLayout title="Login">
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={submitForm}
        validationSchema={LoginSchema}
      >
        {() => (
          <Form>
            <Field
              name="email"
              placeholder="Email Address"
              type="text"
              component={TextInput}
            />

            <Field
              name="password"
              type={showPassword ? "text" : "password"}
              show={showPassword}
              toggleShow={toggleShowPassword}
              placeholder="Password"
              component={PasswordInput}
              autoComplete="current-password"
            />

            <Link
              className={styles.login__forgotPassword}
              to={routes.FORGOT_PASSWORD}
            >
              Forgot Password?
            </Link>

            {loginLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <PrimaryButton type="submit" block size="lg" title="Login" />
                <OutlineButton
                  title="Sign Up"
                  block
                  size="lg"
                  link={routes.REGISTER}
                />
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default LoginScreen;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .test(
      "noExtraSpaces",
      "Extra spaces not accepted",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
});
