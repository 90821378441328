import axios from "axios";
import config from "./api";
import NProgress from "nprogress";
import { toast } from "react-toastify";
import { logoutFn } from "slices/authSlice";

import { dispatch } from "../App";

interface Request {
    method: "get" | "post" | "put" | "delete" | "patch";
    url?: string;
    data?: object;
    params?: object;
    headers?: object;
    [x: string]: any;
}

const request = ({ method, url, params, data, headers }: Request) => {
    const customHeader = () => {
        if (localStorage.getItem(config.ACCESS_TOKEN)) {
            return {
                "X-Access-Token": localStorage.getItem(config.ACCESS_TOKEN),
                "X-Refresh-Token": localStorage.getItem(config.REFRESH_TOKEN),
                // "ngrok-skip-browser-warning": "any",
            };
        }
    };

    NProgress.start();

    return axios({
        url,
        method,
        baseURL: config.API_URL,
        headers: {
            ...customHeader(),
            ...headers,
        },
        data,
        params,
    })
        .then((response) => {
            return response;
        })
        .catch(async (error) => {
            if (error?.response) {
                console.log(error?.response?.data);
                console.log(error?.response?.status);
                console.log(error?.response?.headers);

                if (error?.response?.status === 401) {
                    dispatch(logoutFn());
                } else {
                    toast.error(error?.response?.data?.message || "Something went wrong, try again later");
                }
            } else if (error?.request) {
                console.log(error?.request);
            } else {
                console.log("Error", error?.message);
            }

            throw error;
        })
        .finally(() => {
            NProgress.done();
        });
};

export default request;
