import ReactPaginate from "react-paginate";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import PartnerLayout from "layouts/Partner/Partner";
import { selectPartnerState, getMerchantCardHolderFn } from "slices/partnerSlice";

import styles from "./PartnerCardHolder.module.css";

const PartnerCardHolderScreen = () => {
    const timer: any = useRef();
    const dispatch = useDispatch();
    const partnerState = useSelector(selectPartnerState);

    const { cardholders, accessMode, activeMerchant } = partnerState;

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(cardholders?.meta?.page);

    const fetchMerchantCardHolders = () => {
        if (activeMerchant) {
            dispatch(getMerchantCardHolderFn({ page: currentPage, name: searchValue }));
        }
    };

    useEffect(fetchMerchantCardHolders, [accessMode, activeMerchant, currentPage]);

    useEffect(() => {
        if (activeMerchant) {
            if (timer?.current) {
                clearTimeout(timer?.current);

                timer.current = setTimeout(() => {
                    dispatch(getMerchantCardHolderFn({ page: currentPage, name: searchValue }));
                }, 500);
            } else {
                timer.current = setTimeout(() => {
                    dispatch(getMerchantCardHolderFn({ page: currentPage, name: searchValue }));
                }, 500);
            }
        }

        return () => {
            clearTimeout(timer.current);
        };
    }, [searchValue, activeMerchant, currentPage, dispatch]);

    const renderRows = () => {
        const rows = cardholders?.data?.map((each, index) => {
            return (
                <tr style={{ cursor: "pointer" }} key={each?.id}>
                    <td>{each?.firstName}</td>
                    <td>{each?.lastName}</td>
                    <td>{each?.email}</td>
                    <td>{each?.phoneNumber}</td>
                </tr>
            );
        });

        return rows;
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        const presentPage = selected + 1;
        setCurrentPage(presentPage);
    };

    return (
        <>
            <PartnerLayout>
                {!activeMerchant ? (
                    <div className={styles.partner}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>Card Holders</Header>
                        </div>

                        <div className={styles.partner__content}>You do not have any active merchant selected.</div>
                    </div>
                ) : cardholders?.loading ? (
                    <Loading />
                ) : (
                    <div className={styles.partner}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>Card Holders</Header>
                            <div>
                                <p className={styles.partner__totalText}>Total Numbers of Card Holders</p>
                                <h4 className={styles.partner__totalAmount}>{cardholders?.meta?.totalRecords || 0}</h4>
                            </div>
                        </div>

                        <div className={styles.partner__content}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                            >
                                <div className="col-auto my-3" style={{ width: "70%" }}>
                                    <label className="sr-only" htmlFor="searchTransaction">
                                        Search card holders
                                    </label>
                                    <div className="input-group mb-2" style={{ backgroundColor: "#FBB900" }}>
                                        <div className="input-group-prepend">
                                            <div
                                                style={{
                                                    borderRightColor: "transparent",
                                                    backgroundColor: "transparent",
                                                }}
                                                className="input-group-text"
                                            >
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            value={searchValue}
                                            id="searchTransaction"
                                            className="form-control"
                                            placeholder="Search card holders"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            style={{ borderLeftColor: "transparent", backgroundColor: "#FBB900" }}
                                        />
                                    </div>
                                </div>
                            </form>

                            <Table
                                noData={cardholders?.data?.length === 0 ? true : false}
                                headers={["First name", "Last Name", "email", "Phone Number"]}
                            >
                                {renderRows()}
                            </Table>

                            {cardholders?.meta?.totalPages > 1 ? (
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        pageCount={cardholders?.meta?.totalPages || 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                    />
                                </nav>
                            ) : null}
                        </div>
                    </div>
                )}
            </PartnerLayout>
        </>
    );
};

export default PartnerCardHolderScreen;
