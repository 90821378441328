import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./PendingTransactions.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  approveTransactionFn,
  selectPendingTransactionsState,
  getPendingTransactionsFn,
  deletePendingTransactionFn,
} from "slices/transactionsSlice";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import { PrimaryButton } from "components/Button/Button";
import PendingTransactionDetails from "../PendingTransactionDetails";
import { CATEGORY_LIST, CATEGORY_DESCRIPTION } from "constants/transaction";
import routes from "routing/routes";

import DownArrow from "assets/images/down-arrow.svg";

const PendingTransactionsScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pendingTransactionState = useSelector(selectPendingTransactionsState);

  const [pendingCurrentPage, setPendingCurrentPage] = useState(
    pendingTransactionState?.meta?.page
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [type, setType] = useState("ALL");
  const [category, setCategory] = useState("");
  const [selectedPendingTransaction, setSelectedPendingTransaction] =
    useState(null);
  const [toggleFilter, setToggleFilter] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const fetchTransactions = () => {
    dispatch(
      getPendingTransactionsFn({
        page: pendingCurrentPage,
        type: "ALL",
        category: "",
      })
    );
  };

  useEffect(fetchTransactions, [pendingCurrentPage]);

  const handlefilter = () => {
    dispatch(
      getPendingTransactionsFn({ page: pendingCurrentPage, type, category })
    );
  };

  const renderRows = () => {
    return pendingTransactionState?.data?.map((each: any, index: number) => {
      return pendingTransactionState?.data?.length ? (
        <tr style={{ cursor: "pointer" }} key={index}>
          <td
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              setSelectedPendingTransaction(each);
              openModal("pendingTransactionDetail");
            }}
          >
            <span
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                display: "inline-block",
                backgroundColor:
                  each?.status === "PENDING"
                    ? "#FBB900"
                    : each?.status === "COMPLETED"
                    ? "green"
                    : "red",
              }}
            />
            &nbsp;&nbsp;&#8358;{numberWithCommas(each?.amount)}
          </td>
          <td
            onClick={() => {
              setSelectedPendingTransaction(each);
              openModal("pendingTransactionDetail");
            }}
          >
            {each?.type}
          </td>
          <td
            onClick={() => {
              setSelectedPendingTransaction(each);
              openModal("pendingTransactionDetail");
            }}
          >
            {CATEGORY_DESCRIPTION[each?.category]}
          </td>
          <td
            onClick={() => {
              setSelectedPendingTransaction(each);
              openModal("pendingTransactionDetail");
            }}
          >
            {each?.creator?.firstName + " " + each?.creator?.lastName}
          </td>
          <td
            onClick={() => {
              setSelectedPendingTransaction(each);
              openModal("pendingTransactionDetail");
            }}
          >
            {format(parseISO(each?.createdAt), "dd-MM-yyyy p")}
          </td>
          <td>
            {(each?.creator?.id !== user?.id || user?.Merchant?.owner) && (
              <>
                <PrimaryButton
                  title="Approve"
                  size="sm"
                  onClick={() =>
                    dispatch(
                      approveTransactionFn({ transactionId: each?.id }, () =>
                        dispatch(
                          getPendingTransactionsFn({
                            page: pendingCurrentPage,
                            type: "ALL",
                            category: "",
                          })
                        )
                      )
                    )
                  }
                />
                <PrimaryButton
                  title="Decline"
                  size="sm"
                  style={{
                    backgroundColor: "red",
                    border: "1px solid red",
                    marginLeft: "5px",
                  }}
                  onClick={() =>
                    dispatch(
                      deletePendingTransactionFn(each?.id, () =>
                        dispatch(
                          getPendingTransactionsFn({
                            page: pendingCurrentPage,
                            type: "ALL",
                            category: "",
                          })
                        )
                      )
                    )
                  }
                />
              </>
            )}
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            No Transaction Available
          </td>
        </tr>
      );
    });
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;

    setPendingCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "pendingTransactionDetail") {
      return <PendingTransactionDetails content={selectedPendingTransaction} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {pendingTransactionState.loading &&
        !toggleFilter &&
        !pendingTransactionState?.data?.length ? (
          <Loading />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Transactions</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Pending Transactions
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {pendingTransactionState?.meta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-light mr-3`}
                    onClick={() => history.push(routes.TRANSACTIONS)}
                  >
                    Approved
                  </button>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-warning mr-3`}
                    onClick={() =>
                      history.push(`${routes.TRANSACTIONS}/pending`)
                    }
                  >
                    Pending
                  </button>
                  <button
                    className={`btn btn-lg rounded-pill px-4 btn-light`}
                    onClick={() => history.push(`${routes.TRANSACTIONS}/batch`)}
                  >
                    Batch
                  </button>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="col-auto my-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.filter__container}>
                      <div
                        className={styles.filter__placeholder}
                        onClick={() => setToggleFilter(!toggleFilter)}
                      >
                        All{" "}
                        <img
                          src={DownArrow}
                          alt=""
                          className={styles.filter__icon}
                        />
                      </div>
                      <div
                        className={styles.filter__content}
                        style={{ display: toggleFilter ? "block" : "none" }}
                      >
                        <div className={styles.filter__content__top}>
                          <p className={styles.filter__top__text}>FILTER</p>
                          <button
                            className={styles.filter__button}
                            onClick={handlefilter}
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                        <div className={styles.filter__content__bottom}>
                          <select
                            onChange={(e) => setType(e.target.value)}
                            className={styles.filter__select}
                          >
                            <option value="ALL">-- Select Type --</option>
                            <option value="CREDIT">Credit</option>
                            <option value="DEBIT">Debit</option>
                          </select>
                          <select
                            onChange={(e) => setCategory(e.target.value)}
                            className={styles.filter__select}
                          >
                            <option value="">-- Select Category --</option>
                            {CATEGORY_LIST.map((each) => (
                              <option value={each} key={each}>
                                {CATEGORY_DESCRIPTION[each]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <Table
                headers={[
                  "amount",
                  "type",
                  "category",
                  "creator",
                  "date",
                  "action",
                ]}
                noData={
                  pendingTransactionState?.data?.length === 0 &&
                  !pendingTransactionState?.loading
                    ? true
                    : false
                }
              >
                {pendingTransactionState?.loading ? (
                  <tr className="bg-white">
                    <td colSpan={5} className="text-center">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {pendingTransactionState?.meta?.totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={pendingTransactionState?.meta?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
      <div
        className={styles.filter__overlay}
        style={{ display: toggleFilter ? "block" : "none" }}
        onClick={() => setToggleFilter(false)}
      />
    </>
  );
};

export default PendingTransactionsScreen;
