import * as React from "react";
import styles from "./SideContent.module.css";

interface SideContentProps {
  modalIsOpen: boolean;
  closeModalFn: () => void;
  upperModal?: boolean;
}

const SideContent: React.FC<SideContentProps> = ({
  modalIsOpen,
  closeModalFn,
  children,
  upperModal
}) => {
  return (
    <div className={modalIsOpen ? upperModal ? styles.sideContentNew : styles.sideContent : styles.noDisplay}>
      {children}
    </div>
  );
};

export default SideContent;
