import format from "date-fns/format";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import AmountModal from "../AmountModal/AmountModal";
import { CATEGORY_DESCRIPTION } from "constants/transaction";
import ModalBackground from "layouts/Bank/components/ModalBackground/ModalBackground";
import {
  debitWalletFn,
  creditWalletFn,
  getSingleCustomerFn,
} from "slices/customersSlice";

import styles from "./Transaction.module.css";

const TransactionDetail = ({ transaction }) => {
  const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const dispatch = useDispatch();

  const debitWallet = (values: { amount: string }) => {
    dispatch(
      debitWalletFn(
        {
          amount: values.amount,
          customerId: transaction?.data?.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const creditWallet = (values: { amount: string }) => {
    dispatch(
      creditWalletFn(
        {
          amount: values.amount,
          customerId: transaction?.data?.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const closeModal = () => {
    setAmountModalIsOpen(false);
    setModalContent("");
  };

  const renderModalContent = () => {
    if (modalContent === "creditWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={creditWallet}
        />
      );
    } else if (modalContent === "debitWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={debitWallet}
        />
      );
    }
  };

  const {
    status,
    balance_before,
    balance_after,
    amount,
    fee,
    vat,
    category,
    currency,
    reference,
    type,
    createdAt,
    description,
    metadata,
  } = transaction?.data;

  if (transaction?.loading) {
    return <Loading />;
  }

  return (
    <div>
      <ModalBackground
        modalIsOpen={amountModalIsOpen}
        closeModalFn={closeModal}
      />
      {renderModalContent()}
      <div>
        <p className={styles.customerDetail__fullName}>Transaction Details</p>
      </div>

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Status</td>
            <td align="right">{status}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td align="right">{type}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td align="right">&#8358;{numberWithCommas(amount)}</td>
          </tr>
          {(!!fee || fee === 0) && (
            <tr>
              <td>Transaction Fee</td>
              <td align="right">&#8358;{numberWithCommas(fee)}</td>
            </tr>
          )}
          {(!!vat || vat === 0) && (
            <tr>
              <td>VAT</td>
              <td align="right">&#8358;{numberWithCommas(vat)}</td>
            </tr>
          )}
          <tr>
            <td>Customer Balance</td>
            <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
          </tr>
          <tr>
            <td>Balance Before</td>
            <td align="right">&#8358;{numberWithCommas(balance_before)}</td>
          </tr>
          <tr>
            <td>Balance After</td>
            <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
          </tr>
          <tr>
            <td>Category</td>
            <td align="right">{CATEGORY_DESCRIPTION[category]}</td>
          </tr>
          {metadata?.narration ? (
            <tr>
              <td>Narration</td>
              <td align="right">{metadata?.narration}</td>
            </tr>
          ) : (
            <tr>
              <td>Description</td>
              <td align="right">{description}</td>
            </tr>
          )}
          <tr>
            <td>Currency</td>
            <td align="right">{currency}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {reference}
            </td>
          </tr>
          <tr>
            <td>Transaction Date</td>
            <td align="right">
              {format(new Date(createdAt), "dd-MM-yyyy hh:mm a")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransactionDetail;
