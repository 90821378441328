import { createSlice } from "@reduxjs/toolkit";
import request from "constants/requests";

const initialState = {
    accountMode: {
        loading: false,
        data: {},
        error: {},
    },
};

export const configSlice = createSlice({
    name: "customer",
    initialState: initialState,
    reducers: {
        setAccountMode: (state) => {
            let { accountMode } = state;
            accountMode.loading = true;
            accountMode.data = [];
            accountMode.error = {};
        },
        setAccountModeSuccess: (state, { payload }) => {
            let { accountMode } = state;
            accountMode.loading = false;
            accountMode.data = payload;
            accountMode.error = {};
        },
        setAccountModeFailed: (state, { payload }) => {
            let { accountMode } = state;
            accountMode.loading = false;
            accountMode.data = [];
            accountMode.error = payload;
        },
    },
});

export const { setAccountMode, setAccountModeSuccess, setAccountModeFailed } = configSlice.actions;

export const setAccountModeFn =
    (mode: string, cb?: () => void) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setAccountMode());
            const response = await request({
                method: "post",
                url: "/merchant/account-mode",
                data: {
                    mode,
                },
            });
            console.log(response);

            dispatch(setAccountModeSuccess(response?.data?.banks));
            if (cb) {
                cb();
            }
        } catch (error) {
            dispatch(setAccountModeFailed(error?.response?.data || error?.response));
            // console.log(error?.response?.data || error.response);
        }
    };
export const selectConfigState = (state: any) => state;

export default configSlice.reducer;
