import React, { Fragment } from "react";
import { object, string } from "yup";
import { Field, Form, Formik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import AuthLayout from "layouts/Auth/Auth";
import {
  selectAcceptInvitation,
  acceptInvitationFn,
} from "slices/merchantSlice";
import { handleInputValidation } from "utils/helpers";

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  password: "",
};

const validationSchema = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  phoneNumber: string()
    .required("Phone number is required")
    .test(
      "onlyNumber",
      "Phone number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  password: string().required("Password is required"),
});

const CreateTeam: React.FC = () => {
  const { link } = useParams() as Record<string, string | undefined>;
  const history = useHistory();

  const dispatch = useDispatch();

  const acceptInvitationState = useSelector(selectAcceptInvitation);

  const handleSubmit = (values) => {
    dispatch(
      acceptInvitationFn(
        {
          invitationCode: link,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          password: values.password,
        },
        (url) => {
          history.push(url);
        }
      )
    );
  };

  return (
    <AuthLayout title="Accept Invitation">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => {
          return (
            <Form>
              <Field
                name="firstName"
                placeholder="First Name*"
                type="text"
                component={TextInput}
              />
              <Field
                name="lastName"
                placeholder="Last Name*"
                type="text"
                component={TextInput}
              />
              <Field
                name="phoneNumber"
                placeholder="Phone Number*"
                type="tel"
                autoComplete="tel"
                component={TextInput}
              />
              <Field
                name="password"
                placeholder="Password*"
                type="password"
                autoComplete="new-password"
                component={TextInput}
              />

              {acceptInvitationState.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <PrimaryButton type="submit" block size="lg" title="Submit" />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default CreateTeam;
