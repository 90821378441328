import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';

import Header from "components/Header/Header";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import styles from "./Wallet.module.css";
import { numberWithCommas } from "utils/helpers";
import AddWalletImg from "assets/images/addWallet.svg";
import { fundWalletFn, getWalletBalanceFn } from "slices/walletSlice";
import BankDetails from "components/BankDetails/BankDetails";
import config from "constants/api";
import {selectProfile} from 'slices/merchantSlice';

interface WalletProps {
    data: {
        availableBalance: string;
        accountName: string;
        accountNumber: string;
        bankName: string;
        pendingSettlement: string;
    };
    openSideContentFn: (content: string) => void;
    permissions?: string[]
}

const Wallet: React.FC<WalletProps> = ({ data, openSideContentFn, permissions }) => {
    const dispatch = useDispatch();

    const {permissions: profilePermissions} = useSelector(selectProfile);

    // eslint-disable-next-line
    const { availableBalance, accountName, accountNumber, bankName, pendingSettlement } = data;

    const mode = localStorage.getItem(config.ACCOUNT_MODE) === "SANDBOX";

    return (
        <div className={styles.wallet}>
            <div className="row mb-4">
                <div className="col">
                    <Header>
                        {(!!permissions?.includes('CREATE_CUSTOMER_WALLET') || !!permissions?.includes('BROWSE_MERCHANT_WALLET')) && 'Merchant Account'}{" "}
                        {!!permissions?.includes('CREATE_CUSTOMER_WALLET') && <><img
                            onClick={() => openSideContentFn("createWallet")}
                            className={styles.wallet__headerIcon}
                            src={AddWalletImg}
                            alt=""
                            data-tip='Create Customer'
                            data-for='createCustomer'
                        />
                        <ReactTooltip id='createCustomer' place="right" />
                        </>}
                    </Header>
                </div>
                <div className="col d-flex justify-content-end">
                    {mode && permissions?.includes('WALLET_CREDIT_DEBIT') ? (
                        <PrimaryButton
                            onClick={() => dispatch(fundWalletFn(() => !!profilePermissions?.includes('BROWSE_MERCHANT_WALLET') && dispatch(getWalletBalanceFn())))}
                            className="mr-2"
                            title="Fund Wallet"
                            // disabled
                        />
                    ) : null}
                    {!!permissions?.includes('WALLET_CREDIT_DEBIT') && <OutlineButton title="Withdraw" onClick={() => openSideContentFn("walletWithdraw")} />}
                </div>
            </div>
            
            {!!permissions?.includes('BROWSE_MERCHANT_WALLET') && <div className={styles.wallet__content}>
                <BankDetails accountName={accountName} accountNumber={accountNumber} bankName={bankName} />
                <div>
                    <p className={styles.wallet__amountTitle}>Available balance</p>
                    <h3 className={styles.wallet__amount}>&#x20A6;{numberWithCommas(availableBalance)}</h3>
                </div>
                {/* <div className={styles.wallet__vl} />
				<div>
					<p className={styles.wallet__amountTitle}>Pending Settlement</p>
					<h3 className={styles.wallet__amount}>&#x20A6;{numberWithCommas(pendingSettlement)}</h3>
				</div> */}
            </div>}
        </div>
    );
};

export default Wallet;
