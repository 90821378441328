import * as Yup from "yup";
import React, { useEffect, Fragment } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";

import {
  getBankListFn,
  selectSendMoneyState,
  postBankTransferFn,
  getBankAccountDetailsFn,
  clearBankAccountDetailsFn,
} from "slices/sendMoneySlice";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { getWalletBalanceFn } from "slices/walletSlice";
import { PrimaryButton } from "components/Button/Button";
import { selectProfile } from "slices/merchantSlice";
import { handleInputValidation } from "utils/helpers";

const ViaBank: React.FC = () => {
  const dispatch = useDispatch();

  const bankState = useSelector(selectSendMoneyState);
  const { permissions: profilePermissions } = useSelector(selectProfile);

  const { loading: bankListLoading } = bankState.bankList;
  const { loading: bankTransferloading } = bankState.bankTransfer;
  const { data: bankAccountDetails, loading: bankAccountDetailsLoading } =
    bankState.bankAccountDetails;

  const fetchData = () => {
    dispatch(getBankListFn());
  };

  useEffect(fetchData, []);

  const fetchBankAcount = (values: any) => {
    const { accountNumber, sortCode } = values;
    if (
      accountNumber &&
      Number(accountNumber) &&
      accountNumber?.length === 10 &&
      sortCode
    ) {
      dispatch(getBankAccountDetailsFn(sortCode, accountNumber));
    } else if (accountNumber?.length !== 10) {
      dispatch(clearBankAccountDetailsFn());
    }
  };

  const renderVerifiedBankAccount = () => {
    if (bankAccountDetailsLoading) {
      return "verifying...";
    } else if (bankAccountDetails.accountName) {
      return `${bankAccountDetails.accountName}`;
    } else {
      return null;
    }
  };

  const renderBankList = () => {
    const { data: allBanks } = bankState.bankList;
    const banks = allBanks.map((bank: { code: string; name: string }) => (
      <option value={bank?.code} key={bank?.code}>
        {bank?.name}
      </option>
    ));
    return banks;
  };

  return (
    <div>
      {bankListLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <h4 className="mb-5">Enter recipients details</h4>
          <Formik
            // enableReinitialize
            initialValues={{
              amount: "",
              sortCode: "",
              narration: "",
              accountNumber: "",
            }}
            validationSchema={BankTransferSchema}
            onSubmit={(values, actions) => {
              dispatch(
                postBankTransferFn(
                  { ...values, accountName: bankAccountDetails?.accountName },
                  () => {
                    actions.resetForm();
                    dispatch(clearBankAccountDetailsFn());
                    !!profilePermissions?.includes("BROWSE_MERCHANT_WALLET") &&
                      dispatch(getWalletBalanceFn());
                  }
                )
              );
            }}
          >
            {(props) => {
              const sortCodeClassName = `form-control form-control-lg ${
                props.errors.sortCode && props.touched.sortCode
                  ? "is-invalid"
                  : ""
              }`;

              return (
                <Form>
                  <div className="form-group">
                    <Field
                      // validate={BankTransferSchema.fields?.sortCode}
                      name="sortCode"
                      component="select"
                      className={sortCodeClassName}
                      onBlur={() => {
                        fetchBankAcount(props.values);
                      }}
                    >
                      <option value="" disabled hidden>
                        --Select Bank--
                      </option>
                      {renderBankList()}
                    </Field>
                    <ErrorMessage name="sortCode">
                      {(error) => (
                        <div className="invalid-feedback">{error}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <Field
                    onBlur={(e: { currentTarget: { value: any } }) => {
                      props.handleBlur(e);
                      fetchBankAcount(props.values);
                    }}
                    type="text"
                    name="accountNumber"
                    component={TextInput}
                    placeholder="Account Number"
                    subtext={renderVerifiedBankAccount()}
                    maxLength="10"
                  />
                  <Field
                    name="amount"
                    placeholder="Amount"
                    type="number"
                    component={TextInput}
                  />
                  <Field
                    type="text"
                    name="narration"
                    component={TextInput}
                    placeholder="Narration (optional)"
                  />

                  {bankTransferloading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <PrimaryButton
                      type="submit"
                      block
                      size="lg"
                      title="Transfer"
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Fragment>
      )}
    </div>
  );
};

export default ViaBank;

const BankTransferSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required("Required")
    .length(10, "Account number must be 10 characters")
    .test(
      "onlyNumber",
      "Account number must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    ),
  amount: Yup.string()
    .required("Required")
    .test(
      "onlyNumber",
      "Amount must contain numbers only",
      (val) => handleInputValidation(val)?.onlyNumber
    )
    .test(
      "noExtraSpace",
      "No extra space needed",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
  sortCode: Yup.string().required("Required"),
  narration: Yup.string(),
});
