import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import route from "routing/routes";
import styles from "./SideNav.module.css";

import HomeImg from "assets/images/house.svg";
import TransactionsImg from "assets/images/clipboard.svg";
import CustomersImg from "assets/images/person.svg";
import SubsidiariesImg from "assets/images/person.svg";
import RolesImg from "assets/images/clipboard.svg";
import TeamImg from "assets/images/person.svg";
import SendMoneyImg from "assets/images/money-in.svg";
import SettingsImg from "assets/images/settings.svg";
import LogOutImg from "assets/images/logout.svg";
import { logoutFn } from "slices/authSlice";
import {profileFn, selectProfile} from 'slices/merchantSlice';

const SideNav: React.FC = () => {
    const dispatch = useDispatch();
    const {permissions: profilePermissions, loading} = useSelector(selectProfile);

    const merchant = JSON.parse(localStorage.getItem("merchant") || "");

    const getProfile = () => {
        dispatch(profileFn());
    }

    useEffect(getProfile, []);

    const logoutUser = () => {
        dispatch(logoutFn());
    };

    return (
        <div className={styles.sidenav}>
            <NavItem route={route.HOME} routeName="Home" image={HomeImg} />
            {!!(loading || profilePermissions?.includes('BROWSE_CUSTOMERS')) && <NavItem route={route.CUSTOMERS} routeName="Customers" image={CustomersImg} />}
            {!!(loading || profilePermissions?.includes('BROWSE_TRANSACTIONS')) && <NavItem route={route.TRANSACTIONS} routeName="Transactions" image={TransactionsImg} />}
            {!!((loading || profilePermissions?.includes('WALLET_CREDIT_DEBIT')) || profilePermissions?.includes('MANAGE_TRANSFER')) && <NavItem route={route.SEND_MONEY} routeName="Send Money" image={SendMoneyImg} />}
            {merchant?.parentMerchant || (!merchant?.parentMerchant && !profilePermissions?.includes('BROWSE_SUBSIDIARY_MERCHANT')) ? null : (
                <NavItem route={route.SUBSIDIARIES} routeName="Subsidiaries" image={SubsidiariesImg} />
            )}
            {!!(loading || profilePermissions?.includes('BROWSE_ROLES')) && <NavItem route={route.ROLES} routeName="Roles" image={RolesImg} />}
            {!!(loading || profilePermissions?.includes('BROWSE_TEAM_MEMBER')) && <NavItem route={route.TEAM} routeName="Team" image={TeamImg} />}
            <NavItem route={route.SETTINGS} routeName="Settings" image={SettingsImg} />

            <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.sidenav__logoutContainer}
                href="https://documenter.getpostman.com/view/1736650/TzzGFsrL"
            >
                <img src={TransactionsImg} alt="Documentation" />
                <span>Developer</span>
            </a>
            <div onClick={logoutUser} className={styles.sidenav__logoutContainer}>
                <img src={LogOutImg} alt="Logout" />
                <span>Log Out</span>
            </div>
        </div>
    );
};

export default SideNav;

const NavItem = ({ route, routeName, image }: { route: string; routeName: string; image: string }) => (
    <NavLink className={styles.sidenav__navItem} activeClassName={styles.sidenav__navItemActive} to={route}>
        <img src={image} alt="" />
        <span>{routeName}</span>
    </NavLink>
);
