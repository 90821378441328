import React from "react";

export interface TextInputProps {
  [x: string]: any;
  field: any;
  form: any;
}

const TextInput: React.FC<TextInputProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: {
  [x: string]: any;
  field: any;
  form: any;
}) => {
  const inputClassName = `form-control form-control-lg ${
    touched[field.name] && errors[field.name] ? "is-invalid" : ""
  }`;
  return (
    <div className="form-group">
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}

      <input id={props.id} className={inputClassName} {...field} {...props} />

      {props.subtext ? (
        <small className="form-text text-muted">{props.subtext}</small>
      ) : null}
      {touched[field.name] && errors[field.name] && (
        <div className="invalid-feedback">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default TextInput;
