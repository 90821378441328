import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";

import {
  selectWalletState,
  editWalletFn,
  getWalletsFn,
} from "slices/walletSlice";
import { getSingleCustomerFn } from "slices/customersSlice";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email?: string;
  address?: string;
  tier?: string;
  customerId?: string;
}

export interface CreateWalletSectionProps {
  editWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const EditWalletSection: React.SFC<{
  customerData: Record<string, any>;
  closeModal: () => void;
}> = ({ customerData, closeModal }) => {
  const dispatch = useDispatch();
  const walletState = useSelector(selectWalletState);
  const { loading, error } = walletState?.editWallet;

  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <h2 className="mb-5">Edit Customer</h2>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: customerData?.firstName || "",
          lastName: customerData?.lastName || "",
          email: customerData?.email || "",
          address: customerData?.address || "",
          tier: customerData?.tier || "",
        }}
        validationSchema={CreateWalletSchema}
        onSubmit={(values, actions) => {
          dispatch(
            editWalletFn(
              { ...values, customerId: customerData?.customerId },
              () => {
                dispatch(getWalletsFn({ page: 1 }));
                dispatch(getSingleCustomerFn(customerData?.customerId));
                closeModal();
                actions.resetForm();
              }
            )
          );
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col">
                <Field
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  component={TextInput}
                />
              </div>
            </div>
            <Field
              name="lastName"
              placeholder="Last Name"
              type="text"
              component={TextInput}
            />
            <div className="row">
              <div className="col">
                <Field
                  name="email"
                  placeholder="Email"
                  type="email"
                  component={TextInput}
                />
              </div>
            </div>
            <Field
              name="address"
              placeholder="Address"
              type="text"
              component={TextInput}
            />
            <Field
              name="tier"
              type="text"
              component="select"
              className={`form-control form-control-lg ${
                errors.tier && touched.tier ? "is-invalid" : ""
              }`}
            >
              <option value="TIER_1">Tier 1</option>
              <option value="TIER_2">Tier 2</option>
              <option value="TIER_3">Tier 3</option>
            </Field>
            {errors.tier && touched.tier && (
              <div className="invalid-feedback">{errors.tier}</div>
            )}

            {loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Edit Customer"
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditWalletSection;

const CreateWalletSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(3, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email"),
  address: Yup.string(),
  tier: Yup.string().default("TIER_1").oneOf(["TIER_1", "TIER_2", "TIER_3"]),
});
