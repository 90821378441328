import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import styles from "./Customers.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
  getCustomersFn,
  // getSingleCustomerFn,
  // selectSingleCustomer,
  selectCustomers,
  selectBankAccessModeState,
} from "slices/bankSlice";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import routes from "routing/routes";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const customersState = useSelector(selectCustomers);
  const modeState = useSelector(selectBankAccessModeState);
  // const singleCustomerState = useSelector(selectSingleCustomer);
  // All customers
  const {
    data: customersData,
    meta: customersMeta,
    loading: customerIsLoading,
  } = customersState;

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(customersMeta?.page || 1);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

  const fetchCustomers = () => {
    dispatch(getCustomersFn({ mode: modeState, page: currentPage }));
  };

  useEffect(fetchCustomers, [currentPage, modeState]);

  const renderRows = () => {
    const rows = customersData?.map((each: any) => {
      return (
        <tr style={{ cursor: "pointer" }} key={each?.id}>
          <td onClick={() => getCustomer(each?.id)}>
            {each?.lastName} {each?.firstName}
          </td>
          <td onClick={() => getCustomer(each?.id)}>{each?.dateOfBirth}</td>
          <td onClick={() => getCustomer(each?.id)}>{each?.phoneNumber}</td>
          <td onClick={() => getCustomer(each?.id)}>{each?.email}</td>
          <td onClick={() => getCustomer(each?.id)}>
            {format(parseISO(each?.createdAt), "dd MMMM, yyyy")}
          </td>
          <td>
            <PrimaryButton
              title="View Transactions"
              size="sm"
              link={`${routes.BANK_CUSTOMER}/${each?.id}/transactions`}
            />
          </td>
        </tr>
      );
    });
    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const renderSideContent = () => {
    if (sideContent === "customerDetail") {
      return (
        <CustomerDetail
          customer={{ data: selectedCustomer, loading: customerIsLoading }}
        />
      );
    } else {
      return null;
    }
  };

  const getCustomer = (id: string) => {
    const foundCustomer = customersData?.find(
      (record) => record?.id === id || record?.phoneNumber === id
    );

    if (foundCustomer) {
      setSelectedCustomer(foundCustomer);
      openModal("customerDetail");
      return;
    }

    toast.error("No record found");
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <BankLayout>
        {customerIsLoading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Customers</Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of customers
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {customersMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getCustomer(searchValue);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="col-auto my-3" style={{ flex: 1 }}>
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
                <OutlineButton
                  type="submit"
                  title="Search"
                  size="sm"
                  style={{ padding: "6px 20px" }}
                />
              </form>
              <Table
                noData={customersData?.length === 0 ? true : false}
                headers={[
                  "name",
                  "date of birth",
                  "phone number",
                  "email",
                  "date",
                  "action",
                ]}
              >
                {renderRows()}
              </Table>

              {customersMeta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={customersMeta?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default CustomersScreen;
