import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AuthLayout from "layouts/Auth/Auth";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { PrimaryButton } from "components/Button/Button";
import { selectAuthState, changePasswordFn } from "slices/authSlice";
import routes from "routing/routes";
import { handleInputValidation } from "utils/helpers";

import styles from "../ForgotPassword/ForgotPassword.module.css";

const ChangePasswordScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const authState = useSelector(selectAuthState);

  const { loading: changePasswordLoading } = authState.changePassword;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout title="Change Password">
      <Formik
        validationSchema={ChangePasswordSchema}
        initialValues={{ resetCode: "", password: "" }}
        onSubmit={(values) => {
          dispatch(changePasswordFn(values, () => history.push("/")));
        }}
      >
        {() => (
          <Form>
            <Field
              name="resetCode"
              placeholder="Reset Code"
              type="text"
              component={TextInput}
            />
            <div style={{ marginBottom: "20px" }}>
              <Field
                name="password"
                type={showPassword ? "text" : "password"}
                component={PasswordInput}
                show={showPassword}
                toggleShow={toggleShowPassword}
                placeholder="New Password"
                autoComplete="new-password"
              />
            </div>
            {changePasswordLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <PrimaryButton
                  type="submit"
                  block
                  size="lg"
                  title="Reset Password"
                />
                <div className={styles.container}>
                  <p
                    className={styles.text}
                    onClick={() => history.push(routes.LOGIN)}
                  >
                    Proceed to Login
                  </p>
                </div>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ChangePasswordScreen;

const ChangePasswordSchema = Yup.object().shape({
  resetCode: Yup.string()
    .required("Required")
    .test(
      "noExtraSpace",
      "Extra spaces not accepted",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
  password: Yup.string()
    .required("Required")
    .test(
      "noExtraSpace",
      "Extra spaces not accepted",
      (val) => handleInputValidation(val)?.noExtraSpace
    ),
});
