import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import request from "constants/requests";
import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import Header from "components/Header/Header";
import { useSelector } from "react-redux";
import EndOfDayHeaderSection from "./components/Transaction/Transaction";
import SideContent from "layouts/Bank/components/SideContent/SideContent";
import TransactionDetail from "./components/Transaction/TransactionDetail";
import EndOfDayTransactionList from "./components/MerchantList/MerchantList";
import ModalBackground from "layouts/Bank/components/ModalBackground/ModalBackground";
import { toast } from "react-toastify";

import { selectBankState } from "slices/bankSlice";
import styles from "./components/MerchantList/MerchantList.module.css";

const Transaction: React.FC = () => {
  const bankState = useSelector(selectBankState);
  //   const { loading, info, data, meta } = bankState.reports;

  const [currentPage, setCurrentPage] = useState(1);
  const [sideContent, setSideContent] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reports, setReports] = useState<any>(null);
  const [reportAnalytics, setReportAnalytics] = useState<any>(null);
  const [metaData, setMetaData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [filterObj, setFilterObj] = useState<any>(null);

  const [date, setDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));

  const getReportFn = async () => {
    setIsFetching(true);

    const params: any = {};

    if (currentPage) {
      params.page = currentPage;
    }
    if (bankState?.accessMode) {
      params.mode = bankState?.accessMode;
    }
    if (date) {
      params.date = format(new Date(date), "yyyy-MM-dd");
    }
    if (filterObj?.type) {
      params.type = filterObj?.type;
    }
    if (filterObj?.status) {
      params.status = filterObj?.status;
    }
    if (filterObj?.category) {
      params.category = filterObj?.category;
    }
    if (filterObj?.reference) {
      params.reference = filterObj?.reference;
    }

    try {
      const [transactionResponse, endOfDayTransactionResponse] =
        await Promise.all([
          request({
            method: "get",
            url: "/admin/analytics/transaction",
            params: { mode: params?.mode, date: params?.date },
          }),
          request({
            method: "get",
            url: "/admin/transaction/end-of-day",
            params,
          }),
        ]);

      setReports(endOfDayTransactionResponse?.data?.data);
      setReportAnalytics(transactionResponse?.data?.data);
      setMetaData(endOfDayTransactionResponse?.data?.metadata);
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data || error?.response);
      } else {
        toast.error(error?.message);
      }
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    console.log(date);
    getReportFn();
  }, [bankState.accessMode, currentPage, date, filterObj]);

  const handlePageClick = ({ selected }: { selected: number }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "transactionDetail") {
      return (
        <TransactionDetail
          transaction={{ data: transaction, loading }}
          closeModalFn={closeModal}
        />
      );
    } else {
      return null;
    }
  };
  const handleDownload = async () => {
    const response = await request({
      method: "get",
      url: "/admin/transaction/end-of-day/download",
      params: {
        date: format(new Date(date), "yyyy-MM-dd"),
        mode: bankState.accessMode,
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}-end-of-day-report.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <BankLayout>
        <div className={styles.wallet}>
          <div className="mb-4 d-flex justify-content-between">
            <Header>End of day report</Header>
            <div>
              <p className={styles.totalText}>Date</p>

              <input
                type="date"
                value={date}
                max={format(new Date(), "yyyy-MM-dd")}
                onChange={(e) => {
                  setDate(format(new Date(e.target.value), "yyyy-MM-dd"));
                }}
              />
              <button
                style={{
                  marginLeft: 10,
                  backgroundColor: "#174542",
                  paddingRight: 15,
                  paddingLeft: 15,
                  paddingTop: 7,
                  paddingBottom: 7,
                  color: "#FFF",
                  borderRadius: "3px",
                  outline: "none",
                  border: 0,
                }}
                type="button"
                onClick={handleDownload}
              >
                Download Report
              </button>
            </div>
          </div>
        </div>

        {loading && currentPage === 1 ? (
          <Loading />
        ) : (
          <>
            <EndOfDayHeaderSection data={reportAnalytics} />
            <div className={styles.partner__content}>
              {isFetching ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "2rem 0",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <EndOfDayTransactionList
                  data={reports}
                  openModal={openModal}
                  setTransaction={setTransaction}
                  setFilterObj={setFilterObj}
                />
              )}

              <nav aria-label="navigation">
                {metaData?.totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={metaData?.totalPages || 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </>
        )}
      </BankLayout>
    </>
  );
};

export default Transaction;
