import React, { useEffect } from "react";
import { format, parseISO } from "date-fns";

import styles from "./TransactionHistory.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactionsHistoryFn,
  selectTransactionHistoryState,
} from "slices/transactionsSlice";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";

const TransactionHistory: React.FC = () => {
  const dispatch = useDispatch();
  const transactionsHistoryState = useSelector(selectTransactionHistoryState);

  const fetchTransactions = () => {
    dispatch(
      getTransactionsHistoryFn((cd) => {
        window.print();
      })
    );
  };

  useEffect(fetchTransactions, []);

  const renderRows = () => {
    return transactionsHistoryState?.data?.length ? (
      transactionsHistoryState?.data.map((each: any, index: number) => {
        return (
          <tr key={index}>
            <td style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                  display: "inline-block",
                  backgroundColor:
                    each?.status?.toLowerCase() === "success"
                      ? "green"
                      : each?.status?.toLowerCase() === "pending"
                      ? "#FBB900"
                      : "red",
                }}
              />
              &nbsp;&nbsp;&#8358;{numberWithCommas(each?.amount)}
            </td>
            <td>{each?.type}</td>
            <td className={styles.descriptionRow}>{each?.description}</td>
            <td>{format(parseISO(each?.createdAt), "dd-MM-yyyy p")}</td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={5} className="text-center">
          No Transaction Available
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.container}>
      {transactionsHistoryState.loading &&
      !transactionsHistoryState?.data?.length ? (
        <Loading />
      ) : (
        <div className={styles.transactions}>
          <div className="mb-4 d-flex justify-content-between">
            <Header>Transactions</Header>
            <div>
              <p className={styles.transactions__totalText}>
                Total Numbers of Transactions
              </p>
              <h4 className={styles.transactions__totalAmount}>
                {transactionsHistoryState?.data?.length || 0}
              </h4>
            </div>
          </div>

          <div className={styles.transactions__content}>
            <Table
              headers={["amount", "type", "description", "date"]}
              noData={
                transactionsHistoryState?.data?.length === 0 &&
                !transactionsHistoryState.loading
                  ? true
                  : false
              }
            >
              {renderRows()}
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
