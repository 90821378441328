import React from "react";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";

import store from "./store";
import Routes from "./routing";

export const dispatch = store.dispatch;
export const selector = store.getState;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ToastContainer autoClose={5000} />
        <Routes />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
