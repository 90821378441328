import React, { useEffect, Fragment, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { PrimaryButton, OutlineButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import styles from "./BatchCustomersBankTransfer.module.css";

import {
  getBankListFn,
  selectSendMoneyState,
  getBankAccountDetailsFn,
  clearBankAccountDetailsFn,
} from "slices/sendMoneySlice";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import {
  selectAllCustomersState,
  getAllCustomerFn,
} from "slices/customersSlice";
import { handleInputValidation } from "utils/helpers";

const BatchCustomersBankTransfer: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectSendMoneyState);
  const customerState = useSelector(selectAllCustomersState);
  const { loading: bankListLoading } = bankState.bankList;
  const { loading: bankTransferloading } = bankState.bankTransfer;
  const { data: bankAccountDetails, loading: bankAccountDetailsLoading } =
    bankState.bankAccountDetails;
  const [collection, setCollection] = useState([]);
  const [resolvedName, setResolvedName] = useState(false);

  const fetchData = () => {
    dispatch(getBankListFn());
    dispatch(getAllCustomerFn());
  };

  useEffect(fetchData, []);

  const fetchBankAcount = (values: any) => {
    const { accountNumber, sortCode } = values;
    if (
      accountNumber &&
      Number(accountNumber) &&
      accountNumber?.length === 10 &&
      sortCode
    ) {
      dispatch(getBankAccountDetailsFn(sortCode, accountNumber));
    } else if (accountNumber?.length !== 10) {
      dispatch(clearBankAccountDetailsFn());
    }
  };

  const renderVerifiedBankAccount = () => {
    if (bankAccountDetailsLoading) {
      setResolvedName(false);
      return "verifying...";
    } else if (bankAccountDetails?.accountName) {
      setResolvedName(true);
      return `${bankAccountDetails?.accountName}`;
    } else {
      return null;
    }
  };

  const renderBankList = () => {
    const { data: allBanks } = bankState.bankList;

    const banks = allBanks.map((bank: { code: string; name: string }) => {
      return (
        <option value={bank?.code} key={bank?.code}>
          {bank?.name}
        </option>
      );
    });
    return banks;
  };

  const bankListMap = bankState?.bankList?.data?.reduce((accum, record) => {
    accum[record?.code] = record;

    return accum;
  }, {});

  return (
    <SendMoney>
      <div className={styles.viaBank}>
        {bankListLoading && customerState.loading ? (
          <Loading />
        ) : (
          <Fragment>
            <h4 className={styles.viaBank__title}>
              Batch Customers Bank Transfer
            </h4>
            <Formik
              // enableReinitialize
              initialValues={{
                amount: "",
                accountNumber: "",
                narration: "",
                sortCode: "",
                customer: "",
              }}
              validationSchema={Yup.object().shape(
                collection?.length
                  ? {
                      accountNumber: Yup.string()
                        .test(
                          "onlyNumber",
                          "Account number must contain numbers only",
                          (val) =>
                            val ? handleInputValidation(val)?.onlyNumber : true
                        )
                        .max(
                          10,
                          "Account number must not be more than 10 characters"
                        ),
                      amount: Yup.string().test(
                        "onlyNumber",
                        "Amount must contain numbers only",
                        (val) =>
                          val ? handleInputValidation(val)?.onlyNumber : true
                      ),
                      sortCode: Yup.string(),
                      customer: Yup.string(),
                    }
                  : {
                      accountNumber: Yup.string()
                        .required("Required")
                        .length(10, "Account number must be 10 characters")
                        .test(
                          "onlyNumber",
                          "Account number must contain numbers only",
                          (val) => handleInputValidation(val)?.onlyNumber
                        ),
                      amount: Yup.string()
                        .required("Required")
                        .test(
                          "onlyNumber",
                          "Amount must contain numbers only",
                          (val) => handleInputValidation(val)?.onlyNumber
                        )
                        .test(
                          "noExtraSpace",
                          "No extra space needed",
                          (val) => handleInputValidation(val)?.noExtraSpace
                        ),
                      sortCode: Yup.string().required("Required"),
                      customer: Yup.string().required("Required"),
                    }
              )}
              onSubmit={(values, actions) => {
                if (!resolvedName) {
                  actions.setFieldError(
                    "accountNumber",
                    "Account is not resolved"
                  );
                  return;
                }
                // console.log(values);
                // return;
                // dispatch(
                //     customerBankTransferFn([],
                //         () => {
                //             actions.resetForm();
                //             dispatch(clearBankAccountDetailsFn());
                //         }
                //     )
                // );

                // submitForm(values);
              }}
            >
              {(props) => {
                const sortCodeClassName = `form-control form-control-lg ${
                  props.errors.sortCode && props.touched.sortCode
                    ? "is-invalid"
                    : ""
                }`;

                return (
                  <Form>
                    {!!collection.length && (
                      <div className={styles.collection__container}>
                        <div className={styles.collection__header}>
                          <p className={styles.header__name}>Bank Name</p>
                          <p className={styles.header__amount}>Amount</p>
                          <p className={styles.header__action}>Action</p>
                        </div>
                        {collection?.map((data, index) => (
                          <div
                            className={styles.collection__content}
                            key={index}
                          >
                            <p className={styles.content__name}>
                              {data?.accountName} ({data?.accountNumber})
                            </p>
                            <p className={styles.content__amount}>
                              {data?.amount}
                            </p>
                            <p className={styles.content__action}>
                              <button
                                type="button"
                                className={styles.edit}
                                onClick={() => {
                                  setCollection(
                                    collection?.filter(
                                      (flitered) =>
                                        flitered?.sortCode !== data?.sortCode
                                    )
                                  );
                                  props.setValues({
                                    accountNumber: data?.accountNumber,
                                    amount: data?.amount,
                                    sortCode: data?.sortCode,
                                    narration: data?.narration,
                                    customer: data?.customer,
                                  });
                                }}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className={styles.delete}
                                onClick={() => {
                                  setCollection(
                                    collection?.filter(
                                      (flitered) =>
                                        flitered?.sortCode !== data?.sortCode
                                    )
                                  );
                                }}
                              >
                                Delete
                              </button>
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                    <div
                      className="form-group"
                      style={{ marginBottom: "2rem" }}
                    >
                      <Field
                        name="customer"
                        component="select"
                        className={`form-control form-control-lg ${
                          props.errors.customer && props.touched.customer
                            ? "is-invalid"
                            : ""
                        }`}
                        value={props.values.customer}
                        // onBlur={() => fetchBankAcount(props.values)}
                      >
                        <option value="" disabled hidden>
                          Select Customer
                        </option>
                        {customerState?.data?.map((customer) => (
                          <option key={customer?.id} value={customer?.id}>
                            {customer?.firstName + " " + customer?.lastName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="customer">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>

                    <div className="form-group">
                      <Field
                        name="sortCode"
                        component="select"
                        className={sortCodeClassName}
                        onBlur={() => fetchBankAcount(props.values)}
                      >
                        <option value="" disabled hidden>
                          Select Bank
                        </option>
                        {renderBankList()}
                      </Field>
                      <ErrorMessage name="sortCode">
                        {(error) => (
                          <div className="invalid-feedback">{error}</div>
                        )}
                      </ErrorMessage>
                    </div>

                    <Field
                      onBlur={(e: { currentTarget: { value: any } }) => {
                        props.handleBlur(e);
                        fetchBankAcount(props.values);
                      }}
                      type="text"
                      name="accountNumber"
                      placeholder="Account Number"
                      component={TextInput}
                      subtext={renderVerifiedBankAccount()}
                      maxLength="10"
                    />
                    <Field
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      component={TextInput}
                    />
                    <Field
                      name="narration"
                      placeholder="Narration (optional)"
                      type="text"
                      component={TextInput}
                    />

                    {bankTransferloading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <OutlineButton
                          title="Add More"
                          type="button"
                          size="sm"
                          onClick={() => {
                            const values = props.values;
                            if (!values.sortCode) {
                              props.setFieldError(
                                "sortCode",
                                "Account sort code is required"
                              );
                              props.setFieldTouched("sortCode");
                              return;
                            } else if (!values.accountNumber) {
                              props.setFieldError(
                                "accountNumber",
                                "Account number is required"
                              );
                              props.setFieldTouched("accountNumber");
                              return;
                            } else if (values.accountNumber.length !== 10) {
                              props.setFieldError(
                                "accountNumber",
                                "Account number must be 10 characters"
                              );
                              props.setFieldTouched("accountNumber");
                              return;
                            } else if (!resolvedName) {
                              props.setFieldError(
                                "accountNumber",
                                "Account is not resolved"
                              );
                              props.setFieldTouched("accountNumber");
                              return;
                            } else if (!values.customer) {
                              props.setFieldError(
                                "customer",
                                "Customer is required"
                              );
                              props.setFieldTouched("customer");
                              return;
                            } else if (!values.amount) {
                              props.setFieldError(
                                "amount",
                                "Amount is required"
                              );
                              props.setFieldTouched("amount");
                              return;
                            }
                            setCollection([
                              ...collection,
                              {
                                ...props.values,
                                accountName:
                                  bankListMap[props.values.sortCode]?.name,
                              },
                            ]);
                            props.setValues({
                              amount: "",
                              accountNumber: "",
                              narration: "",
                              sortCode: "",
                              customer: props.values.customer,
                            });
                          }}
                        />
                        <PrimaryButton
                          type="submit"
                          block
                          size="lg"
                          title="Transfer"
                          style={{ marginTop: "2rem" }}
                        />
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        )}
      </div>
    </SendMoney>
  );
};

export default BatchCustomersBankTransfer;
